import React, { useEffect, useRef } from 'react'
import { Field, useField, useForm } from 'react-final-form'

import ContrastTitle from 'components/widgets/ContrastTitle'
import ErrorText from 'components/widgets/ErrorText'
import { RadioTabs } from 'components/forms/fieldRenderers'
import { CONDITION_TYPE } from '../../models/Question'
import { composeName } from '../../utils/functions'
import useFieldInit from '../../hooks/useFieldInit'
import AnswerConditionFormPart from './conditionWidgets/AnswerCondition.formPart'
import GenderConditionFormPart from './conditionWidgets/GenderCondition.formPart'
import NumberConditionFormPart from './conditionWidgets/NumberCondition.formPart'
import styles from './Condition.module.scss'

const NotImplemented = () => (
  <center>
    <i>Not Implemented</i>
  </center>
)

const CONDITION_FORM = {
  [CONDITION_TYPE.Answer]: AnswerConditionFormPart,
  [CONDITION_TYPE.Gender]: GenderConditionFormPart,
  [CONDITION_TYPE.Weight]: props => (
    <NumberConditionFormPart leftLabel="Patient weight is" rightLabel="kg" {...props} />
  ),
  [CONDITION_TYPE.Height]: props => (
    <NumberConditionFormPart leftLabel="Patient height is" rightLabel="cm" {...props} />
  ),
  [CONDITION_TYPE.Age]: props => (
    <NumberConditionFormPart leftLabel="Patient age is" rightLabel="years" {...props} />
  ),
}

const typeOptions = Object.values(CONDITION_TYPE)

const useObserver = (callback, values) => {
  const prevValues = useRef(null)
  useEffect(() => {
    if (prevValues.current) {
      callback(prevValues.current, values)
    }
    prevValues.current = [...values]
  }, [...values])
}

const useFieldError = name => {
  const {
    meta: { error },
  } = useField(name, { subscription: { error: true } })
  return error
}

const ConditionPartialForm = ({ name, disabled }) => {
  const conditionPath = composeName(name, 'content.condition')
  const typePath = composeName(conditionPath, 'type')
  const error = useFieldError(composeName(conditionPath, 'error'))

  useFieldInit(typePath, CONDITION_TYPE.Answer)
  const {
    input: { value: type },
  } = useField(typePath, { subscription: { value: true } })
  const form = useForm({ subscription: null })

  const handleTypeChange = () => {
    const valuePath = composeName(conditionPath, 'value')
    const criteriaPath = composeName(conditionPath, 'criteria')
    form.change(valuePath, [])
    form.change(criteriaPath, '')
  }

  const FormPart = CONDITION_FORM[type] || NotImplemented

  return (
    <div>
      <ContrastTitle>Condition</ContrastTitle>
      <Field
        name={typePath}
        component={RadioTabs}
        options={typeOptions}
        onChange={handleTypeChange}
      />
      <div className={styles.formPartWrapper}>
        {FormPart && <FormPart name={name} disabled={disabled} />}
        <ErrorText className={styles.error} fixed={false}>
          {error}
        </ErrorText>
      </div>
    </div>
  )
}

export default ConditionPartialForm
