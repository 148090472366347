import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { composeName } from '../../../utils/functions';
import { TextInput, Toggle, TextFieldsArray } from '../fieldRenderers';
import ContrastTitle from '../../widgets/ContrastTitle';

import i18n from '../../../i18n';

const NumberUnitPartialForm = ({ input: { name }, disabled }) => (
  <div>
    <ContrastTitle>{i18n.t('dropdown')}</ContrastTitle>
    <div>
      <Field
        name={composeName(name, 'content.min')}
        label={i18n.t('minimum_value')}
        component={TextInput}
        disabled={disabled}
      />
      <Field
        name={composeName(name, 'content.max')}
        label={i18n.t('maximum_value')}
        component={TextInput}
        disabled={disabled}
      />
    </div>
    <div>
      <Field
        name={composeName(name, 'content.decimal')}
        label={i18n.t('decimal_precision')}
        component={Toggle}
        disabled={disabled}
      />
    </div>
    <div>
      <Field
        name={composeName(name, 'content.suffix')}
        label={i18n.t('suffix')}
        component={TextInput}
        disabled={disabled}
      />
    </div>
    <div>
      <FieldArray
        name={composeName(name, 'content.units')}
        addBtnLabel={i18n.t('add_unit')}
        itemLabel={(idx) => `${i18n.t('unit')} ${idx + 1}`}
        component={TextFieldsArray}
        disabled={disabled}
      />
    </div>
  </div>
);

export default NumberUnitPartialForm;
