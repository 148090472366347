// ENUM: i => i
export const NOTIF_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFO: 'INFO',
}

let listeners = []

// use setTimeout to execute asynchronously listeners
const dispatch = msg => listeners.forEach(l => setTimeout(l(msg), 0))

export function onMessage(func) {
  listeners.push(func)
}

export function unRegister(func) {
  listeners = listeners.filter(f => f !== func)
}

export function notifSuccess(text) {
  dispatch({ text, type: NOTIF_TYPE.SUCCESS })
}

export function notifError(text) {
  dispatch({ text, type: NOTIF_TYPE.ERROR })
}

export function notifInfo(text) {
  dispatch({ text, type: NOTIF_TYPE.INFO })
}
