import React, { useCallback, useState } from 'react'
import { FORM_ERROR } from 'final-form'
import { Form, Field } from 'react-final-form'
import { withRouter } from 'react-router-dom'

import { TextInput } from '../../components/forms/fieldRenderers'
import { login, multiFactorAuth } from 'services/Authentication'
import { getQueryStringArgs } from '../../utils/functions'

import PrimaryButton from '../../components/widgets/PrimaryButton'
import styles from './Login.module.scss'

import i18n from '../../i18n'

const Login = withRouter(({ history }) => {
  const [mfaToken, setMfaToken] = useState('')

  const handleLogin = async ({ username, password }) => {
    const res = await login({
      email: username,
      password,
      language_tag: 'en',
    })

    if (!res.data) {
      return { [FORM_ERROR]: i18n.t('login_failed') }
    }

    setMfaToken(res.data.access_token)

    return ''
  }

  const handleConfirm = useCallback(
    async ({ code }) => {
      const res = await multiFactorAuth({
        access_token: mfaToken,
        one_time_password: parseInt(code, 10),
      })

      if (res.error) {
        console.log('Error confirming: ', res.error.message)
        return
      }

      const { next } = getQueryStringArgs()
      history.push(next || '/')
    },
    [mfaToken]
  )

  return (
    <main className={styles.main}>
      <header>{i18n.t('welcome')}</header>
      <Form onSubmit={handleLogin}>
        {({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <Field name="username" label={i18n.t('username')} component={TextInput} fullWidth />
            <Field
              name="password"
              type="password"
              label={i18n.t('password')}
              component={TextInput}
              fullWidth
            />
            <PrimaryButton type="submit" disabled={submitting} fullWidth>
              Continue
            </PrimaryButton>
            <p className={styles.submitError}>{submitError}</p>
          </form>
        )}
      </Form>
      {mfaToken && (
        <Form onSubmit={handleConfirm}>
          {({ handleSubmit, submitError, submitting }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <Field name="code" label="Enter code" component={TextInput} fullWidth />
              <PrimaryButton type="submit" disabled={submitting} fullWidth>
                {i18n.t('login')}
              </PrimaryButton>
              <p className={styles.submitError}>{submitError}</p>
            </form>
          )}
        </Form>
      )}
    </main>
  )
})

export default Login
