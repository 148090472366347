import getProperty from 'lodash/get'
import setProperty from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { ARRAY_ERROR } from 'final-form'
import { trimObjProperty, deepFreeze } from '../utils/functions'

import i18n from '../i18n'

// Must be mirror like key => key
export const QUESTION_TYPE = deepFreeze({
  Localization: 'Localization',
  Slider: 'Slider',
  Buttons: 'Buttons',
  DatePicker: 'DatePicker',
  DropDown: 'DropDown',
  FreeText: 'FreeText',
  NumberUnit: 'NumberUnit',
  Emergency: 'Emergency',
})

// Must be mirror like key => key
export const CONDITION_TYPE = deepFreeze({
  Answer: 'Answer',
  Gender: 'Gender',
  Weight: 'Weight',
  Height: 'Height',
  Age: 'Age',
})

// Must be mirror like key => key
export const CONDITION_CRITERIA = deepFreeze({
  AnyOf: 'AnyOf',
  AllOf: 'AllOf',
  Greater: 'Greater',
  Lesser: 'Lesser',
  Equal: 'Equal',
  Between: 'Between',
  NotBetween: 'NotBetween',
})

export const GENDER = deepFreeze({ Male: 'Male', Female: 'Female' })

export const VALID_PARENT_TYPE = deepFreeze([
  QUESTION_TYPE.Buttons,
  QUESTION_TYPE.Slider,
  QUESTION_TYPE.DropDown,
])

export const DEFAULT_QUESTION = deepFreeze({
  text: i18n.t('type_question'),
  possible_answers: [],
  content: {
    slideName: 'New Question',
    isMandatory: false,
    isConditional: false,
  },
  template: QUESTION_TYPE.FreeText,
})

const numRx = /^\d+$/
function isValidNumber(str) {
  return str && numRx.test(str)
}

const validateCondition = (question, index, complaint) => {
  const condition = getProperty(question, 'content.condition', {})
  let result = null
  const { type, criteria, value, parentIdx } = condition
  if (!type) {
    result = 'Type is required'
  } else {
    switch (type) {
      case CONDITION_TYPE.Weight:
      case CONDITION_TYPE.Height:
      case CONDITION_TYPE.Age:
        const validCriterias = [
          CONDITION_CRITERIA.Greater,
          CONDITION_CRITERIA.Lesser,
          CONDITION_CRITERIA.Between,
          CONDITION_CRITERIA.NotBetween,
        ]
        if (!validCriterias.includes(criteria)) {
          result = 'Criteria is required'
        } else if (!value) {
          result = 'Bound is required'
        } else {
          if (criteria === CONDITION_CRITERIA.Greater || criteria === CONDITION_CRITERIA.Lesser) {
            const [bound] = value
            if (!bound) {
              result = 'Bound is required'
            } else if (!isValidNumber(bound)) {
              result = 'Bound is not a valid number'
            }
          } else if (
            criteria === CONDITION_CRITERIA.Between ||
            criteria === CONDITION_CRITERIA.NotBetween
          ) {
            const [boundMin, boundMax] = value
            if (!boundMin) {
              result = 'Min is required'
            } else if (!isValidNumber(boundMin)) {
              result = 'Min is not a valid number'
            } else if (!boundMax) {
              result = 'Max is required'
            } else if (!isValidNumber(boundMax)) {
              result = 'Max is not a valid number'
            }
          }
        }
        break
      case CONDITION_TYPE.Answer:
        if (typeof parentIdx === 'undefined') {
          result = 'Parent question is required'
        } else if (parentIdx >= 0) {
          const parentQuestion = complaint.questions[parentIdx]
          if (!parentQuestion) result = 'Parent question is required'
          else if (!VALID_PARENT_TYPE.includes(parentQuestion.template))
            result = 'Invalid parent question type'
        }
        break
      case CONDITION_TYPE.Gender:
        if (!value || !value[0]) {
          result = 'Gender is required'
        }
        break
      default:
        break
    }
  }
  if (result) {
    return result
  }
}

/**
 * Function to normalize a question
 * @param {*} question question to normalize
 * @returns normalized question
 */
export function normalizeQuestion(question) {
  // TODO
  const result = cloneDeep(question)
  ;['content.slideName', 'text'].forEach(path => trimObjProperty(result, path))
  return result
}

/**
 * Function to validate a question
 * @param {*} question question to validate
 * @returns nothing if valid, error mapping if errors
 */
export const validateQuestion = complaint => (question, index) => {
  const values = normalizeQuestion(question)
  const { template, text, possible_answers = [], content = {} } = values
  const name = getProperty(values, 'content.slideName')
  const isConditional = getProperty(values, 'content.isConditional')
  // init result
  const errors = {}
  let hasError = false

  if (!name) {
    setProperty(errors, 'content.slideName', 'Required')
    hasError = true
  } else if (name.length > 255) {
    setProperty(errors, 'content.slideName', 'Too Long')
    hasError = true
  } else if (name.length < 3) {
    setProperty(errors, 'content.slideName', 'Min 3 characters')
    hasError = true
  }
  if (isConditional) {
    const conditionError = validateCondition(question, index, complaint)
    if (conditionError) {
      hasError = true
      setProperty(errors, 'content.condition.error', conditionError)
    }
  }

  if (!text) {
    setProperty(errors, 'text', 'Required')
    hasError = true
  } else if (text.length > 1000) {
    setProperty(errors, 'text', 'Too Long')
    hasError = true
  } else if (text.length < 3) {
    setProperty(errors, 'text', 'Min 3 characters')
    hasError = true
  }

  if (!template) {
    setProperty(errors, 'template', 'Required')
  }

  switch (template) {
    case QUESTION_TYPE.Buttons:
    case QUESTION_TYPE.Slider:
    case QUESTION_TYPE.DropDown:
      const entityPlural = {
        [QUESTION_TYPE.Buttons]: 'buttons',
        [QUESTION_TYPE.DropDown]: 'options',
        [QUESTION_TYPE.Slider]: 'steps',
      }[template]
      const maxLength = {
        [QUESTION_TYPE.Buttons]: 2000,
        [QUESTION_TYPE.DropDown]: 2000,
        [QUESTION_TYPE.Slider]: 2000,
      }[template]

      errors.possible_answers = []
      if (possible_answers) {
        for (let idx in possible_answers) {
          if (!possible_answers[idx] || !possible_answers[idx].text) {
            setProperty(errors, `possible_answers[${idx}]`, 'Required')
            hasError = true
          } else if (possible_answers[idx].text.length > maxLength) {
            setProperty(errors, `possible_answers[${idx}]`, `Max length ${maxLength} characters`)
            hasError = true
          }
        }
      }

      if (!possible_answers || possible_answers.length < 2) {
        setProperty(errors, `possible_answers.${ARRAY_ERROR}`, `Min 2 ${entityPlural}`)
        hasError = true
      }
      break
    case QUESTION_TYPE.NumberUnit:
      const { min = '', max = '', suffix } = content
      let minValue = parseFloat(min.toString())
      let maxValue = parseFloat(max.toString())
      if (!min) {
        setProperty(errors, `content.min`, `Required`)
        hasError = true
      } else if (isNaN(minValue)) {
        setProperty(errors, `content.min`, `Must be a number`)
        hasError = true
      }
      if (!max) {
        setProperty(errors, `content.max`, `Required`)
        hasError = true
      } else if (isNaN(maxValue)) {
        setProperty(errors, `content.max`, `Must be a number`)
        hasError = true
      }
      if (!isNaN(minValue) && !isNaN(maxValue) && minValue >= maxValue) {
        setProperty(errors, `content.max`, `Must be greater than minimum`)
        hasError = true
      }
      if (suffix && suffix.length > 10) {
        setProperty(errors, `content.suffix`, `Max 10 characters`)
        hasError = true
      }
      break
    case QUESTION_TYPE.Localization:
      const { lockZoom, preZoom } = content
      if (lockZoom && !preZoom) {
        setProperty(errors, `content.preZoom`, `Required when zoom is locked`)
        hasError = true
      }
      break
    default:
      break
  }

  if (hasError) {
    return errors
  }
}

/**
 * Search questions by slide name
 * @param {*} questions
 * @param {*} searchText
 */
export function searchQuestions(questions, searchText) {
  let result = questions
  let lowerSearch = searchText.toLowerCase()
  if (searchText) {
    result = questions
      // compute match index
      .map(item => [
        item,
        getProperty(item, 'content.slideName').toLowerCase().indexOf(lowerSearch),
      ])
      // filter out when no match
      .filter(([, idx]) => idx !== -1)
      // sort by match index
      .sort(([, i1], [, i2]) => i1 - i2)
      // remap to model only
      .map(([item]) => item)
  }
  return result
}
