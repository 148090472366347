import BODYPARTS from './bodyparts';

export const ORIENTATION = {
  FRONT: 0,
  LEFT: 1,
  BACK: 2,
  RIGHT: 3,
};

const ROTATION_ZONE = {
  Body: 'Body',
  Right_Arm: 'Right Arm',
  Left_Arm: 'Left Arm',
  Right_Leg: 'Right Leg',
  Left_Leg: 'Left Leg',
  Right_Foot: 'Right Foot',
  Left_Foot: 'Left Foot',
  Right_Hand: 'Right_Hand',
  Left_Hand: 'Left_Hand',
  Head: 'Head',
  Chest: 'Chest',
  Abdomen: 'Abdomen',
  Hip: 'Hip',
};

export const BODYPARTS_DATA = {
  [BODYPARTS.Body_F]: {
    rotation: ROTATION_ZONE.Body,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Body_B]: {
    rotation: ROTATION_ZONE.Body,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Body_L]: {
    rotation: ROTATION_ZONE.Body,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Body_R]: {
    rotation: ROTATION_ZONE.Body,
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Arm_RF]: {
    rotation: ROTATION_ZONE.Right_Arm,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Medial_Elbow_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Lateral_Elbow_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Biceps_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Hand_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Ventral_Lower_Arm_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Arm_RB]: {
    rotation: ROTATION_ZONE.Right_Arm,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Olecranon_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Medial_Elbow_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Lateral_Elbow_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Triceps_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Hand_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Dorsal_Lower_Arm_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Arm_LF]: {
    rotation: ROTATION_ZONE.Left_Arm,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Medial_Elbow_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Lateral_Elbow_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Biceps_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Hand_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Ventral_Lower_Arm_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Arm_LB]: {
    rotation: ROTATION_ZONE.Left_Arm,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Olecranon_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Medial_Elbow_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Lateral_Elbow_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Triceps_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Hand_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Dorsal_Lower_Arm_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Leg_RF]: {
    rotation: ROTATION_ZONE.Right_Leg,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Upper_Leg_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Lower_Leg_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Knee_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Foot_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Medial_Thigh_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Leg_RB]: {
    rotation: ROTATION_ZONE.Right_Leg,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Hamstrings_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Foot_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Popliteal_Region_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Calve_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Leg_RM]: {
    rotation: ROTATION_ZONE.Right_Leg,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Medial_Thigh_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Medial_Knee_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Medial_Upper_Leg_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Medial_Hamstrings_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Knee_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Politea_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Calve_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Medial_Lower_Leg_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Foot_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Right_Leg_RL]: {
    rotation: ROTATION_ZONE.Right_Leg,
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Lateral_Thigh_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Lateral_Knee_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Lateral_Upper_Leg_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Lateral_Hamstrings_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Knee_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Politea_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Calve_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Lateral_Lower_Leg_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Foot_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Left_Leg_LF]: {
    rotation: ROTATION_ZONE.Left_Leg,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Upper_Leg_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Lower_Leg_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Knee_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Foot_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Medial_Thigh_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Leg_LB]: {
    rotation: ROTATION_ZONE.Left_Leg,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Hamstrings_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Foot_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Popliteal_Region_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Calve_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Leg_LM]: {
    rotation: ROTATION_ZONE.Left_Leg,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Medial_Thigh_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Medial_Knee_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Medial_Upper_Leg_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Medial_Hamstrings_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Knee_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Politea_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Calve_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Medial_Lower_Leg_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Foot_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Left_Leg_LL]: {
    rotation: ROTATION_ZONE.Left_Leg,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Lateral_Thigh_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Lateral_Knee_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Lateral_Upper_Leg_LL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Lateral_Hamstrings_LL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Knee_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Politea_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Calve_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Lateral_Lower_Leg_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Foot_LL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Foot_RF]: {
    rotation: ROTATION_ZONE.Right_Foot,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Hallux_1_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Hallux_2_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Hallux_3_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Hallux_4_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Hallux_5_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Forefoot_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Ankle_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Foot_RB]: {
    rotation: ROTATION_ZONE.Right_Foot,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Achillesheel_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Lateral_Ankle_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Medial_Ankle_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Heel_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Sole_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Foot_RM]: {
    rotation: ROTATION_ZONE.Right_Foot,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Right_Medial_Ankle_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Right_Medial_Foot_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Right_Heel_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Right_Sole_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Right_Forefoot_RM]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Right_Foot_RL]: {
    rotation: ROTATION_ZONE.Right_Foot,
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Lateral_Ankle_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Lateral_Foot_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Heel_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Sole_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Forefoot_RL]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Left_Foot_LF]: {
    rotation: ROTATION_ZONE.Left_Foot,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Hallux_1_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Hallux_2_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Hallux_3_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Hallux_4_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Hallux_5_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Forefoot_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Front_Ankle_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Foot_LB]: {
    rotation: ROTATION_ZONE.Left_Foot,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Achillesheel_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Lateral_Ankle_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Medial_Ankle_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Heel_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Sole_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Foot_LM]: {
    rotation: ROTATION_ZONE.Left_Foot,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Medial_Ankle_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Left_Medial_Foot_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Left_Heel_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Left_Sole_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Left_Forefoot_LM]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Left_Foot_LL]: {
    rotation: ROTATION_ZONE.Left_Foot,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Lateral_Ankle_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Lateral_Foot_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Heel_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Sole_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Forefoot_LL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Hand_LF]: {
    rotation: ROTATION_ZONE.Left_Hand,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Thumb_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Index_Finger_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Middle_Finger_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Ring_Finger_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Pink_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Palm_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Wrist_LF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Hand_LB]: {
    rotation: ROTATION_ZONE.Left_Hand,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Thumb_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Index_Finger_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Middle_Finger_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Ring_Finger_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Pink_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Back_Hand_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Wrist_LB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Hand_RF]: {
    rotation: ROTATION_ZONE.Right_Hand,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Thumb_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Index_Finger_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Middle_Finger_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Ring_Finger_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Pink_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Palm_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Wrist_RF]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Hand_RB]: {
    rotation: ROTATION_ZONE.Right_Hand,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Thumb_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Index_Finger_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Middle_Finger_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Ring_Finger_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Pink_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Back_Hand_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Wrist_RB]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Head_F]: {
    rotation: ROTATION_ZONE.Head,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Head_Top_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Throat_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Chin_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Neck_Left_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Neck_Right_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Jaw_Left_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Jaw_Right_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Eye_Left_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Eye_Right_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Ear_Left_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Ear_Right_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Cheek_Left_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Cheek_Right_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Mouth_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Nose_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Lower_Neck_Left_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Lower_Neck_Right_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Forehead_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Head_B]: {
    rotation: ROTATION_ZONE.Head,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Backside_Head_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Head_Top_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Ear_Left_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Ear_Right_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Neck_Left_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Neck_Right_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Cervical_neck_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Lower_Neck_Left_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Lower_Neck_Right_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Head_L]: {
    rotation: ROTATION_ZONE.Head,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Mastoid_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Backside_Head_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Head_Top_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Chin_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Neck_Frontside_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Jaw_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Eye_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Ear_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Temporal_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Cheek_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Mouth_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Neck_Backside_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Nose_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Lower_Neck_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Throat_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Preauricular_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Forehead_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Head_R]: {
    rotation: ROTATION_ZONE.Head,
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Mastoid_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Backside_Head_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Head_Top_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Chin_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Neck_Frontside_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Jaw_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Eye_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Ear_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Temporal_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Cheek_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Mouth_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Neck_Backside_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Nose_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Lower_Neck_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Throat_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Preauricular_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Forehead_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Upper_Back_B]: {
    rotation: ROTATION_ZONE.Chest,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Upper_Left_Flank_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Upper_Left_Paravertebral_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Back_Left_Shoulder_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Back_Left_Ribcage_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Shoulder_Blade_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Mid_Left_Paravertebral_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Upper_Right_Flank_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Upper_Right_Paravertebral_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Back_Right_Shoulder_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Back_Right_Ribcage_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Shoulder_Blade_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Mid_Right_Paravertebral_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Upper_Spinal_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Mid_Spinal_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Torso_F]: {
    rotation: ROTATION_ZONE.Chest,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Sternum_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Lower_Chest_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Upper_Chest_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Front_Shoulder_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Clavicle_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Lower_Chest_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Upper_Chest_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Front_Shoulder_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Clavicle_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Torso_R]: {
    rotation: ROTATION_ZONE.Chest,
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Upper_Chest_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Upper_Right_Flank_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Shoulder_Blade_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Lower_Chest_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Front_Right_Ribcage_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Torso_L]: {
    rotation: ROTATION_ZONE.Chest,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Upper_Chest_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Upper_Left_Flank_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Shoulder_Blade_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Lower_Chest_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Front_Left_Ribcage_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Abdomen_F]: {
    rotation: ROTATION_ZONE.Abdomen,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Epigastric_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Upper_Abdomen_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Upper_Abdomen_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Lower_Abdomen_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Lower_Abdomen_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Umbilical_region_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Middle_Abdomen_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Middle_Abdomen_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Lumbal_region_B]: {
    rotation: ROTATION_ZONE.Abdomen,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Lumbal_Paravertebral_Left_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Lumbal_Paravertebral_Right_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_SI_Joint_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_SI_Joint_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Lowerback_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Lowerback_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Flank_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Flank_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Lumbal_spine_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Abdomen_L]: {
    rotation: ROTATION_ZONE.Abdomen,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Upper_Flank_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Middle_Abdomen_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Lower_Flank_L]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Abdomen_R]: {
    rotation: ROTATION_ZONE.Abdomen,
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Upper_Flank_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Middle_Abdomen_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Lower_Flank_R]: {
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Hip_Region_F]: {
    rotation: ROTATION_ZONE.Hip,
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Hipbone_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Hipbone_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Lower_Abdominal_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Left_Inguinals_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Right_Inguinals_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Pubic_region_F]: {
    orientation: ORIENTATION.FRONT,
  },
  [BODYPARTS.Hip_Region_B]: {
    rotation: ROTATION_ZONE.Hip,
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Left_Gluteal_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Right_Gluteal_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Perianal_region_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Sacral_region_B]: {
    orientation: ORIENTATION.BACK,
  },
  [BODYPARTS.Hip_Region_L]: {
    rotation: ROTATION_ZONE.Hip,
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Left_Gluteal_BL]: {
    orientation: ORIENTATION.LEFT,
  },
  [BODYPARTS.Hip_Region_R]: {
    rotation: ROTATION_ZONE.Hip,
    orientation: ORIENTATION.RIGHT,
  },
  [BODYPARTS.Right_Gluteal_BR]: {
    orientation: ORIENTATION.RIGHT,
  },
};
