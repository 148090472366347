import React from 'react';
import classNames from 'classnames';

import styles from './ErrorText.module.scss';

const ErrorText = ({ className, fixed = true, children }) => (
  <span className={classNames(className, styles.errorText)}>
    {children}
    {fixed && <>&nbsp;</>}
  </span>
);

export default ErrorText;
