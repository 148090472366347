import React, { memo, useCallback } from 'react'
import { Field } from 'react-final-form'

import { TextInput, TextArea, DropDown, Toggle } from '../../../fieldRenderers'
import { Container } from './Styles'

const ACCOUNT_FIELD = [
  { value: 'age', label: 'Age' },
  { value: 'gender', label: 'Gender' },
  { value: 'weight', label: 'Weight' },
  { value: 'height', label: 'Height' },
]
const CONDITION_OPERATOR = [
  // {value: 'anyof', label: 'Any Of'},
  { value: 'greater', label: 'Greater than' },
  { value: 'lesser', label: 'Lesser than' },
  { value: 'equal', label: 'Equal to' },
]
const GENDERS = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
]

const PatientConditions = ({ rfIndex, fields }) => {
  const handleConditionAdd = useCallback(
    val => {
      if (val === 'gender') {
        fields.push({ account_field: val, condition: { operator: 'equal', parameters: [] } })
      } else fields.push({ account_field: val, condition: { parameters: [] } })
    },
    [fields]
  )

  return (
    <Container>
      {fields.map((f, idx) => {
        const currentField = fields.value[idx]
        return (
          <>
            <Field
              label="Condition"
              name={`red_flags[${rfIndex}].account_conditions[${idx}].account_field`}
              component={DropDown}
              options={ACCOUNT_FIELD}
            />
            {currentField.account_field === 'gender' ? (
              <Field
                name={`red_flags[${rfIndex}].account_conditions[${idx}].condition.parameters[0]`}
                component={DropDown}
                options={GENDERS}
              />
            ) : (
              <>
                <Field
                  name={`red_flags[${rfIndex}].account_conditions[${idx}].condition.operator`}
                  component={DropDown}
                  options={CONDITION_OPERATOR}
                />
                <Field
                  name={`red_flags[${rfIndex}].account_conditions[${idx}].condition.parameters[0]`}
                  component={TextInput}
                />
              </>
            )}
            <br />
          </>
        )
      })}
      <br />
      <DropDown
        label="New condition"
        options={ACCOUNT_FIELD}
        input={{
          onChange: handleConditionAdd,
          value: '',
        }}
      />
    </Container>
  )
}

PatientConditions.whyDidYouRender = true

export default memo(PatientConditions)
