import React, { useState, useEffect } from 'react';

import Dummy from '../../Dummy';

import styles from './LocalizationPreview.module.scss';

export default ({ slide = {}, onChange }) => {
  const { content = {} } = slide;
  const { lockZoom, lockRotation, preZoom } = content;
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey((key) => key + 1);
  }, [lockZoom, lockRotation, preZoom]);
  return (
    <div className={styles.container}>
      <Dummy
        key={key}
        className={styles.dummy}
        initialZoom={preZoom}
        canZoom={!lockZoom}
        canRotate={!lockRotation}
      />
    </div>
  );
};
