import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { TextFieldsArray } from '../fieldRenderers';
import ContrastTitle from '../../widgets/ContrastTitle';

import i18n from '../../../i18n';

const SliderPartialForm = ({ input: { name }, disabled }) => (
  <div>
    <ContrastTitle>{i18n.t('dropdown')}</ContrastTitle>
    <FieldArray
      name={`${name}.possible_answers`}
      component={TextFieldsArray}
      formatItem={({ text, index }) => text}
      parseItem={(text, index) => ({ text, index })}
      itemLabel={(idx) => `Option ${idx + 1}`}
      addBtnLabel={i18n.t('add_option')}
      disabled={disabled}
    />
  </div>
);

export default SliderPartialForm;
