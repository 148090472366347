import React from 'react'

import { ROUTES } from 'Constants'
import { DEFAULT_COMPLAINT } from '../../models/Complaint'
import { notifError, notifSuccess } from '../../utils/Notification'
import ComplaintFormView from './ComplaintForm.view'
import { CS, CS_m } from 'back-end-api'

const formatFetchedComplaint = complaint => {
  for (let question of complaint.questions) {
    if (Array.isArray(question.possible_answers)) {
      // sort answers
      question.possible_answers = question.possible_answers.sort((a, b) => a.index - b.index)
    }
  }
  return complaint
}

export const formatQuestions = complaint => {
  const { questions } = complaint
  const outQuestions = questions
  questions.forEach((q, qIdx) => {
    if (!q?.conditional_id) outQuestions[qIdx].conditional_id = Math.round(Math.random() * 100000)
  })
  return { ...complaint, questions: outQuestions }
}

export const formatRedFlags = complaint => {
  const { red_flags, questions } = complaint
  if (!red_flags) return { ...complaint, red_flags: [] }
  const outRF = red_flags.filter(r => r?.text !== '')
  outRF?.forEach((r, rIdx) => {
    r?.answer_conditions?.forEach((a, aIdx) => {
      if (a.question_id) {
        outRF[rIdx].answer_conditions[aIdx].question_conditional_id = questions.find(
          q => q.id === a.question_id
        )?.conditional_id
      }
    })
  })
  return { ...complaint, red_flags: outRF }
}

export default class ComplaintForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: !!this.complaintId,
      error: null,
      initialComplaint: Object.assign({}, DEFAULT_COMPLAINT),
    }
  }

  componentDidMount() {
    // fetch complaint if ID provided
    if (this.complaintId) {
      this.fetchComplaint()
    }
  }

  get complaintId() {
    return (this.props.match && this.props.match.params && this.props.match.params.id) || null
  }

  importComplaint = async rawComplaint => {
    const complaint = formatFetchedComplaint(rawComplaint)
    this.setState({
      initialComplaint: complaint,
      rawComplaint,
      loading: false,
    })
  }

  handleSubmit = async values => {
    const { history } = this.props
    const isEdit = !!this.complaintId

    const formattedByQuestions = formatQuestions(values)
    const valuesToSend = formatRedFlags(formattedByQuestions)

    const res = await (isEdit
      ? CS.updateComplaint({ ...valuesToSend, id: +this.complaintId })
      : CS.createComplaint({ ...valuesToSend, type: CS_m.ModelsComplaintType.standard }))

    if (res.error) {
      console.error(res.error.message)
      notifError('Operation failed')
      return
    }

    this.setState({
      initialComplaint: formatFetchedComplaint(res.data),
      rawComplaint: res.data,
    })
    notifSuccess(`Successfully ${isEdit ? 'Updated' : 'Created'}`)
    history.push(ROUTES.EDIT_COMPLAINT(res.data.id))
  }

  async fetchComplaint() {
    const res = await CS.getComplaint(this.complaintId)
    if (res.error) {
      this.setState({
        loading: false,
        error: 'An unexpected error occurred',
      })
    }

    const complaint = formatFetchedComplaint(res.data)
    this.setState({
      initialComplaint: complaint,
      rawComplaint: res.data,
      loading: false,
    })
  }

  render() {
    const { error, loading, initialComplaint, rawComplaint } = this.state
    const viewProps = {
      loading,
      error,
      editMode: !!this.complaintId,
      initialValues: initialComplaint,
      onSubmit: this.handleSubmit,
      history: this.props.history,
      rawComplaint,
      importComplaint: this.importComplaint,
    }
    return <ComplaintFormView {...viewProps} />
  }
}
