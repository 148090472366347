import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

import App from './App';
import './index.css';

Sentry.init({
  dsn:
    'https://99d72edb2276444fa4afe06273ebb933@o399011.ingest.sentry.io/5257964',
});

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: 'jss-insertion-point',
});

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </JssProvider>,
  document.getElementById('root'),
);
