import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';

import logoSrc from '../../assets/medvice-logo.svg';
import Notifications from '../Notifications';
import styles from './MainHeader.module.scss';

const MainHeader = () => (
  <AppBar
    position="static"
    color="inherit"
    elevation={0}
    className={styles.root}>
    <Toolbar>
      <Link to="/">
        <img src={logoSrc} className={styles.logoImg} alt="Medvice Logo" />
      </Link>
      <div className={styles.notifZone}>
        <Notifications />
      </div>
    </Toolbar>
  </AppBar>
);

export default MainHeader;
