import getProperty from 'lodash/get'
import setProperty from 'lodash/set'

export const composeName = (...nameParts) => nameParts.filter(p => !!p).join('.')

export function trimObjProperty(object, path) {
  setProperty(object, path, (getProperty(object, path) || '').trim())
}

export function deepFreeze(o) {
  // undefined or null (not type comparison, only '==')
  if (o == null) {
    return o
  }
  Object.freeze(o)

  Object.getOwnPropertyNames(o).forEach(prop => {
    if (
      // eslint-disable-next-line no-prototype-builtins
      o.hasOwnProperty(prop) &&
      o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])
    ) {
      deepFreeze(o[prop])
    }
  })

  return o
}

export function getQueryStringArgs() {
  return Object.fromEntries(
    window.location.search
      .slice(1)
      .split('&')
      .filter(kv => !!kv)
      .map(kv => kv.split('='))
      .map(([k, v]) => [k, decodeURIComponent(v)])
  )
}
