import BODYPARTS from './bodyparts';

export default {
  en: {
    [BODYPARTS.Body_F]: 'Body (Front)',
    [BODYPARTS.Body_B]: 'Body (Back)',
    [BODYPARTS.Body_L]: 'Body (Left)',
    [BODYPARTS.Body_R]: 'Body (Right)',
    [BODYPARTS.Right_Arm_RF]: 'right arm (front side)',
    [BODYPARTS.Medial_Elbow_RF]: 'right inner Elbow',
    [BODYPARTS.Lateral_Elbow_RF]: 'right outer Elbow',
    [BODYPARTS.Biceps_RF]: 'right upper arm',
    [BODYPARTS.Hand_RF]: 'right hand',
    [BODYPARTS.Ventral_Lower_Arm_RF]: 'right lower arm',
    [BODYPARTS.Right_Arm_RB]: 'right arm (Front side)',
    [BODYPARTS.Olecranon_RB]: 'right elbow',
    [BODYPARTS.Medial_Elbow_RB]: 'right inner elbow',
    [BODYPARTS.Lateral_Elbow_RB]: 'right outer elbow',
    [BODYPARTS.Triceps_RB]: 'right upper arm (back side)',
    [BODYPARTS.Hand_RB]: 'right Hand',
    [BODYPARTS.Dorsal_Lower_Arm_RB]: 'right lower arm (back side)',
    [BODYPARTS.Left_Arm_LF]: 'Left_Arm_LF',
    [BODYPARTS.Medial_Elbow_LF]: 'left inner elbow',
    [BODYPARTS.Lateral_Elbow_LF]: 'left outer elbow',
    [BODYPARTS.Biceps_LF]: 'left upper arm',
    [BODYPARTS.Hand_LF]: 'left hand',
    [BODYPARTS.Ventral_Lower_Arm_LF]: 'left lower arm',
    [BODYPARTS.Left_Arm_LB]: 'left arm (Back side)',
    [BODYPARTS.Olecranon_LB]: 'left elbow',
    [BODYPARTS.Medial_Elbow_LB]: 'left inner elbow',
    [BODYPARTS.Lateral_Elbow_LB]: 'left outer elbow',
    [BODYPARTS.Triceps_LB]: 'left upper arm (back side)',
    [BODYPARTS.Hand_LB]: 'left hand',
    [BODYPARTS.Dorsal_Lower_Arm_LB]: 'left lower arm (back side)',
    [BODYPARTS.Right_Leg_RF]: 'right leg (front side)',
    [BODYPARTS.Upper_Leg_RF]: 'right upper leg',
    [BODYPARTS.Lower_Leg_RF]: 'right shin',
    [BODYPARTS.Knee_RF]: 'right knee',
    [BODYPARTS.Foot_RF]: 'right foot (front side)',
    [BODYPARTS.Medial_Thigh_RF]: 'right inner thigh',
    [BODYPARTS.Right_Leg_RB]: 'right leg (back side)',
    [BODYPARTS.Hamstrings_RB]: 'right hamstrings',
    [BODYPARTS.Foot_RB]: 'right foot (back side)',
    [BODYPARTS.Popliteal_Region_RB]: 'right knee (Back side)',
    [BODYPARTS.Calve_RB]: 'right calve',
    [BODYPARTS.Right_Leg_RM]: 'right inner Leg',
    [BODYPARTS.Medial_Thigh_RM]: 'right inner thigh',
    [BODYPARTS.Medial_Knee_RM]: 'right inner knee',
    [BODYPARTS.Medial_Upper_Leg_RM]: 'right upper Leg',
    [BODYPARTS.Medial_Hamstrings_RM]: 'right hamstrings',
    [BODYPARTS.Knee_RM]: 'right knee',
    [BODYPARTS.Politea_RM]: 'right knee (back side)',
    [BODYPARTS.Calve_RM]: 'right calve',
    [BODYPARTS.Medial_Lower_Leg_RM]: 'right inner shin',
    [BODYPARTS.Foot_RM]: 'Right Foot RM',
    [BODYPARTS.Right_Leg_RL]: 'right outer leg',
    [BODYPARTS.Lateral_Thigh_RL]: 'right outer thigh',
    [BODYPARTS.Lateral_Knee_RL]: 'right outer knee',
    [BODYPARTS.Lateral_Upper_Leg_RL]: 'right upper leg',
    [BODYPARTS.Lateral_Hamstrings_RL]: 'right hamstrings',
    [BODYPARTS.Knee_RL]: 'right knee',
    [BODYPARTS.Politea_RL]: 'right knee (back side)',
    [BODYPARTS.Calve_RL]: 'right calve',
    [BODYPARTS.Lateral_Lower_Leg_RL]: 'right outer shin',
    [BODYPARTS.Foot_RL]: 'right foot',
    [BODYPARTS.Left_Leg_LF]: 'left leg (front side)',
    [BODYPARTS.Upper_Leg_LF]: 'left upper leg',
    [BODYPARTS.Lower_Leg_LF]: 'left shin',
    [BODYPARTS.Knee_LF]: 'left knee (front side)',
    [BODYPARTS.Foot_LF]: 'left foot (front side)',
    [BODYPARTS.Medial_Thigh_LF]: 'left inner thigh',
    [BODYPARTS.Left_Leg_LB]: 'left leg (back side)',
    [BODYPARTS.Hamstrings_LB]: 'left hamstrings',
    [BODYPARTS.Foot_LB]: 'left foot (back side)',
    [BODYPARTS.Popliteal_Region_LB]: 'left knee (back side)',
    [BODYPARTS.Calve_LB]: 'left calve (back side)',
    [BODYPARTS.Left_Leg_LM]: 'left inner leg',
    [BODYPARTS.Medial_Thigh_LM]: 'left inner thigh',
    [BODYPARTS.Medial_Knee_LM]: 'left inner knee',
    [BODYPARTS.Medial_Upper_Leg_LM]: 'left upper leg',
    [BODYPARTS.Medial_Hamstrings_LM]: 'left hamstrings',
    [BODYPARTS.Knee_LM]: 'left knee',
    [BODYPARTS.Politea_LM]: 'left knee (back side)',
    [BODYPARTS.Calve_LM]: 'left calve',
    [BODYPARTS.Medial_Lower_Leg_LM]: 'left inner shin',
    [BODYPARTS.Foot_LM]: 'left foot',
    [BODYPARTS.Left_Leg_LL]: 'left outer leg',
    [BODYPARTS.Lateral_Thigh_LL]: 'left outer thigh',
    [BODYPARTS.Lateral_Knee_LL]: 'left outer knee',
    [BODYPARTS.Lateral_Upper_Leg_LL]: 'left upper leg',
    [BODYPARTS.Lateral_Hamstrings_LL]: 'left hamstrings',
    [BODYPARTS.Knee_LL]: 'left knee (left side)',
    [BODYPARTS.Politea_LL]: 'left knee (left side)',
    [BODYPARTS.Calve_LL]: 'left calve (left side)',
    [BODYPARTS.Lateral_Lower_Leg_LL]: 'left outer shin',
    [BODYPARTS.Foot_LL]: 'left foot',
    [BODYPARTS.Right_Foot_RF]: 'right foot (front side)',
    [BODYPARTS.Right_Hallux_1_RF]: 'right big toe',
    [BODYPARTS.Right_Hallux_2_RF]: 'right second toe',
    [BODYPARTS.Right_Hallux_3_RF]: 'right middle toe',
    [BODYPARTS.Right_Hallux_4_RF]: 'right fourth toe',
    [BODYPARTS.Right_Hallux_5_RF]: 'right little toe',
    [BODYPARTS.Right_Forefoot_RF]: 'right forefoot',
    [BODYPARTS.Right_Ankle_RF]: 'right front ankle',
    [BODYPARTS.Right_Foot_RB]: 'right foot (back side)',
    [BODYPARTS.Right_Achillesheel_RB]: 'right achillesheel',
    [BODYPARTS.Right_Lateral_Ankle_RB]: 'right outer ankle',
    [BODYPARTS.Right_Medial_Ankle_RB]: 'right inner ankle',
    [BODYPARTS.Right_Heel_RB]: 'right heel',
    [BODYPARTS.Right_Sole_RB]: 'right sole',
    [BODYPARTS.Right_Foot_RM]: 'right foot (inner side)',
    [BODYPARTS.Right_Medial_Ankle_RM]: 'right inner ankle',
    [BODYPARTS.Right_Medial_Foot_RM]: 'right inner foot',
    [BODYPARTS.Right_Heel_RM]: 'right heel',
    [BODYPARTS.Right_Sole_RM]: 'right sole',
    [BODYPARTS.Right_Forefoot_RM]: 'right inner forefoot',
    [BODYPARTS.Right_Foot_RL]: 'right foot (outer side)',
    [BODYPARTS.Right_Lateral_Ankle_RL]: 'right inner ankle',
    [BODYPARTS.Right_Lateral_Foot_RL]: 'right outer foot',
    [BODYPARTS.Right_Heel_RL]: 'right heel',
    [BODYPARTS.Right_Sole_RL]: 'right sole',
    [BODYPARTS.Right_Forefoot_RL]: 'right outer forefoot',
    [BODYPARTS.Left_Foot_LF]: 'left foot (front side)',
    [BODYPARTS.Left_Hallux_1_LF]: 'left big toe',
    [BODYPARTS.Left_Hallux_2_LF]: 'left second toe',
    [BODYPARTS.Left_Hallux_3_LF]: 'left middle toe',
    [BODYPARTS.Left_Hallux_4_LF]: 'left fourth toe',
    [BODYPARTS.Left_Hallux_5_LF]: 'left little toe',
    [BODYPARTS.Left_Forefoot_LF]: 'left forefoot',
    [BODYPARTS.Left_Front_Ankle_LF]: 'left front ankle',
    [BODYPARTS.Left_Foot_LB]: 'left foot (back side)',
    [BODYPARTS.Left_Achillesheel_LB]: 'left achillesheel',
    [BODYPARTS.Left_Lateral_Ankle_LB]: 'left outer ankle',
    [BODYPARTS.Left_Medial_Ankle_LB]: 'left inner ankle',
    [BODYPARTS.Left_Heel_LB]: 'left heel',
    [BODYPARTS.Left_Sole_LB]: 'left sole (back side)',
    [BODYPARTS.Left_Foot_LM]: 'left foot (inner side)',
    [BODYPARTS.Left_Medial_Ankle_LM]: 'left inner ankle',
    [BODYPARTS.Left_Medial_Foot_LM]: 'left inner foot',
    [BODYPARTS.Left_Heel_LM]: 'left heel',
    [BODYPARTS.Left_Sole_LM]: 'left sole',
    [BODYPARTS.Left_Forefoot_LM]: 'left inner forefoot',
    [BODYPARTS.Left_Foot_LL]: 'left foot (outer side)',
    [BODYPARTS.Left_Lateral_Ankle_LL]: 'left outer ankle',
    [BODYPARTS.Left_Lateral_Foot_LL]: 'left outer foot',
    [BODYPARTS.Left_Heel_LL]: 'left heel',
    [BODYPARTS.Left_Sole_LL]: 'left sole (left side)',
    [BODYPARTS.Left_Forefoot_LL]: 'left outer forefoot',
    [BODYPARTS.Left_Hand_LF]: 'left hand (front side)',
    [BODYPARTS.Left_Thumb_LF]: 'left thumb',
    [BODYPARTS.Left_Index_Finger_LF]: 'left index finger',
    [BODYPARTS.Left_Middle_Finger_LF]: 'left middle finger',
    [BODYPARTS.Left_Ring_Finger_LF]: 'left ring finger',
    [BODYPARTS.Left_Pink_LF]: 'left pink',
    [BODYPARTS.Left_Palm_LF]: 'left palm',
    [BODYPARTS.Left_Wrist_LF]: 'left wrist',
    [BODYPARTS.Left_Hand_LB]: 'left hand (back side)',
    [BODYPARTS.Left_Thumb_LB]: 'left thumb (back side)',
    [BODYPARTS.Left_Index_Finger_LB]: 'left index finger (back side)',
    [BODYPARTS.Left_Middle_Finger_LB]: 'left middle finger (back side)',
    [BODYPARTS.Left_Ring_Finger_LB]: 'left ring finger (back side)',
    [BODYPARTS.Left_Pink_LB]: 'left pink (back side)',
    [BODYPARTS.Left_Back_Hand_LB]: 'left back hand',
    [BODYPARTS.Left_Wrist_LB]: 'left wrist (back side)',
    [BODYPARTS.Right_Hand_RF]: 'right hand (front side)',
    [BODYPARTS.Right_Thumb_RF]: 'right thumb',
    [BODYPARTS.Right_Index_Finger_RF]: 'right index finger',
    [BODYPARTS.Right_Middle_Finger_RF]: 'right middle finger',
    [BODYPARTS.Right_Ring_Finger_RF]: 'right ring finger',
    [BODYPARTS.Right_Pink_RF]: 'right pink',
    [BODYPARTS.Right_Palm_RF]: 'right palm',
    [BODYPARTS.Right_Wrist_RF]: 'right wrist',
    [BODYPARTS.Right_Hand_RB]: 'right hand (back side)',
    [BODYPARTS.Right_Thumb_RB]: 'right thumb (back side)',
    [BODYPARTS.Right_Index_Finger_RB]: 'right index finger (Back side)',
    [BODYPARTS.Right_Middle_Finger_RB]: 'right middle finger (Back side)',
    [BODYPARTS.Right_Ring_Finger_RB]: 'right ring finger (back side)',
    [BODYPARTS.Right_Pink_RB]: 'right pink (back side)',
    [BODYPARTS.Right_Back_Hand_RB]: 'right back hand',
    [BODYPARTS.Right_Wrist_RB]: 'right wrist (back side)',
    [BODYPARTS.Head_F]: 'head (front side)',
    [BODYPARTS.Head_Top_F]: 'top head',
    [BODYPARTS.Throat_F]: 'throat',
    [BODYPARTS.Chin_F]: 'chin',
    [BODYPARTS.Neck_Left_F]: 'left neck',
    [BODYPARTS.Neck_Right_F]: 'right neck',
    [BODYPARTS.Jaw_Left_F]: 'left jaw',
    [BODYPARTS.Jaw_Right_F]: 'right jaw',
    [BODYPARTS.Eye_Left_F]: 'left rye',
    [BODYPARTS.Eye_Right_F]: 'right rye',
    [BODYPARTS.Ear_Left_F]: 'left rar',
    [BODYPARTS.Ear_Right_F]: 'right rar',
    [BODYPARTS.Cheek_Left_F]: 'left cheek',
    [BODYPARTS.Cheek_Right_F]: 'right cheek',
    [BODYPARTS.Mouth_F]: 'mouth',
    [BODYPARTS.Nose_F]: 'nose',
    [BODYPARTS.Lower_Neck_Left_F]: 'left lower neck',
    [BODYPARTS.Lower_Neck_Right_F]: 'right lower neck',
    [BODYPARTS.Forehead_F]: 'forehead',
    [BODYPARTS.Head_B]: 'Head (back side)',
    [BODYPARTS.Backside_Head_B]: 'backside of head (back side)',
    [BODYPARTS.Head_Top_B]: 'top head (back side)',
    [BODYPARTS.Ear_Left_B]: 'left ear',
    [BODYPARTS.Ear_Right_B]: 'right ear',
    [BODYPARTS.Neck_Left_B]: 'left neck (back side)',
    [BODYPARTS.Neck_Right_B]: 'right neck (back side)',
    [BODYPARTS.Cervical_neck_B]: 'neck spine',
    [BODYPARTS.Lower_Neck_Left_B]: 'left lower neck (back side)',
    [BODYPARTS.Lower_Neck_Right_B]: 'right lower neck (back side)',
    [BODYPARTS.Head_L]: 'head (left side)',
    [BODYPARTS.Mastoid_L]: 'behind the left ear',
    [BODYPARTS.Backside_Head_L]: 'backside of head (left side)',
    [BODYPARTS.Head_Top_L]: 'top head',
    [BODYPARTS.Chin_L]: 'chin',
    [BODYPARTS.Neck_Frontside_L]: 'left neck (front side)',
    [BODYPARTS.Jaw_L]: 'left jaw',
    [BODYPARTS.Eye_L]: 'left eye',
    [BODYPARTS.Ear_L]: 'left ear',
    [BODYPARTS.Temporal_L]: 'left temple',
    [BODYPARTS.Cheek_L]: 'left cheek',
    [BODYPARTS.Mouth_L]: 'mouth',
    [BODYPARTS.Neck_Backside_L]: 'left neck (back side)',
    [BODYPARTS.Nose_L]: 'nose',
    [BODYPARTS.Lower_Neck_L]: 'left lower neck',
    [BODYPARTS.Throat_L]: 'throat (left side)',
    [BODYPARTS.Preauricular_L]: 'front side of left ear',
    [BODYPARTS.Forehead_L]: 'forehead (left side)',
    [BODYPARTS.Head_R]: 'head (right side)',
    [BODYPARTS.Mastoid_R]: 'behind the right ear',
    [BODYPARTS.Backside_Head_R]: 'backside of head (right side)',
    [BODYPARTS.Head_Top_R]: 'top head',
    [BODYPARTS.Chin_R]: 'chin',
    [BODYPARTS.Neck_Frontside_R]: 'right neck (front side)',
    [BODYPARTS.Jaw_R]: 'right jaw',
    [BODYPARTS.Eye_R]: 'right eye',
    [BODYPARTS.Ear_R]: 'right ear',
    [BODYPARTS.Temporal_R]: 'right temple',
    [BODYPARTS.Cheek_R]: 'right cheek',
    [BODYPARTS.Mouth_R]: 'mouth',
    [BODYPARTS.Neck_Backside_R]: 'right neck (back side)',
    [BODYPARTS.Nose_R]: 'nose',
    [BODYPARTS.Lower_Neck_R]: 'right lower neck',
    [BODYPARTS.Throat_R]: 'throat (right side)',
    [BODYPARTS.Preauricular_R]: 'front side of right ear',
    [BODYPARTS.Forehead_R]: 'forehead (right side)',
    [BODYPARTS.Upper_Back_B]: 'upper back',
    [BODYPARTS.Upper_Left_Flank_B]: 'upper left flank',
    [BODYPARTS.Upper_Left_Paravertebral_B]: 'middle upper back (left side)',
    [BODYPARTS.Back_Left_Shoulder_B]: 'left shoulder (back side)',
    [BODYPARTS.Back_Left_Ribcage_B]: 'left ribcage (back side)',
    [BODYPARTS.Left_Shoulder_Blade_B]: 'left shoulder blade',
    [BODYPARTS.Mid_Left_Paravertebral_B]: 'middle back (left side)',
    [BODYPARTS.Upper_Right_Flank_B]: 'upperflank right',
    [BODYPARTS.Upper_Right_Paravertebral_B]: 'middle upper back (right side)',
    [BODYPARTS.Back_Right_Shoulder_B]: 'right shoulder (back side)',
    [BODYPARTS.Back_Right_Ribcage_B]: 'right ribcage (back side)',
    [BODYPARTS.Right_Shoulder_Blade_B]: 'right shoulder blade',
    [BODYPARTS.Mid_Right_Paravertebral_B]: 'middle back (right side)',
    [BODYPARTS.Upper_Spinal_B]: 'upper spine',
    [BODYPARTS.Mid_Spinal_B]: 'middle spine',
    [BODYPARTS.Torso_F]: 'chest',
    [BODYPARTS.Sternum_F]: 'sternum',
    [BODYPARTS.Left_Lower_Chest_F]: 'lower chest left',
    [BODYPARTS.Left_Upper_Chest_F]: 'upper chest left',
    [BODYPARTS.Left_Front_Shoulder_F]: 'left front shoulder',
    [BODYPARTS.Left_Clavicle_F]: 'left clavicle',
    [BODYPARTS.Right_Lower_Chest_F]: 'lower chest right',
    [BODYPARTS.Right_Upper_Chest_F]: 'upper chest right',
    [BODYPARTS.Right_Front_Shoulder_F]: 'right front shoulder',
    [BODYPARTS.Right_Clavicle_F]: 'right clavicle',
    [BODYPARTS.Torso_R]: 'chest (right side)',
    [BODYPARTS.Right_Upper_Chest_R]: 'upper right chest',
    [BODYPARTS.Upper_Right_Flank_R]: 'upper right flank',
    [BODYPARTS.Right_Shoulder_Blade_R]: 'right shoulder blade',
    [BODYPARTS.Right_Lower_Chest_R]: 'lower right chest',
    [BODYPARTS.Front_Right_Ribcage_R]: 'rightside front ribcage',
    [BODYPARTS.Torso_L]: 'chest (left side)',
    [BODYPARTS.Left_Upper_Chest_L]: 'upper left chest',
    [BODYPARTS.Upper_Left_Flank_L]: 'upper left flank',
    [BODYPARTS.Left_Shoulder_Blade_L]: 'left shoulder blade',
    [BODYPARTS.Left_Lower_Chest_L]: 'lower left chest',
    [BODYPARTS.Front_Left_Ribcage_L]: 'leftside front ribcage',
    [BODYPARTS.Abdomen_F]: 'Abdomen / Belly',
    [BODYPARTS.Epigastric_F]: 'upper abdomen / stomach',
    [BODYPARTS.Left_Upper_Abdomen_F]: 'left upper abdomen',
    [BODYPARTS.Right_Upper_Abdomen_F]: 'right upper abdomen',
    [BODYPARTS.Left_Lower_Abdomen_F]: 'left lower abdomen',
    [BODYPARTS.Right_Lower_Abdomen_F]: 'right lower abdomen',
    [BODYPARTS.Umbilical_region_F]: 'middle abdomen',
    [BODYPARTS.Right_Middle_Abdomen_F]: 'rightside of abdomen',
    [BODYPARTS.Left_Middle_Abdomen_F]: 'leftside of abdomen',
    [BODYPARTS.Lumbal_region_B]: 'lower Back',
    [BODYPARTS.Lumbal_Paravertebral_Left_B]: 'middle lowerback (left side)',
    [BODYPARTS.Lumbal_Paravertebral_Right_B]: 'middle lowerback (right side)',
    [BODYPARTS.Left_SI_Joint_B]: 'lowerback pit left',
    [BODYPARTS.Right_SI_Joint_B]: 'lowerback pit right',
    [BODYPARTS.Left_Lowerback_B]: 'left lower back',
    [BODYPARTS.Right_Lowerback_B]: 'right lower back',
    [BODYPARTS.Left_Flank_B]: 'left flank',
    [BODYPARTS.Right_Flank_B]: 'right flank',
    [BODYPARTS.Lumbal_spine_B]: 'lower spine',
    [BODYPARTS.Abdomen_L]: 'Belly (left side)',
    [BODYPARTS.Left_Upper_Flank_L]: 'left upper flank',
    [BODYPARTS.Left_Middle_Abdomen_L]: 'left middle belly',
    [BODYPARTS.Left_Lower_Flank_L]: 'left lower flank',
    [BODYPARTS.Abdomen_R]: 'Belly (right side)',
    [BODYPARTS.Right_Upper_Flank_R]: 'right upper flank',
    [BODYPARTS.Right_Middle_Abdomen_R]: 'right middle belly',
    [BODYPARTS.Right_Lower_Flank_R]: 'right lower flank',
    [BODYPARTS.Hip_Region_F]: 'hip area (front side)',
    [BODYPARTS.Left_Hipbone_F]: 'left hipbone',
    [BODYPARTS.Right_Hipbone_F]: 'right hipbone',
    [BODYPARTS.Lower_Abdominal_F]: 'lower abdomen',
    [BODYPARTS.Left_Inguinals_F]: 'left groin',
    [BODYPARTS.Right_Inguinals_F]: 'right groin',
    [BODYPARTS.Pubic_region_F]: 'pubic area',
    [BODYPARTS.Hip_Region_B]: 'hip area (back side)',
    [BODYPARTS.Left_Gluteal_B]: 'left buttock',
    [BODYPARTS.Right_Gluteal_B]: 'right buttock',
    [BODYPARTS.Perianal_region_B]: 'anal region',
    [BODYPARTS.Sacral_region_B]: 'tailbone region',
    [BODYPARTS.Hip_Region_L]: 'hip area (left side)',
    [BODYPARTS.Left_Gluteal_BL]: 'left buttock',
    [BODYPARTS.Hip_Region_R]: 'hip area (right side)',
    [BODYPARTS.Right_Gluteal_BR]: 'right buttock',
  },
  nl: {
    [BODYPARTS.Body_F]: 'Lichaam (Voor)',
    [BODYPARTS.Body_B]: 'Lichaam (Achter)',
    [BODYPARTS.Body_L]: 'Lichaam (Links)',
    [BODYPARTS.Body_R]: 'Lichaam (Rechts)',
    [BODYPARTS.Right_Arm_RF]: 'de voorzijde van de rechter arm',
    [BODYPARTS.Medial_Elbow_RF]: 'de binnenzijde van de rechter elleboog',
    [BODYPARTS.Lateral_Elbow_RF]: 'de buitenzijde van de rechter elleboog',
    [BODYPARTS.Biceps_RF]: 'de voorzijde van de rechter bovenarm',
    [BODYPARTS.Hand_RF]: 'de rechter handpalm',
    [BODYPARTS.Ventral_Lower_Arm_RF]: 'de voorzijde van de rechter onderarm',
    [BODYPARTS.Right_Arm_RB]: 'de achterzijde van de rechter arm',
    [BODYPARTS.Olecranon_RB]: 'de rechter elleboog',
    [BODYPARTS.Medial_Elbow_RB]: 'de binnenzijde van de rechter elleboog',
    [BODYPARTS.Lateral_Elbow_RB]: 'de buitenzijde van de rechter elleboog',
    [BODYPARTS.Triceps_RB]: 'de achterzijde van de rechter bovenarm',
    [BODYPARTS.Hand_RB]: 'de rechter handrug',
    [BODYPARTS.Dorsal_Lower_Arm_RB]: 'de achterzijde van de rechter onderarm',
    [BODYPARTS.Left_Arm_LF]: 'de voorzijde van de linker arm',
    [BODYPARTS.Medial_Elbow_LF]: 'de binnenzijde van de linker elleboog',
    [BODYPARTS.Lateral_Elbow_LF]: 'de buitenzijde van de linker elleboog',
    [BODYPARTS.Biceps_LF]: 'de voorzijde van de linker bovenarm',
    [BODYPARTS.Hand_LF]: 'de linker handpalm',
    [BODYPARTS.Ventral_Lower_Arm_LF]: 'de voorzijde van de linker onderarm',
    [BODYPARTS.Left_Arm_LB]: 'de achterzijde van de linker arm',
    [BODYPARTS.Olecranon_LB]: 'de linker elleboog',
    [BODYPARTS.Medial_Elbow_LB]: 'de binnenzijde van de linker elleboog',
    [BODYPARTS.Lateral_Elbow_LB]: 'de buitenzijde van de linker  elleboog',
    [BODYPARTS.Triceps_LB]: 'de achterzijde van de linker bovenarm',
    [BODYPARTS.Hand_LB]: 'de linker handrug',
    [BODYPARTS.Dorsal_Lower_Arm_LB]: 'de achterzijde van de linker onderarm',
    [BODYPARTS.Right_Leg_RF]: 'de voorzijde van het rechter been',
    [BODYPARTS.Upper_Leg_RF]: 'de voorzijde van het rechter bovenbeen',
    [BODYPARTS.Lower_Leg_RF]: 'het rechter scheenbeen',
    [BODYPARTS.Knee_RF]: 'de rechter knie',
    [BODYPARTS.Foot_RF]: 'de rechter voet',
    [BODYPARTS.Medial_Thigh_RF]: 'de binnenzijde van het rechter dijbeen',
    [BODYPARTS.Right_Leg_RB]: 'de achterzijde van het rechter been',
    [BODYPARTS.Hamstrings_RB]: 'de rechter hamstrings',
    [BODYPARTS.Foot_RB]: 'de rechter voet',
    [BODYPARTS.Popliteal_Region_RB]: 'de rechter knieholte',
    [BODYPARTS.Calve_RB]: 'de rechter kuit',
    [BODYPARTS.Right_Leg_RM]: 'de binnenzijde van het rechter been',
    [BODYPARTS.Medial_Thigh_RM]: 'de binnenzijde van het rechter dijbeen',
    [BODYPARTS.Medial_Knee_RM]: 'de binnenzijde van de rechter knie',
    [BODYPARTS.Medial_Upper_Leg_RM]: 'de binnenzijde van het rechter bovenbeen',
    [BODYPARTS.Medial_Hamstrings_RM]:
      'de binnenzijde van de rechter hamstrings',
    [BODYPARTS.Knee_RM]: 'de rechter knie',
    [BODYPARTS.Politea_RM]: 'de rechter knieholte',
    [BODYPARTS.Calve_RM]: 'de rechter kuit',
    [BODYPARTS.Medial_Lower_Leg_RM]:
      'de binnenzijde van het rechter scheenbeen',
    [BODYPARTS.Foot_RM]: 'de rechter voet',
    [BODYPARTS.Right_Leg_RL]: 'de buitenzijde van het rechter been',
    [BODYPARTS.Lateral_Thigh_RL]: 'de buitenzijde van het rechter dijbeen',
    [BODYPARTS.Lateral_Knee_RL]: 'de buitenzijde van de rechter knie',
    [BODYPARTS.Lateral_Upper_Leg_RL]:
      'de buitenzijde van het rechter bovenbeen',
    [BODYPARTS.Lateral_Hamstrings_RL]:
      'de buitenzijde van de rechter hamstrings',
    [BODYPARTS.Knee_RL]: 'de rechter knie',
    [BODYPARTS.Politea_RL]: 'de buitenzijde van de rechter knieholte',
    [BODYPARTS.Calve_RL]: 'de buitenzijde van de rechter kuit',
    [BODYPARTS.Lateral_Lower_Leg_RL]:
      'de buitenzijde van het  rechter scheenbeen',
    [BODYPARTS.Foot_RL]: 'de rechter voet',
    [BODYPARTS.Left_Leg_LF]: 'de voorzijde van het linker been',
    [BODYPARTS.Upper_Leg_LF]: 'de voorzijde van het linker bovenbeen',
    [BODYPARTS.Lower_Leg_LF]: 'het linker scheenbeen',
    [BODYPARTS.Knee_LF]: 'de linker knie',
    [BODYPARTS.Foot_LF]: 'de linker voet',
    [BODYPARTS.Medial_Thigh_LF]: 'de binnenzijde van het linker dijbeen',
    [BODYPARTS.Left_Leg_LB]: 'de achterzijde van het linker been',
    [BODYPARTS.Hamstrings_LB]: 'de linker hamstrings',
    [BODYPARTS.Foot_LB]: 'de linker voet',
    [BODYPARTS.Popliteal_Region_LB]: 'de linker knieholte',
    [BODYPARTS.Calve_LB]: 'de linker kuit',
    [BODYPARTS.Left_Leg_LM]: 'de binnenzijde van het linker been',
    [BODYPARTS.Medial_Thigh_LM]: 'de binnenzijde van het linker dijbeen',
    [BODYPARTS.Medial_Knee_LM]: 'de binnenzijde van de linker knie',
    [BODYPARTS.Medial_Upper_Leg_LM]: 'de binnenzijde van het linker bovenbeen',
    [BODYPARTS.Medial_Hamstrings_LM]: 'de binnenzijde van de linker hamstrings',
    [BODYPARTS.Knee_LM]: 'de linker knie',
    [BODYPARTS.Politea_LM]: 'de linker knieholte',
    [BODYPARTS.Calve_LM]: 'de linker kuit',
    [BODYPARTS.Medial_Lower_Leg_LM]: 'de binnenzijde van het linker scheenbeen',
    [BODYPARTS.Foot_LM]: 'de linker voet',
    [BODYPARTS.Left_Leg_LL]: 'de buitenzijde van het linker been',
    [BODYPARTS.Lateral_Thigh_LL]: 'de buitenzijde van het linker dijbeen',
    [BODYPARTS.Lateral_Knee_LL]: 'de buitenzijde van de linker knie',
    [BODYPARTS.Lateral_Upper_Leg_LL]: 'de buitenzijde van het linker bovenbeen',
    [BODYPARTS.Lateral_Hamstrings_LL]:
      'de buitenzijde van de linker hamstrings',
    [BODYPARTS.Knee_LL]: 'de linker knie',
    [BODYPARTS.Politea_LL]: 'de buitenzijde van de linker knieholte',
    [BODYPARTS.Calve_LL]: 'de buitenzijde van de linker kuit',
    [BODYPARTS.Lateral_Lower_Leg_LL]:
      'de buitenzijde van het  linker scheenbeen',
    [BODYPARTS.Foot_LL]: 'de linker voet',
    [BODYPARTS.Right_Foot_RF]: 'de voorzijde van de rechter voet',
    [BODYPARTS.Right_Hallux_1_RF]: 'de rechter grote teen',
    [BODYPARTS.Right_Hallux_2_RF]: 'de rechter tweede teen',
    [BODYPARTS.Right_Hallux_3_RF]: 'de rechter derde teen',
    [BODYPARTS.Right_Hallux_4_RF]: 'de rechter vierde teen',
    [BODYPARTS.Right_Hallux_5_RF]: 'de rechter kleine teen',
    [BODYPARTS.Right_Forefoot_RF]: 'de rechter voorvoet',
    [BODYPARTS.Right_Ankle_RF]: 'de rechter enkel',
    [BODYPARTS.Right_Foot_RB]: 'de achterzijde van de rechter voet',
    [BODYPARTS.Right_Achillesheel_RB]: 'de rechter achilleshiel',
    [BODYPARTS.Right_Lateral_Ankle_RB]: 'de buitenzijde van de rechter enkel',
    [BODYPARTS.Right_Medial_Ankle_RB]: 'de binnenzijde van de rechter enkel',
    [BODYPARTS.Right_Heel_RB]: 'de rechter hiel',
    [BODYPARTS.Right_Sole_RB]: 'de achterzijde van de rechter voetzool',
    [BODYPARTS.Right_Foot_RM]: 'de binnenzijde van de rechter voet',
    [BODYPARTS.Right_Medial_Ankle_RM]: 'de binnenzijde van de rechter enkel',
    [BODYPARTS.Right_Medial_Foot_RM]: 'de binnenzijde van de rechter voet',
    [BODYPARTS.Right_Heel_RM]: 'de rechter hiel',
    [BODYPARTS.Right_Sole_RM]: 'de binnenzijde van de rechter voetzool',
    [BODYPARTS.Right_Forefoot_RM]: 'de binnenzijde van de rechter voorvoet',
    [BODYPARTS.Right_Foot_RL]: 'de buitenzijde van de rechter voet',
    [BODYPARTS.Right_Lateral_Ankle_RL]: 'de buitenzijde van de rechter enkel',
    [BODYPARTS.Right_Lateral_Foot_RL]: 'de buitenzijde van de rechter voet',
    [BODYPARTS.Right_Heel_RL]: 'de rechter hiel',
    [BODYPARTS.Right_Sole_RL]: 'de buitenzijde van de rechter voetzool',
    [BODYPARTS.Right_Forefoot_RL]: 'de buitenzijde van de rechter voorvoet',
    [BODYPARTS.Left_Foot_LF]: 'de voorzijde van de linker voet',
    [BODYPARTS.Left_Hallux_1_LF]: 'de linker grote teen',
    [BODYPARTS.Left_Hallux_2_LF]: 'de linker tweede teen',
    [BODYPARTS.Left_Hallux_3_LF]: 'de linker derde teen',
    [BODYPARTS.Left_Hallux_4_LF]: 'de linker vierde teen',
    [BODYPARTS.Left_Hallux_5_LF]: 'de linker kleine teen',
    [BODYPARTS.Left_Forefoot_LF]: 'de linker voorvoet',
    [BODYPARTS.Left_Front_Ankle_LF]: 'de linker enkel',
    [BODYPARTS.Left_Foot_LB]: 'de achterzijde van de linker voet',
    [BODYPARTS.Left_Achillesheel_LB]: 'de linker achilleshiel',
    [BODYPARTS.Left_Lateral_Ankle_LB]: 'de buitenzijde van de linker enkel',
    [BODYPARTS.Left_Medial_Ankle_LB]: 'de binnenzijde van de linker enkel',
    [BODYPARTS.Left_Heel_LB]: 'de linker hiel',
    [BODYPARTS.Left_Sole_LB]: 'de achterzijde van de linker voetzool',
    [BODYPARTS.Left_Foot_LM]: 'de binnenzijde van de linker voet',
    [BODYPARTS.Left_Medial_Ankle_LM]: 'de binnenzijde van de linker enkel',
    [BODYPARTS.Left_Medial_Foot_LM]: 'de binnenzijde van de linker  voet',
    [BODYPARTS.Left_Heel_LM]: 'de linker hiel',
    [BODYPARTS.Left_Sole_LM]: 'de binnenzijde van de linker voetzool',
    [BODYPARTS.Left_Forefoot_LM]: 'de binnenzijde van de linker voorvoet',
    [BODYPARTS.Left_Foot_LL]: 'de buitenzijde van de linker voet',
    [BODYPARTS.Left_Lateral_Ankle_LL]: 'de buitenzijde van de linker enkel',
    [BODYPARTS.Left_Lateral_Foot_LL]: 'de buitenzijde van de linker voet',
    [BODYPARTS.Left_Heel_LL]: 'de linker hiel',
    [BODYPARTS.Left_Sole_LL]: 'de buitenzijde van de linker voetzool',
    [BODYPARTS.Left_Forefoot_LL]: 'de buitenzijde van de linker voorvoet',
    [BODYPARTS.Left_Hand_LF]: 'de voorzijde van de linker hand',
    [BODYPARTS.Left_Thumb_LF]: 'de voorzijde van de linker duim',
    [BODYPARTS.Left_Index_Finger_LF]: 'de voorzijde van de linker wijsvinger',
    [BODYPARTS.Left_Middle_Finger_LF]:
      'de voorzijde van de linker middelvinger',
    [BODYPARTS.Left_Ring_Finger_LF]: 'de voorzijde van de linker ringvinger',
    [BODYPARTS.Left_Pink_LF]: 'de voorzijde van de linker pink',
    [BODYPARTS.Left_Palm_LF]: 'de linker handpalm',
    [BODYPARTS.Left_Wrist_LF]: 'de voorzijde van de linker pols',
    [BODYPARTS.Left_Hand_LB]: 'de achterzijde van de linker hand',
    [BODYPARTS.Left_Thumb_LB]: 'de achterzijde van de linker duim',
    [BODYPARTS.Left_Index_Finger_LB]: 'de achterzijde van de linker wijsvinger',
    [BODYPARTS.Left_Middle_Finger_LB]:
      'de achterzijde van de linker middelvinger',
    [BODYPARTS.Left_Ring_Finger_LB]: 'de achterzijde van de linker ringvinger',
    [BODYPARTS.Left_Pink_LB]: 'de achterzijde van de linker pink',
    [BODYPARTS.Left_Back_Hand_LB]: 'de linker handrug',
    [BODYPARTS.Left_Wrist_LB]: 'de achterzijde van de linker pols',
    [BODYPARTS.Right_Hand_RF]: 'de voorzijde van de rechter hand',
    [BODYPARTS.Right_Thumb_RF]: 'de voorzijde van de rechter duim',
    [BODYPARTS.Right_Index_Finger_RF]: 'de voorzijde van de rechter wijsvinger',
    [BODYPARTS.Right_Middle_Finger_RF]:
      'de voorzijde van de rechter middelvinger',
    [BODYPARTS.Right_Ring_Finger_RF]: 'de voorzijde van de rechter ringvinger',
    [BODYPARTS.Right_Pink_RF]: 'de voorzijde van de rechter pink',
    [BODYPARTS.Right_Palm_RF]: 'de rechter handpalm',
    [BODYPARTS.Right_Wrist_RF]: 'de voorzijde van de rechter pols',
    [BODYPARTS.Right_Hand_RB]: 'de achterzijde van de rechter hand',
    [BODYPARTS.Right_Thumb_RB]: 'de achterzijde van de rechter duim',
    [BODYPARTS.Right_Index_Finger_RB]:
      'de achterzijde van de rechter wijsvinger',
    [BODYPARTS.Right_Middle_Finger_RB]:
      'de achterzijde van de rechter middelvinger',
    [BODYPARTS.Right_Ring_Finger_RB]:
      'de achterzijde van de rechter ringvinger',
    [BODYPARTS.Right_Pink_RB]: 'de achterzijde van de rechter pink',
    [BODYPARTS.Right_Back_Hand_RB]: 'de rechter handrug',
    [BODYPARTS.Right_Wrist_RB]: 'de achterzijde van de rechter pols',
    [BODYPARTS.Head_F]: 'de voorzijde van het hoofd',
    [BODYPARTS.Head_Top_F]: 'de bovenzijde van het hoofd',
    [BODYPARTS.Throat_F]: 'de keel',
    [BODYPARTS.Chin_F]: 'de kin',
    [BODYPARTS.Neck_Left_F]: 'de linkerzijde van de hals',
    [BODYPARTS.Neck_Right_F]: 'de rechterzijde van de hals',
    [BODYPARTS.Jaw_Left_F]: 'de linkerzijde van de kaak',
    [BODYPARTS.Jaw_Right_F]: 'de rechterzijde van de kaak',
    [BODYPARTS.Eye_Left_F]: 'het linker oog',
    [BODYPARTS.Eye_Right_F]: 'het rechter oog',
    [BODYPARTS.Ear_Left_F]: 'het linker oor',
    [BODYPARTS.Ear_Right_F]: 'het rechter oor',
    [BODYPARTS.Cheek_Left_F]: 'de linker wang',
    [BODYPARTS.Cheek_Right_F]: 'de rechter wang',
    [BODYPARTS.Mouth_F]: 'de mond',
    [BODYPARTS.Nose_F]: 'de neus',
    [BODYPARTS.Lower_Neck_Left_F]: 'de linkerzijde van de hals onder',
    [BODYPARTS.Lower_Neck_Right_F]: 'de rechterzijde van de  hals onder',
    [BODYPARTS.Forehead_F]: 'het voorhoofd',
    [BODYPARTS.Head_B]: 'de achterzijde van het hoofd',
    [BODYPARTS.Backside_Head_B]: 'het achterhoofd',
    [BODYPARTS.Head_Top_B]: 'de bovenzijde van het hoofd',
    [BODYPARTS.Ear_Left_B]: 'het linker oor',
    [BODYPARTS.Ear_Right_B]: 'het rechter oor',
    [BODYPARTS.Neck_Left_B]: 'de linkerzijde van de nek',
    [BODYPARTS.Neck_Right_B]: 'de rechterzijde van de nek',
    [BODYPARTS.Cervical_neck_B]: 'de nekwervels',
    [BODYPARTS.Lower_Neck_Left_B]: 'de linkerzijde van de nek onder',
    [BODYPARTS.Lower_Neck_Right_B]: 'de rechterzijde van de nek onder',
    [BODYPARTS.Head_L]: 'de linkerzijde van het hoofd',
    [BODYPARTS.Mastoid_L]: 'het gebied achter het linker oor',
    [BODYPARTS.Backside_Head_L]: 'het achterhoofd links',
    [BODYPARTS.Head_Top_L]: 'de bovenzijde van het hoofd links',
    [BODYPARTS.Chin_L]: 'de kin',
    [BODYPARTS.Neck_Frontside_L]: 'de linker hals',
    [BODYPARTS.Jaw_L]: 'de linker kaak',
    [BODYPARTS.Eye_L]: 'het linker oog',
    [BODYPARTS.Ear_L]: 'het linker oor',
    [BODYPARTS.Temporal_L]: 'de linker slaap',
    [BODYPARTS.Cheek_L]: 'de linker wang',
    [BODYPARTS.Mouth_L]: 'de mond',
    [BODYPARTS.Neck_Backside_L]: 'de linkerzijde van de nek',
    [BODYPARTS.Nose_L]: 'de neus',
    [BODYPARTS.Lower_Neck_L]: 'de linkerzijde van de nek onder',
    [BODYPARTS.Throat_L]: 'de keel links',
    [BODYPARTS.Preauricular_L]: 'het gebied vóór het linker oor',
    [BODYPARTS.Forehead_L]: 'het voorhoofd links',
    [BODYPARTS.Head_R]: 'de rechterzijde van het hoofd',
    [BODYPARTS.Mastoid_R]: 'het gebied achter het rechter oor',
    [BODYPARTS.Backside_Head_R]: 'het achterhoofd rechts',
    [BODYPARTS.Head_Top_R]: 'de bovenzijde van het hoofd rechts',
    [BODYPARTS.Chin_R]: 'de kin',
    [BODYPARTS.Neck_Frontside_R]: 'de rechter hals',
    [BODYPARTS.Jaw_R]: 'de rechter kaak',
    [BODYPARTS.Eye_R]: 'het rechter oog',
    [BODYPARTS.Ear_R]: 'het rechter oor',
    [BODYPARTS.Temporal_R]: 'de rechter slaap',
    [BODYPARTS.Cheek_R]: 'de rechter wang',
    [BODYPARTS.Mouth_R]: 'de mond',
    [BODYPARTS.Neck_Backside_R]: 'de rechterzijde van de nek',
    [BODYPARTS.Nose_R]: 'de neus',
    [BODYPARTS.Lower_Neck_R]: 'de rechterzijde van de nek onder',
    [BODYPARTS.Throat_R]: 'de keel',
    [BODYPARTS.Preauricular_R]: 'het gebied vóór het rechter oor',
    [BODYPARTS.Forehead_R]: 'het voorhoofd rechts',
    [BODYPARTS.Upper_Back_B]: 'de bovenrug',
    [BODYPARTS.Upper_Left_Flank_B]: 'de linker hoge flank',
    [BODYPARTS.Upper_Left_Paravertebral_B]: 'de hoge middenrug links',
    [BODYPARTS.Back_Left_Shoulder_B]: 'de achterzijde van de linker schouder',
    [BODYPARTS.Back_Left_Ribcage_B]: 'de achterzijde van de linker ribbenkas',
    [BODYPARTS.Left_Shoulder_Blade_B]: 'het linker schouderblad',
    [BODYPARTS.Mid_Left_Paravertebral_B]: 'de middenrug links',
    [BODYPARTS.Upper_Right_Flank_B]: 'de rechter hoge flank',
    [BODYPARTS.Upper_Right_Paravertebral_B]: 'de hoge middenrug rechts',
    [BODYPARTS.Back_Right_Shoulder_B]: 'de achterzijde van de rechter schouder',
    [BODYPARTS.Back_Right_Ribcage_B]: 'de achterzijde van de rechter ribbenkas',
    [BODYPARTS.Right_Shoulder_Blade_B]: 'het rechter schouderblad',
    [BODYPARTS.Mid_Right_Paravertebral_B]: 'de middenrug rechts',
    [BODYPARTS.Upper_Spinal_B]: 'de hoge wervels',
    [BODYPARTS.Mid_Spinal_B]: 'de midden wervels',
    [BODYPARTS.Torso_F]: 'de borstkas',
    [BODYPARTS.Sternum_F]: 'het borstbeen',
    [BODYPARTS.Left_Lower_Chest_F]: 'de onderzijde van de linker borst',
    [BODYPARTS.Left_Upper_Chest_F]: 'de bovenzijde van de linker borst',
    [BODYPARTS.Left_Front_Shoulder_F]: 'de voorzijde van de linker schouder',
    [BODYPARTS.Left_Clavicle_F]: 'het linker sleutelbeen',
    [BODYPARTS.Right_Lower_Chest_F]: 'de onderzijde van de rechter borst',
    [BODYPARTS.Right_Upper_Chest_F]: 'de bovenzijde van de rechter borst',
    [BODYPARTS.Right_Front_Shoulder_F]: 'de voorzijde van de rechter schouder',
    [BODYPARTS.Right_Clavicle_F]: 'het rechter sleutelbeen',
    [BODYPARTS.Torso_R]: 'de rechterzijde van de borstkas',
    [BODYPARTS.Right_Upper_Chest_R]: 'de bovenzijde van de rechter borst',
    [BODYPARTS.Upper_Right_Flank_R]: 'de rechter hoge flank',
    [BODYPARTS.Right_Shoulder_Blade_R]: 'het rechter schouderblad',
    [BODYPARTS.Right_Lower_Chest_R]: 'de onderzijde van de rechter borst',
    [BODYPARTS.Front_Right_Ribcage_R]: 'de voorzijde van de rechter ribbenkas ',
    [BODYPARTS.Torso_L]: 'de linkerzijde van de borstkas',
    [BODYPARTS.Left_Upper_Chest_L]: 'de bovenzijde van de linker borst',
    [BODYPARTS.Upper_Left_Flank_L]: 'de linker hoge flank',
    [BODYPARTS.Left_Shoulder_Blade_L]: 'het linker schouderblad',
    [BODYPARTS.Left_Lower_Chest_L]: 'de onderzijde van de linker borst',
    [BODYPARTS.Front_Left_Ribcage_L]: 'de voorzijde van de linker ribbenkas ',
    [BODYPARTS.Abdomen_F]: 'de buik',
    [BODYPARTS.Epigastric_F]: 'de bovenbuik of maag',
    [BODYPARTS.Left_Upper_Abdomen_F]: 'de linker bovenbuik',
    [BODYPARTS.Right_Upper_Abdomen_F]: 'de rechter bovenbuik',
    [BODYPARTS.Left_Lower_Abdomen_F]: 'de linker onderbuik',
    [BODYPARTS.Right_Lower_Abdomen_F]: 'de rechter onderbuik',
    [BODYPARTS.Umbilical_region_F]: 'de middenbuik',
    [BODYPARTS.Right_Middle_Abdomen_F]: 'de rechterzijde van de buik',
    [BODYPARTS.Left_Middle_Abdomen_F]: 'de linkerzijde van de buik',
    [BODYPARTS.Lumbal_region_B]: 'de onderrug',
    [BODYPARTS.Lumbal_Paravertebral_Left_B]: 'de lage middenrug links',
    [BODYPARTS.Lumbal_Paravertebral_Right_B]: 'de lage middenrug rechts',
    [BODYPARTS.Left_SI_Joint_B]: 'het rugkuiltje links',
    [BODYPARTS.Right_SI_Joint_B]: 'het rugkuiltje rechts',
    [BODYPARTS.Left_Lowerback_B]: 'de linkerzijde van de onderrug',
    [BODYPARTS.Right_Lowerback_B]: 'de rechterzijde van de  onderrug',
    [BODYPARTS.Left_Flank_B]: 'de linker flank',
    [BODYPARTS.Right_Flank_B]: 'de rechter flank',
    [BODYPARTS.Lumbal_spine_B]: 'de lage rugwervels',
    [BODYPARTS.Abdomen_L]: 'de linker buik',
    [BODYPARTS.Left_Upper_Flank_L]: 'de linker bovenzij',
    [BODYPARTS.Left_Middle_Abdomen_L]: 'de linkerzijde van de buik',
    [BODYPARTS.Left_Lower_Flank_L]: 'de linker zij',
    [BODYPARTS.Abdomen_R]: 'de rechter buik',
    [BODYPARTS.Right_Upper_Flank_R]: 'de rechter bovenzij',
    [BODYPARTS.Right_Middle_Abdomen_R]: 'de rechterzijde van de buik',
    [BODYPARTS.Right_Lower_Flank_R]: 'de rechter zij',
    [BODYPARTS.Hip_Region_F]: 'de voorzijde van het heupgebied',
    [BODYPARTS.Left_Hipbone_F]: 'de linker heuprand',
    [BODYPARTS.Right_Hipbone_F]: 'de rechter heuprand',
    [BODYPARTS.Lower_Abdominal_F]: 'de onderbuik',
    [BODYPARTS.Left_Inguinals_F]: 'de linker lies',
    [BODYPARTS.Right_Inguinals_F]: 'de rechter lies',
    [BODYPARTS.Pubic_region_F]: 'de schaamstreek',
    [BODYPARTS.Hip_Region_B]: 'de achterzijde van het heupgebied',
    [BODYPARTS.Left_Gluteal_B]: 'de linker bil',
    [BODYPARTS.Right_Gluteal_B]: 'de rechter bil',
    [BODYPARTS.Perianal_region_B]: 'het anale gebied',
    [BODYPARTS.Sacral_region_B]: 'de stuit',
    [BODYPARTS.Hip_Region_L]: 'de linkerzijde van het heupgebied',
    [BODYPARTS.Left_Gluteal_BL]: 'de linker bil',
    [BODYPARTS.Hip_Region_R]: 'de rechterzijde van het heupgebied',
    [BODYPARTS.Right_Gluteal_BR]: 'de rechter bil',
  },
  ar: {
    [BODYPARTS.Body_F]: '(الجسم(أمام',
    [BODYPARTS.Body_B]: '(الجسم(خلف',
    [BODYPARTS.Body_L]: '(الجسم(يسار',
    [BODYPARTS.Body_R]: '(الجسم(يمين',
    [BODYPARTS.Right_Arm_RF]: 'الجانب الأمامي للذراع اليمين',
    [BODYPARTS.Medial_Elbow_RF]: 'الجانب الداخلي للكوع الأيمن',
    [BODYPARTS.Lateral_Elbow_RF]: 'الجانب الخارجي للكوع الأيمن',
    [BODYPARTS.Biceps_RF]: 'الجانب الأمامي أعلى الذراع الأيمن',
    [BODYPARTS.Hand_RF]: 'اليّد اليمنى',
    [BODYPARTS.Ventral_Lower_Arm_RF]: 'الجانب الأمامي أسفل الذراع الأيمن',
    [BODYPARTS.Right_Arm_RB]: 'الجانب الخلفي للذراع الأيمن',
    [BODYPARTS.Olecranon_RB]: 'الكوع الأيمن',
    [BODYPARTS.Medial_Elbow_RB]: 'الجانب الداخلي للكوع الأيمن',
    [BODYPARTS.Lateral_Elbow_RB]: 'الجانب الخارجي للكوع الأيمن',
    [BODYPARTS.Triceps_RB]: 'الجانب الخلفي أعلى الذراع الأيمن',
    [BODYPARTS.Hand_RB]: 'اليّد اليمنى',
    [BODYPARTS.Dorsal_Lower_Arm_RB]: 'الجانب الخلفي أسفل الذراع الأيمن',
    [BODYPARTS.Left_Arm_LF]: 'الجانب الأمامي للذراع الأيسر',
    [BODYPARTS.Medial_Elbow_LF]: 'الجانب الداخلي للكوع الأيسر',
    [BODYPARTS.Lateral_Elbow_LF]: 'الجانب الخارجي للكوع الأيسر',
    [BODYPARTS.Biceps_LF]: 'الجانب الأمامي أعلى الذراع الأيسر',
    [BODYPARTS.Hand_LF]: 'أليّد اليسرى',
    [BODYPARTS.Ventral_Lower_Arm_LF]: 'الجانب الأمامي أسفل الذراع الايسر',
    [BODYPARTS.Left_Arm_LB]: 'الجانب الخلفي لليد اليسرى',
    [BODYPARTS.Olecranon_LB]: 'الكوع الأيسر',
    [BODYPARTS.Medial_Elbow_LB]: 'الجانب الداخلي للكوع الأيسر',
    [BODYPARTS.Lateral_Elbow_LB]: 'الجانب الخارجي للكوع الأيسر',
    [BODYPARTS.Triceps_LB]: 'الجانب الخلفي أعلى الذراع الأيسر',
    [BODYPARTS.Hand_LB]: 'اليّد اليسرى',
    [BODYPARTS.Dorsal_Lower_Arm_LB]: 'الجانب الخلفي أسفل الذراع الأيسر',
    [BODYPARTS.Right_Leg_RF]: 'الجانب الأمامي للساق اليمنى',
    [BODYPARTS.Upper_Leg_RF]: 'الجانب الأمامي أعلى الساق اليمنى',
    [BODYPARTS.Lower_Leg_RF]: 'قصبة الساق اليمنى',
    [BODYPARTS.Knee_RF]: 'الركبة اليمنى',
    [BODYPARTS.Foot_RF]: 'الجانب الأمامي للقدم الأيمن',
    [BODYPARTS.Medial_Thigh_RF]: 'الجانب الداخلي لعظمة الفخذ اليمنى',
    [BODYPARTS.Right_Leg_RB]: 'الجانب الخلفي للساق اليمنى',
    [BODYPARTS.Hamstrings_RB]: 'أوتار الركبة اليمنى',
    [BODYPARTS.Foot_RB]: 'الجانب الخلفي للقدم الأيمن',
    [BODYPARTS.Popliteal_Region_RB]: 'تجويف الركبة اليمنى',
    [BODYPARTS.Calve_RB]: 'عضلة الساق اليمنى',
    [BODYPARTS.Right_Leg_RM]: 'الجانب الداخلي للساق اليمنى',
    [BODYPARTS.Medial_Thigh_RM]: 'الجانب الداخلي لعظمة الفخذ اليمنى',
    [BODYPARTS.Medial_Knee_RM]: 'الجانب الداخلي للركبة اليمنى',
    [BODYPARTS.Medial_Upper_Leg_RM]: 'الجانب الداخلي أعلى الساق الأيمن',
    [BODYPARTS.Medial_Hamstrings_RM]: 'الجانب الداخلي لأوتار الركبة اليمنى',
    [BODYPARTS.Knee_RM]: 'الركبة اليمنى',
    [BODYPARTS.Politea_RM]: 'تجويف الركبة اليمنى',
    [BODYPARTS.Calve_RM]: 'عضلة الساق اليمنى',
    [BODYPARTS.Medial_Lower_Leg_RM]: 'الجانب الداخلي لقصبة الساق اليمنى',
    [BODYPARTS.Foot_RM]: 'القدم الأيمن',
    [BODYPARTS.Right_Leg_RL]: 'الجانب الخارجي للساق اليمنى',
    [BODYPARTS.Lateral_Thigh_RL]: 'الجانب الخارجي لعظمة الفخذ اليمنى',
    [BODYPARTS.Lateral_Knee_RL]: 'الجانب الخارجي للركبة اليمنى',
    [BODYPARTS.Lateral_Upper_Leg_RL]: 'الجانب الخارجي أعلى الساق اليمنى',
    [BODYPARTS.Lateral_Hamstrings_RL]: 'الجانب الخارجي لإوتار الركبة اليمنى',
    [BODYPARTS.Knee_RL]: 'الركبة اليمنى',
    [BODYPARTS.Politea_RL]: 'الجانب الخارجي لتجويف الركبة الأيمن',
    [BODYPARTS.Calve_RL]: 'الجانب الخارجي لعضلة الساق اليمنى',
    [BODYPARTS.Lateral_Lower_Leg_RL]: 'الجانب الخارجي لقصبة الساق اليمنى',
    [BODYPARTS.Foot_RL]: 'القدم الأيمن',
    [BODYPARTS.Left_Leg_LF]: 'الجانب الأمامي للساق اليسرى',
    [BODYPARTS.Upper_Leg_LF]: 'الجانب الأمامي أعلى الساق اليسرى',
    [BODYPARTS.Lower_Leg_LF]: 'قصبة الساق اليسرى',
    [BODYPARTS.Knee_LF]: 'الجانب الأمامي للركبة اليسرى',
    [BODYPARTS.Foot_LF]: 'الجانب الأمامي للقدم اليسرى',
    [BODYPARTS.Medial_Thigh_LF]: 'الجانب الداخلي لعظمة الفخذ اليسرى',
    [BODYPARTS.Left_Leg_LB]: 'الجانب الخلفي للساق اليسرى',
    [BODYPARTS.Hamstrings_LB]: 'أوتار الركبة اليسرى',
    [BODYPARTS.Foot_LB]: ' الجانب الخلفي للقدم اليسرى',
    [BODYPARTS.Popliteal_Region_LB]: 'تجويف الركبة اليسرى',
    [BODYPARTS.Calve_LB]: ' الجانب الخلفي لعضلة الساق اليسرى',
    [BODYPARTS.Left_Leg_LM]: 'الجانب الداخلي للساق اليسرى',
    [BODYPARTS.Medial_Thigh_LM]: 'الجانب الداخلي لعظمة الفخذ اليسرى',
    [BODYPARTS.Medial_Knee_LM]: 'الجانب الداخلي للركبة اليسرى',
    [BODYPARTS.Medial_Upper_Leg_LM]: 'الجانب الداخلي أعلى الساق اليسرى',
    [BODYPARTS.Medial_Hamstrings_LM]: 'الجانب الداخلي لأوتار الركبة اليسرى',
    [BODYPARTS.Knee_LM]: 'الركبة اليسرى',
    [BODYPARTS.Politea_LM]: 'تجويف الركبة اليسرى',
    [BODYPARTS.Calve_LM]: 'عضلة الساق اليسرى',
    [BODYPARTS.Medial_Lower_Leg_LM]: 'الجانب الداخلي لقصبة الساق اليسرى',
    [BODYPARTS.Foot_LM]: 'القدم اليسرى',
    [BODYPARTS.Left_Leg_LL]: 'الجانب الخارجي للساق اليسرى',
    [BODYPARTS.Lateral_Thigh_LL]: 'الجانب الخارجي لعظمة الفخذ اليسرى',
    [BODYPARTS.Lateral_Knee_LL]: 'الجانب الخارجي للركبة اليسرى',
    [BODYPARTS.Lateral_Upper_Leg_LL]: 'الجانب الخارجي أعلى  الساق اليسرى',
    [BODYPARTS.Lateral_Hamstrings_LL]: 'الجانب الخارجي لأوتار الركبة اليسرى',
    [BODYPARTS.Knee_LL]: 'الجانب الخارجي للركبة اليسرى',
    [BODYPARTS.Politea_LL]: 'الجانب الخارجي لتجويف الركبة اليسرى',
    [BODYPARTS.Calve_LL]: 'الجانب الخارجي لعضلة الساق اليسرى',
    [BODYPARTS.Lateral_Lower_Leg_LL]: 'الجانب الخارجي لقصبة الساق اليسرى',
    [BODYPARTS.Foot_LL]: 'القدم اليسرى',
    [BODYPARTS.Right_Foot_RF]: 'الجانب الأمامي للقدم اليمنى',
    [BODYPARTS.Right_Hallux_1_RF]: 'أُصبع القدم الأيمن الكبير',
    [BODYPARTS.Right_Hallux_2_RF]: 'أُصبع القدم الأيمن الثاني',
    [BODYPARTS.Right_Hallux_3_RF]: 'أصبع القدم الأيمن الثالث',
    [BODYPARTS.Right_Hallux_4_RF]: 'أصبع القدم الأيمن الرابع',
    [BODYPARTS.Right_Hallux_5_RF]: 'أصبع القدم الأيمن الصغير',
    [BODYPARTS.Right_Forefoot_RF]: 'الجانب الأمامي للقدم اليمنى',
    [BODYPARTS.Right_Ankle_RF]: 'الجانب الأمامي للكاحل الأيمن',
    [BODYPARTS.Right_Foot_RB]: 'الجانب الخلفي للقدم الأيمن',
    [BODYPARTS.Right_Achillesheel_RB]: 'كعب أخيل الأيمن',
    [BODYPARTS.Right_Lateral_Ankle_RB]: 'الجانب الخارجي للكاحل الأيمن',
    [BODYPARTS.Right_Medial_Ankle_RB]: 'الجانب الداخلي للكاحل الأيمن',
    [BODYPARTS.Right_Heel_RB]: 'الكعب الأيمن',
    [BODYPARTS.Right_Sole_RB]: 'الجانب الخلفي لأسفل القدم الأيمن',
    [BODYPARTS.Right_Foot_RM]: 'الجانب الداخلي للقدم الأيمن',
    [BODYPARTS.Right_Medial_Ankle_RM]: 'الجانب الداخلي الأيمن للكاحل',
    [BODYPARTS.Right_Medial_Foot_RM]: 'الجانب الداخلي للقدم الأيمن',
    [BODYPARTS.Right_Heel_RM]: 'الكعب الأيمن',
    [BODYPARTS.Right_Sole_RM]: 'الجانب الداخلي لأسفل القدم الأيمن',
    [BODYPARTS.Right_Forefoot_RM]: 'الجانب الداخلي لمقدمة القدم الأيمن',
    [BODYPARTS.Right_Foot_RL]: 'الجانب الخارجي للقدم الأيمن',
    [BODYPARTS.Right_Lateral_Ankle_RL]: 'الجانب الخارجي للكاحل الأيمن',
    [BODYPARTS.Right_Lateral_Foot_RL]: 'الجانب الخارجي للقدم الأيمن',
    [BODYPARTS.Right_Heel_RL]: 'الكعب الأيمن',
    [BODYPARTS.Right_Sole_RL]: 'الجانب الخارجي لأسفل القدم (أخمص )الأيمن',
    [BODYPARTS.Right_Forefoot_RL]: 'الجانب الخارجي لمقدمة القدم الأيمن',
    [BODYPARTS.Left_Foot_LF]: 'الجانب الأمامي للقدم الأيسر',
    [BODYPARTS.Left_Hallux_1_LF]: 'أصبع القدم الأيسر الكبير',
    [BODYPARTS.Left_Hallux_2_LF]: 'أصبع القدم الأيسر الثاني',
    [BODYPARTS.Left_Hallux_3_LF]: 'أصبع القدم الأيسر الثالث',
    [BODYPARTS.Left_Hallux_4_LF]: 'أصبع القدم الأيسر الرابع',
    [BODYPARTS.Left_Hallux_5_LF]: 'أصبع القدم الأيسر الصغير',
    [BODYPARTS.Left_Forefoot_LF]: 'الجانب الأمامي للقدم الأيسر',
    [BODYPARTS.Left_Front_Ankle_LF]: 'الجانب الأمامي للكاحل الأيسر',
    [BODYPARTS.Left_Foot_LB]: 'الجانب الخلفي للقدم الأيسر',
    [BODYPARTS.Left_Achillesheel_LB]: 'كعب أخيل الأيسر',
    [BODYPARTS.Left_Lateral_Ankle_LB]: 'الجانب الخارجي للكاحل الأيسر',
    [BODYPARTS.Left_Medial_Ankle_LB]: 'الجانب الداخلي للكاحل الأيسر',
    [BODYPARTS.Left_Heel_LB]: 'الكعب الأيسر',
    [BODYPARTS.Left_Sole_LB]: 'الجانب الخلفي لأسفل القدم (أخمص )الأيسر',
    [BODYPARTS.Left_Foot_LM]: 'الجانب الداخلي للقدم الأيسر',
    [BODYPARTS.Left_Medial_Ankle_LM]: 'الجانب الداخلي للكاحل الأيسر',
    [BODYPARTS.Left_Medial_Foot_LM]: 'الجانب الداخلي للقدم الأيسر',
    [BODYPARTS.Left_Heel_LM]: 'الكعب الأيسر',
    [BODYPARTS.Left_Sole_LM]: 'الجانب الداخلي لأسفل القدم (أخمص ) الأيسر',
    [BODYPARTS.Left_Forefoot_LM]: 'الجانب الداخلي لمقدمة القدم الأيسر',
    [BODYPARTS.Left_Foot_LL]: 'الجانب الخارجي للقدم الأيسر',
    [BODYPARTS.Left_Lateral_Ankle_LL]: 'الجانب الخارجي للكاحل الأيسر',
    [BODYPARTS.Left_Lateral_Foot_LL]: 'الجانب الخارجي للقدم الأيسر',
    [BODYPARTS.Left_Heel_LL]: 'الكعب الأيسر',
    [BODYPARTS.Left_Sole_LL]: 'الجانب الخارجي لأسفل القدم (أخمص )الأيسر',
    [BODYPARTS.Left_Forefoot_LL]: 'الجانب الخارجي لمقدمة القدم الأيسر',
    [BODYPARTS.Left_Hand_LF]: 'الجانب الأمامي لليد اليسرى',
    [BODYPARTS.Left_Thumb_LF]: 'الجانب الأمامي لأصبع الإبهام الأيسر',
    [BODYPARTS.Left_Index_Finger_LF]: 'الجانب الأمامي لأصبع السبابة الأيسر',
    [BODYPARTS.Left_Middle_Finger_LF]: 'الجانب الأمامي للأصبع الوسطى الأيسر',
    [BODYPARTS.Left_Ring_Finger_LF]: 'الجانب الأمامي لأصبع البنصر الأيسر',
    [BODYPARTS.Left_Pink_LF]: 'الجانب الأمامي لأصبع الخنصر الأيسر',
    [BODYPARTS.Left_Palm_LF]: 'الجانب الأمامي للكف الأيسر',
    [BODYPARTS.Left_Wrist_LF]: 'الجانب الأمامي للرسغ الأيسر',
    [BODYPARTS.Left_Hand_LB]: 'الجانب الخلفي لليد اليسرى',
    [BODYPARTS.Left_Thumb_LB]: 'الجانب الخلفي لأصبع الإبهام الأيسر',
    [BODYPARTS.Left_Index_Finger_LB]: 'الجانب الخلفي لإصبع السبابة الأيسر',
    [BODYPARTS.Left_Middle_Finger_LB]: 'الجانب الخلفي للأصبع الوسطى الأيسر',
    [BODYPARTS.Left_Ring_Finger_LB]: 'الجانب الخلفي الأصبع البنصر الأيسر',
    [BODYPARTS.Left_Pink_LB]: 'الجانب الخلفي لأصبع الخنصر الأيسر',
    [BODYPARTS.Left_Back_Hand_LB]: 'ظهر اليد اليسرى',
    [BODYPARTS.Left_Wrist_LB]: 'الجانب الخلفي للرسغ الأيسر',
    [BODYPARTS.Right_Hand_RF]: 'الجانب الأمامي لليد اليمنى',
    [BODYPARTS.Right_Thumb_RF]: 'الجانب الأمامي لأصبع الإبهام الأيمن',
    [BODYPARTS.Right_Index_Finger_RF]: 'الجانب الأمامي لأصبع السبابة الأيمن',
    [BODYPARTS.Right_Middle_Finger_RF]: 'الجانب الأمامي للأصبع الأوسط الأيمن',
    [BODYPARTS.Right_Ring_Finger_RF]: 'الجانب الأمامي لأصبع البنصر الأيمن',
    [BODYPARTS.Right_Pink_RF]: 'الجانب الأمامي لأصبع الخنصر الأيمن',
    [BODYPARTS.Right_Palm_RF]: 'الجانب الأمامي  لكف اليد اليمنى',
    [BODYPARTS.Right_Wrist_RF]: 'الجانب الأمامي للرسغ الأيمن',
    [BODYPARTS.Right_Hand_RB]: 'الجانب الخلفي لليد اليمنى',
    [BODYPARTS.Right_Thumb_RB]: 'الجانب الخلفي لأصبع الإبهام الأيمن',
    [BODYPARTS.Right_Index_Finger_RB]: 'الجانب الخلفي لأصبع السبابة الأيمن',
    [BODYPARTS.Right_Middle_Finger_RB]: 'الجانب الخلفي للأصبع الوسطى الأيمن',
    [BODYPARTS.Right_Ring_Finger_RB]: 'الجانب الخلفي لأصبع البنصر الأيمن',
    [BODYPARTS.Right_Pink_RB]: 'الجانب الخلفي لأصبع الخنصر الأيمن',
    [BODYPARTS.Right_Back_Hand_RB]: 'ظهر اليد اليمنى',
    [BODYPARTS.Right_Wrist_RB]: 'الجانب الخلفي للرسغ الأيمن',
    [BODYPARTS.Head_F]: 'الجانب الأمامي للرأس',
    [BODYPARTS.Head_Top_F]: 'الجانب العلوي للرأس',
    [BODYPARTS.Throat_F]: 'الحلق',
    [BODYPARTS.Chin_F]: 'الذقن',
    [BODYPARTS.Neck_Left_F]: 'يسار الرقبة',
    [BODYPARTS.Neck_Right_F]: 'يمين الرقبة',
    [BODYPARTS.Jaw_Left_F]: 'الفك اليسار',
    [BODYPARTS.Jaw_Right_F]: 'الفك اليمين',
    [BODYPARTS.Eye_Left_F]: 'العين اليسرى',
    [BODYPARTS.Eye_Right_F]: 'العين اليمنى',
    [BODYPARTS.Ear_Left_F]: 'الأذن اليسرى',
    [BODYPARTS.Ear_Right_F]: 'الأذن اليمني',
    [BODYPARTS.Cheek_Left_F]: 'الخد الأيسر',
    [BODYPARTS.Cheek_Right_F]: 'الخد الأيمن',
    [BODYPARTS.Mouth_F]: 'الفم',
    [BODYPARTS.Nose_F]: 'الأنف',
    [BODYPARTS.Lower_Neck_Left_F]: 'أسفل يسار الرقبة',
    [BODYPARTS.Lower_Neck_Right_F]: 'أسفل يمين الرقبة',
    [BODYPARTS.Forehead_F]: 'الجبين',
    [BODYPARTS.Head_B]: 'الجانب الخلفي للرأس',
    [BODYPARTS.Backside_Head_B]: 'خلف الرأس',
    [BODYPARTS.Head_Top_B]: 'الجانب الخلفي لأعلى للرأس',
    [BODYPARTS.Ear_Left_B]: 'الأذن اليسار',
    [BODYPARTS.Ear_Right_B]: 'الأذن اليمنى',
    [BODYPARTS.Neck_Left_B]: 'الجانب الأيسر لخلف للرقبة',
    [BODYPARTS.Neck_Right_B]: 'الجانب الأيمن لخلف للرقبة',
    [BODYPARTS.Cervical_neck_B]: 'فقرات الرقبة',
    [BODYPARTS.Lower_Neck_Left_B]: 'أسفل خلف الرقبة الأيسر',
    [BODYPARTS.Lower_Neck_Right_B]: 'أسفل خلف الرقبة الأيمن',
    [BODYPARTS.Head_L]: 'الجانب الأيسر للرأس',
    [BODYPARTS.Mastoid_L]: 'خلف الأذن اليسرى',
    [BODYPARTS.Backside_Head_L]: 'خلف الرأس اليسار',
    [BODYPARTS.Head_Top_L]: 'أعلى الرأس اليسار',
    [BODYPARTS.Chin_L]: 'الذقن',
    [BODYPARTS.Neck_Frontside_L]: 'يسار الرقبة الامامي',
    [BODYPARTS.Jaw_L]: 'الفك الأيسر',
    [BODYPARTS.Eye_L]: 'العين اليسرى',
    [BODYPARTS.Ear_L]: 'الأذن اليسرى',
    [BODYPARTS.Temporal_L]: 'الصدغ الأيسر',
    [BODYPARTS.Cheek_L]: 'الخد الأيسر',
    [BODYPARTS.Mouth_L]: 'الفم',
    [BODYPARTS.Neck_Backside_L]: 'يسار الرقبة الخلفي',
    [BODYPARTS.Nose_L]: 'الأنف',
    [BODYPARTS.Lower_Neck_L]: 'أسفل الجانب الأيسر للرقبة',
    [BODYPARTS.Throat_L]: 'يسار الحلق',
    [BODYPARTS.Preauricular_L]: 'خلف الأذن اليسرى',
    [BODYPARTS.Forehead_L]: 'يسار الجبين',
    [BODYPARTS.Head_R]: 'الجانب الأيمن للرأس',
    [BODYPARTS.Mastoid_R]: 'خلف الأذن اليمين',
    [BODYPARTS.Backside_Head_R]: 'خلف الرأس الأيمن',
    [BODYPARTS.Head_Top_R]: 'أعلى الرأس الأيمن',
    [BODYPARTS.Chin_R]: 'الذقن',
    [BODYPARTS.Neck_Frontside_R]: 'يمين الرقبة الامامي',
    [BODYPARTS.Jaw_R]: 'الفك الأيمن',
    [BODYPARTS.Eye_R]: 'العين اليمنى',
    [BODYPARTS.Ear_R]: 'الأذن اليمنى',
    [BODYPARTS.Temporal_R]: 'الصدغ الأيمن',
    [BODYPARTS.Cheek_R]: 'الخد الأيمن',
    [BODYPARTS.Mouth_R]: 'الفم',
    [BODYPARTS.Neck_Backside_R]: 'يمين الرقبةالخلفي',
    [BODYPARTS.Nose_R]: 'الأنف',
    [BODYPARTS.Lower_Neck_R]: 'أسفل يمين الرقبة',
    [BODYPARTS.Throat_R]: 'الحلق',
    [BODYPARTS.Preauricular_R]: 'خلف الأذن اليمني',
    [BODYPARTS.Forehead_R]: 'أيمن الجبين',
    [BODYPARTS.Upper_Back_B]: 'أعلى الظهر',
    [BODYPARTS.Upper_Left_Flank_B]: 'الخاصرة العليا اليسرى',
    [BODYPARTS.Upper_Left_Paravertebral_B]: 'وسط الظهر العلوي الأيسر',
    [BODYPARTS.Back_Left_Shoulder_B]: 'الجانب الخلفي الأيسر للكتف',
    [BODYPARTS.Back_Left_Ribcage_B]: 'الجانب الخلفي الأيسر للقفص الصدري',
    [BODYPARTS.Left_Shoulder_Blade_B]: 'عظمة الكتف اليسرى',
    [BODYPARTS.Mid_Left_Paravertebral_B]: 'وسط الظهر الأيسر',
    [BODYPARTS.Upper_Right_Flank_B]: 'الخاصرة العليا اليمنى',
    [BODYPARTS.Upper_Right_Paravertebral_B]: 'وسط الظهر العلوي الأيمن',
    [BODYPARTS.Back_Right_Shoulder_B]: 'الجانب الخلفي الأيمن للكتف',
    [BODYPARTS.Back_Right_Ribcage_B]: 'الجانب الخلفي الأيمن للقفص الصدري',
    [BODYPARTS.Right_Shoulder_Blade_B]: 'عظمة الكتف اليمنى',
    [BODYPARTS.Mid_Right_Paravertebral_B]: 'وسط الظهر الأيمن',
    [BODYPARTS.Upper_Spinal_B]: 'الفقرات العليا',
    [BODYPARTS.Mid_Spinal_B]: 'الفقرات الوسطى',
    [BODYPARTS.Torso_F]: 'الصدر',
    [BODYPARTS.Sternum_F]: 'عظمة الصدر',
    [BODYPARTS.Left_Lower_Chest_F]: 'الجانب السفلي الأيسر للصدر',
    [BODYPARTS.Left_Upper_Chest_F]: 'الجانب العلوي الأيسر للصدر',
    [BODYPARTS.Left_Front_Shoulder_F]: 'الجانب الأمامي الأيسر للكتف',
    [BODYPARTS.Left_Clavicle_F]: 'الترقوة اليسرى',
    [BODYPARTS.Right_Lower_Chest_F]: 'الجانب السفلي الأيمن للصدر',
    [BODYPARTS.Right_Upper_Chest_F]: 'الجانب العلوي الأيمن للصدر',
    [BODYPARTS.Right_Front_Shoulder_F]: 'الجانب الأمامي الأيمن للكتف',
    [BODYPARTS.Right_Clavicle_F]: 'الترقوة اليمنى',
    [BODYPARTS.Torso_R]: 'الجانب الأيمن للصدر',
    [BODYPARTS.Right_Upper_Chest_R]: 'الجانب العلوي الأيمن للصدر',
    [BODYPARTS.Upper_Right_Flank_R]: 'الخاصرة العليا اليمنى',
    [BODYPARTS.Right_Shoulder_Blade_R]: 'عظمة الكتف اليمنى',
    [BODYPARTS.Right_Lower_Chest_R]: 'الجانب السفلي الأيمن للصدر',
    [BODYPARTS.Front_Right_Ribcage_R]: 'الجانب الأمامي الأيمن للقفص الصدري',
    [BODYPARTS.Torso_L]: 'الجانب الأيسر للصدر',
    [BODYPARTS.Left_Upper_Chest_L]: 'الجانب العلوي الأيسر للصدر',
    [BODYPARTS.Upper_Left_Flank_L]: 'الخاصرة العليا اليسرى',
    [BODYPARTS.Left_Shoulder_Blade_L]: 'عظمة الكتف اليسرى',
    [BODYPARTS.Left_Lower_Chest_L]: 'الجانب السفلي الأيسر للصدر',
    [BODYPARTS.Front_Left_Ribcage_L]: 'الجانب الأمامي الأيسر للقفص الصدري',
    [BODYPARTS.Abdomen_F]: 'البطن',
    [BODYPARTS.Epigastric_F]: 'المعدة',
    [BODYPARTS.Left_Upper_Abdomen_F]: 'أعلى البطن الأيسر',
    [BODYPARTS.Right_Upper_Abdomen_F]: 'أعلى البطن الأيمن',
    [BODYPARTS.Left_Lower_Abdomen_F]: 'اسفل البطن الأيسر',
    [BODYPARTS.Right_Lower_Abdomen_F]: 'أسفل البطن الأيمن',
    [BODYPARTS.Umbilical_region_F]: 'البطن الأوسط',
    [BODYPARTS.Right_Middle_Abdomen_F]: 'الجانب الأيمن للبطن',
    [BODYPARTS.Left_Middle_Abdomen_F]: 'الجانب الأيسر للبطن',
    [BODYPARTS.Lumbal_region_B]: 'أسفل الظهر',
    [BODYPARTS.Lumbal_Paravertebral_Left_B]: 'وسط أسفل الظهر الأيسر',
    [BODYPARTS.Lumbal_Paravertebral_Right_B]: 'وسط أسفل الظهر الأيمن',
    [BODYPARTS.Left_SI_Joint_B]: 'المفصل العجزي الحرقفي الأيسر',
    [BODYPARTS.Right_SI_Joint_B]: 'المفصل العجزي الحرقفي الأيمن',
    [BODYPARTS.Left_Lowerback_B]: 'أسفل الظهر الأيسر',
    [BODYPARTS.Right_Lowerback_B]: 'أسفل الظهر الأيمن',
    [BODYPARTS.Left_Flank_B]: 'الخاصرة اليسرى',
    [BODYPARTS.Right_Flank_B]: 'الخاصرة اليمنى',
    [BODYPARTS.Lumbal_spine_B]: 'فقرات الظهر السفلى',
    [BODYPARTS.Abdomen_L]: 'الجانب الأيسر للبطن',
    [BODYPARTS.Left_Upper_Flank_L]: 'الجانب العلوي للخاصرة اليسرى',
    [BODYPARTS.Left_Middle_Abdomen_L]: 'الجانب الأيسر للبطن',
    [BODYPARTS.Left_Lower_Flank_L]: 'اسفل الخاصرة اليسرى',
    [BODYPARTS.Abdomen_R]: 'الجانب الأيمن للبطن',
    [BODYPARTS.Right_Upper_Flank_R]: 'الجانب العلوي للخاصرة اليمنى',
    [BODYPARTS.Right_Middle_Abdomen_R]: 'الجانب الأيمن لوسط البطن',
    [BODYPARTS.Right_Lower_Flank_R]: 'اسفل الخاصرة اليمنى',
    [BODYPARTS.Hip_Region_F]: 'الجانب الأمامي لمنطقة الورك',
    [BODYPARTS.Left_Hipbone_F]: 'حافة الورك اليسري',
    [BODYPARTS.Right_Hipbone_F]: 'حافة الورك اليمنى',
    [BODYPARTS.Lower_Abdominal_F]: 'أسفل البطن',
    [BODYPARTS.Left_Inguinals_F]: 'الجانب الداخلي للفخذ الأيسر',
    [BODYPARTS.Right_Inguinals_F]: 'الجانب الداخلي للفخذ الأيمن',
    [BODYPARTS.Pubic_region_F]: 'منطقة العانة',
    [BODYPARTS.Hip_Region_B]: 'الجانب الخلفي لمنطقة الورك',
    [BODYPARTS.Left_Gluteal_B]: 'الأليَة اليسرى',
    [BODYPARTS.Right_Gluteal_B]: 'الأليًة اليمنى',
    [BODYPARTS.Perianal_region_B]: 'منطقة الشرج',
    [BODYPARTS.Sacral_region_B]: 'منطقة العجز',
    [BODYPARTS.Hip_Region_L]: 'منطقة الورك اليسرى',
    [BODYPARTS.Left_Gluteal_BL]: 'الأليًة اليسرى',
    [BODYPARTS.Hip_Region_R]: ' منطقة الورك اليمنى',
    [BODYPARTS.Right_Gluteal_BR]: 'الأليَة اليمنى',
  },
};
