import React from 'react';
import { Field, useField } from 'react-final-form';

import { DropDown, TextInput } from '../../forms/fieldRenderers';
import { composeName } from '../../../utils/functions';
import { CONDITION_CRITERIA } from '../../../models/Question';

import styles from './NumberCondition.module.scss';

const options = [
  {
    value: CONDITION_CRITERIA.Greater,
    label: 'greater than',
  },
  {
    value: CONDITION_CRITERIA.Lesser,
    label: 'less than',
  },
  {
    value: CONDITION_CRITERIA.Between,
    label: 'between',
  },
  {
    value: CONDITION_CRITERIA.NotBetween,
    label: 'not between',
  },
];

const numberInputProps = {
  maxLength: 3,
  size: 3,
};

const useFieldValue = (name) => {
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } });
  return value;
};

const NumberConditionFormPart = ({ name, leftLabel, rightLabel }) => {
  const criteriaName = composeName(name, 'content.condition.criteria');
  const valueMinName = composeName(name, 'content.condition.value[0]');
  const valueMaxName = composeName(name, 'content.condition.value[1]');
  const criteria = useFieldValue(criteriaName);
  const hasTwoBounds =
    criteria === CONDITION_CRITERIA.Between ||
    criteria === CONDITION_CRITERIA.NotBetween;
  return (
    <div className={styles.root}>
      {leftLabel}
      <Field
        name={criteriaName}
        component={DropDown}
        options={options}
        fixedError={false}
      />
      <Field
        name={valueMinName}
        component={TextInput}
        type="text"
        fixedError={false}
        inputProps={numberInputProps}
      />
      {hasTwoBounds && (
        <>
          <span>and</span>
          <Field
            name={valueMaxName}
            component={TextInput}
            type="text"
            fixedError={false}
            inputProps={numberInputProps}
          />
        </>
      )}
      {rightLabel}
    </div>
  );
};

export default NumberConditionFormPart;
