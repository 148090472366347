import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { composeName } from '../../../utils/functions';
import { Toggle, TextFieldsArray } from '../fieldRenderers';
import ContrastTitle from '../../widgets/ContrastTitle';

import i18n from '../../../i18n';

const ButtonsPartialForm = ({ input: { name }, disabled }) => (
  <div>
    <ContrastTitle>{i18n.t('buttons')}</ContrastTitle>
    <Field
      name={composeName(name, 'content.multi')}
      component={Toggle}
      label={i18n.t('multiple_answers_allowed')}
      disabled={disabled}
    />
    <FieldArray
      name={composeName(name, 'possible_answers')}
      formatItem={({ text, index }) => text}
      parseItem={(text, index) => ({ text, index })}
      component={TextFieldsArray}
      itemLabel={(idx) => `${i18n.t('button')} ${idx + 1}`}
      addBtnLabel={i18n.t('add_button')}
      disabled={disabled}
    />
  </div>
);

export default ButtonsPartialForm;


/*


{
id: integer
text: string
account_conditions: [{
  id: integer
  account_field: ['gender', 'weight', 'height', 'age']
  condition: {
     operator: ['anyof', 'greater', 'lesser', 'equal']
     parameters: [string]
  }
}]
answer_conditions: [{
  id: integer
  question_id: integer
  condition: {
     operator: ['anyof', 'greater', 'lesser', 'equal']
     parameters: [string]
  }
}]
}
 */
