import React from 'react'
import { useFormState, Field, useField } from 'react-final-form'

import { QUESTION_TYPE, VALID_PARENT_TYPE } from '../../../models/Question'
import { composeName } from '../../../utils/functions'
import { DropDown } from '../../forms/fieldRenderers'
import AnyOfConditionFormPart from './AnyOfCondition.formPart'
import styles from './AnswerCondition.module.scss'

const PARTIAL_FORM = {
  [QUESTION_TYPE.Buttons]: AnyOfConditionFormPart,
  [QUESTION_TYPE.Slider]: AnyOfConditionFormPart,
  [QUESTION_TYPE.DropDown]: AnyOfConditionFormPart,
}

const AnswerConditionFormPart = ({ name, disabled }) => {
  const conditionPath = composeName(name, 'content.condition')
  const { values: complaint } = useFormState({
    subscription: { values: true },
  })
  const {
    input: { value: currentQuestion },
  } = useField(name)
  const { questions } = complaint
  const currentIndex = questions.indexOf(currentQuestion)
  const parentOptions = questions
    .slice(0, currentIndex)
    .map((question, index) => ({
      value: index,
      label: question.content.slideName,
      template: question.template,
    }))
    .filter(q => VALID_PARENT_TYPE.includes(q.template))
  const parentIdxPath = composeName(conditionPath, 'parentIdx')
  const {
    input: { value: parentIndex },
  } = useField(parentIdxPath)

  let parentQuestion
  let formPart
  if (typeof parentIndex === 'number' && !Number.isNaN(parentIndex) && parentIndex >= 0) {
    parentQuestion = questions[parentIndex]
    if (parentQuestion) formPart = PARTIAL_FORM[parentQuestion.template]
  }

  return (
    <>
      <div className={styles.align}>
        Answer to
        <Field
          name={parentIdxPath}
          component={DropDown}
          options={parentOptions}
          fixedError={false}
        />
      </div>
      <div>is any of:</div>
      {parentQuestion && formPart && (
        <Field name={name} component={formPart} question={parentQuestion} disabled={disabled} />
      )}
    </>
  )
}

export default AnswerConditionFormPart
