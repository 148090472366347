import React, { memo, useCallback, useState } from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import Button from '@material-ui/core/Button'
import { Divider } from '@material-ui/core'

import { TextInput, TextArea, DropDown, Toggle } from '../../../fieldRenderers'
import NumberUnitPreview from '../../../../widgets/QuestionPreview/components/NumberUnit.preview'
import { Container, AnswersRow, QuestionRow } from './Styles'
import Dummy from '../../../../widgets/Dummy'

const CONDITION_OPERATOR_SPECIFIC = {
  Buttons: [
    { value: 'anyof', label: 'Any Of' },
    { value: 'allof', label: 'All Of' },
  ],
  Localization: [
    { value: 'anyof', label: 'Any Of' },
    { value: 'allof', label: 'All Of' },
  ],
  DropDown: [{ value: 'equal', label: 'Equal to' }],
}

const CONDITION_OPERATOR_DEFAULT = [
  { value: 'greater', label: 'Greater than' },
  { value: 'lesser', label: 'Lesser than' },
  { value: 'equal', label: 'Equal to' },
]

const Answers = ({ questions, rfIndex, fields }) => {
  const handleQuestionAdd = useCallback(
    qId => {
      fields.push({ question_id: qId, condition: { parameters: [] } })
    },
    [fields, questions]
  )

  return (
    <Container>
      {fields.map((f, idx) => {
        const currentQuestion = questions.find(
          q => q.conditional_id === fields.value?.[idx]?.question_conditional_id
        )

        console.log('CURRENT QUESTION: ', currentQuestion)
        return (
          <>
            <QuestionRow>
              <Field
                label={!idx ? 'Answers for question' : 'And for question'}
                name={`red_flags[${rfIndex}].answer_conditions[${idx}].question_conditional_id`}
                component={DropDown}
                options={questions.map(q => ({
                  value: q.conditional_id,
                  label: q.content?.slideName,
                }))}
              />
              <Field
                label="is"
                name={`red_flags[${rfIndex}].answer_conditions[${idx}].condition.operator`}
                component={DropDown}
                options={
                  CONDITION_OPERATOR_SPECIFIC[currentQuestion.template] ||
                  CONDITION_OPERATOR_DEFAULT
                }
              />
              {(currentQuestion.template === 'Slider' ||
                currentQuestion.template === 'DropDown') && (
                <FieldArray
                  name={`red_flags[${rfIndex}].answer_conditions[${idx}].condition.parameters`}
                  component={({ fields: parameters }) => {
                    console.log('ParametersField: ', parameters)
                    const handleChange = id => {
                      parameters.update(0, id)
                    }

                    return (
                      <DropDown
                        label="Value"
                        options={currentQuestion.possible_answers.map(a => ({
                          label: a.text,
                          value: a.text,
                          id: a.id,
                        }))}
                        input={{
                          value: parameters?.value?.[0],
                          onChange: handleChange,
                        }}
                      />
                    )
                  }}
                />
              )}
              {currentQuestion.template === 'NumberUnit' && (
                <FieldArray
                  name={`red_flags[${rfIndex}].answer_conditions[${idx}].condition.parameters`}
                  component={({ fields: parameters }) => {
                    console.log('ParametersField: ', parameters)
                    const handleChange = id => {
                      console.log('Changed: ', id)
                      if (id) parameters.update(0, id?.trim())
                    }

                    return (
                      <div
                        style={{
                          height: 212,
                          overflowY: 'auto',
                          overscrollBehavior: 'contain',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <NumberUnitPreview
                          slide={{
                            ...currentQuestion,
                            content: {
                              ...currentQuestion.content,
                              units: undefined,
                              suffix: undefined,
                            },
                          }}
                          onChange={handleChange}
                          initialValue={parameters.value?.[0]}
                        />
                      </div>
                    )
                  }}
                />
              )}
            </QuestionRow>
            {currentQuestion.template === 'Buttons' && (
              <FieldArray
                name={`red_flags[${rfIndex}].answer_conditions[${idx}].condition.parameters`}
                component={({ fields: parameters }) => {
                  console.log('ParametersField: ', parameters.value)
                  const addParam = id => () => {
                    parameters.push(id)
                  }
                  const removeParam = idxP => () => {
                    parameters.remove(idxP)
                  }

                  return (
                    <AnswersRow>
                      {currentQuestion.possible_answers.map(a => {
                        const active = parameters.value.includes(a.text)
                        return (
                          <Button
                            key={a.id}
                            onClick={
                              active
                                ? removeParam(parameters.value.findIndex(p => p === a.text))
                                : addParam(a.text)
                            }
                            style={active ? { color: '#fff', backgroundColor: '#3f51b5' } : {}}
                          >
                            {a.text}
                          </Button>
                        )
                      })}
                    </AnswersRow>
                  )
                }}
              />
            )}
            {currentQuestion.template === 'Localization' && (
              <FieldArray
                name={`red_flags[${rfIndex}].answer_conditions[${idx}].condition.parameters`}
                component={({ fields: parameters }) => {
                  const handleChange = newValues => {
                    if (newValues.length) {
                      parameters.forEach(p => parameters.remove(0))
                      newValues.forEach((n, i) => {
                        parameters.update(i, n)
                      })
                    } else {
                      for (let i = 0; i < parameters.length; i++) {
                        parameters.remove(0)
                      }
                    }
                  }

                  return (
                    <AnswersRow>
                      <Dummy
                        style={{
                          flex: 1,
                          height: '50vmin',
                          padding: '0 10%',
                        }}
                        onSelectionChange={handleChange}
                        selection={parameters.value}
                      />
                    </AnswersRow>
                  )
                }}
              />
            )}
            <br />
            <Divider />
          </>
        )
      })}
      <DropDown
        label="Answers for question"
        options={questions.map(q => ({
          value: q.id,
          label: q.content?.slideName,
        }))}
        input={{
          onChange: handleQuestionAdd,
          value: '',
        }}
      />
    </Container>
  )
}

Answers.whyDidYouRender = true

export default memo(Answers)
