import React from 'react';
import classNames from 'classnames';

import styles from './Label.module.scss';

const Label = ({ children, light }) => (
  <span className={classNames(styles.label, light && styles.light)}>
    {children}
  </span>
);

export default Label;
