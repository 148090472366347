import React, { useEffect } from 'react';
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/semanticui.scss';

import { onMessage, unRegister, NOTIF_TYPE } from '../utils/Notification';
import './Notifications.module.scss';

// If you change that, make sure to update global selectors in Notifications.module.scss
const CONTAINER_ID = 'notifications';
const NOTIF_DURATION = 2000;

const TYPE_MAP = {
  [NOTIF_TYPE.SUCCESS]: 'success',
  [NOTIF_TYPE.ERROR]: 'error',
  [NOTIF_TYPE.INFO]: 'info',
};

function handleMessage({ text, type }) {
  new Noty({
    container: `#${CONTAINER_ID}`,
    theme: 'semanticui',
    type: TYPE_MAP[type],
    text,
    timeout: NOTIF_DURATION,
  }).show();
}

export default () => {
  useEffect(() => {
    onMessage(handleMessage);
    return () => unRegister(handleMessage);
  }, []);
  return <div id={CONTAINER_ID} />;
};
