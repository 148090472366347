import React, { useCallback, useState } from 'react'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { ROUTES } from 'Constants'
import { CS } from 'back-end-api'

import i18n from '../../../i18n'
import ContrastTitle from '../../widgets/ContrastTitle'
import SimpleList from '../../widgets/SimpleList'
import {
  formatQuestions,
  formatRedFlags,
} from '../../../screens/ComplaintForm/ComplaintForm.container'

import defaultRedFlag from './defaultRedFlag.json'
import Popup from './Popup'
import Form from './Form'
import { getRelatedRedFlags } from './Tools'

const RelatedRedFlags = ({ fields, complaint, questionIndex, history }) => {
  const [popupOpen, setPopupOpen] = useState(false)
  const [currentRedFlag, setCurrentRedFlag] = useState(null)

  const relatedRedFlags = getRelatedRedFlags(
    fields.value || [],
    complaint?.questions[questionIndex]?.conditional_id
  )

  const handleEdit = useCallback(
    id => () => {
      const rfIndex = relatedRedFlags.findIndex(r => r.id === id)
      setCurrentRedFlag({
        ...relatedRedFlags[rfIndex],
        index: complaint?.red_flags?.findIndex(r => r.id === relatedRedFlags[rfIndex]?.id),
      })
      setPopupOpen(true)
    },
    [relatedRedFlags]
  )

  const handleCreate = useCallback(() => {
    fields.push(defaultRedFlag)
    setCurrentRedFlag({ index: fields.length })
    setPopupOpen(true)
  }, [fields])

  const handleClose = useCallback(() => {
    setPopupOpen(false)
  }, [])

  const handleDelete = useCallback(
    rfId => async () => {
      const finalComplaint = { ...complaint }
      const rfIdxToDelete = finalComplaint?.red_flags?.findIndex(r => r.id === rfId)
      finalComplaint?.red_flags?.splice(rfIdxToDelete, 1)
      const res = await CS.updateComplaint(finalComplaint)
      if (res.data) {
        history.push(ROUTES.EDIT_COMPLAINT(complaint.id + 1))
        window.location.reload()
      }
    },
    [fields, complaint]
  )

  const handleSave = useCallback(async () => {
    const res = await CS.updateComplaint(formatRedFlags(formatQuestions(complaint)))
    if (res.error) console.log('Error')
    else {
      setPopupOpen(false)
      history.push(ROUTES.EDIT_COMPLAINT(complaint.id + 1))
      window.location.reload()
    }
  }, [fields, currentRedFlag])

  return (
    <>
      <ContrastTitle>Related Red Flags</ContrastTitle>
      <br />
      {!!relatedRedFlags?.length && (
        <SimpleList
          noSearch
          data={relatedRedFlags}
          renderItem={({ text }) => text}
          renderItemActions={item => (
            <>
              <Button onClick={handleDelete(item.id)}>
                <DeleteIcon />
                Delete
              </Button>
              <Button onClick={handleEdit(item.id)}>
                <EditIcon />
                {i18n.t('edit')}
              </Button>
            </>
          )}
        />
      )}
      <Button
        onClick={handleCreate}
        fullWidth
        style={{ marginTop: 12, color: '#fff', backgroundColor: '#3f51b5' }}
      >
        Add Red Flag
      </Button>
      <Popup active={popupOpen} onClose={handleClose}>
        <Form
          values={currentRedFlag}
          questions={complaint.questions}
          currentQuestionIndex={questionIndex}
        />
        <Button
          onClick={handleSave}
          style={{
            position: 'absolute',
            bottom: 24,
            right: 24,
            color: '#fff',
            backgroundColor: '#3f51b5',
          }}
        >
          Save
        </Button>
      </Popup>
    </>
  )
}

export default RelatedRedFlags
