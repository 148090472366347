import React from 'react';
import { Field } from 'react-final-form';

import { CONDITION_CRITERIA } from '../../../models/Question';
import useFieldInit from '../../../hooks/useFieldInit';
import { composeName } from '../../../utils/functions';
import { CheckboxGroup } from '../fieldRenderers';
import ErrorText from '../../widgets/ErrorText';

const AnyOfConditionFormPart = ({
  input: { name },
  meta: { error },
  question,
}) => {
  const conditionPath = composeName(name, 'content.condition');
  useFieldInit(
    composeName(conditionPath, 'criteria'),
    CONDITION_CRITERIA.AnyOf,
  );
  const answers = question.possible_answers.map(({ text }) => text);
  return (
    <div>
      <Field
        name={composeName(conditionPath, 'indexes')}
        options={answers}
        component={CheckboxGroup}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

export default AnyOfConditionFormPart;
