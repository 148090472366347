import React from 'react'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import AddIcon from '@material-ui/icons/AddOutlined'
import EventNoteIcon from '@material-ui/icons/EventNote'

import { logout, getAccessToken } from 'services/Authentication'
import PrimaryButton from '../../components/widgets/PrimaryButton'
import { ROUTES } from 'Constants'

import styles from './SideBar.module.scss'

import i18n from '../../i18n'

export const NAV_ITEMS = [
  {
    path: ROUTES.COMPLAINT_DASHBOARD,
    label: i18n.t('see_all_complaints'),
    icon: EventNoteIcon,
  },
]

const NavItem = ({ to, icon, label, current = false }) => (
  <Link to={to} className={classNames(styles.navItem, current && styles.current)}>
    <div className={styles.icon}>{icon}</div>
    {label}
  </Link>
)

const SideBar = withRouter(({ history, location: { pathname }, classes }) => {
  const handleLogOut = () => {
    const token = getAccessToken()
    if (token) {
      logout({ access_token: token })
    }
    history.push(ROUTES.LOGIN())
  }
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{i18n.t('general')}</h1>
      <div>
        {NAV_ITEMS.map(({ path, label, icon: Icon }, i) => (
          <NavItem key={i} label={label} to={path} icon={<Icon />} current={pathname === path} />
        ))}
        <NavItem label={i18n.t('add_new_complaint')} to={ROUTES.NEW_COMPLAINT} icon={<AddIcon />} />
      </div>
      <div className={styles.logout}>
        <PrimaryButton onClick={handleLogOut}>{i18n.t('logout')}</PrimaryButton>
      </div>
    </div>
  )
})

export default SideBar
