import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { composeName } from '../../../utils/functions';
import { TextFieldsArray } from '../fieldRenderers';
import ContrastTitle from '../../widgets/ContrastTitle';

import i18n from '../../../i18n';

const SliderPartialForm = ({ input: { name }, disabled }) => (
  <div>
    <ContrastTitle>{i18n.t('slider')}</ContrastTitle>
    <FieldArray
      name={composeName(name, 'possible_answers')}
      component={TextFieldsArray}
      formatItem={({ text, index }) => text}
      parseItem={(text, index) => ({ text, index })}
      itemLabel={(idx) => `${i18n.t('step')} ${idx + 1}`}
      addBtnLabel={i18n.t('add_step')}
      disabled={disabled}
    />
  </div>
);

export default SliderPartialForm;
