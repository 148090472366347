import React from 'react'
import { Field } from 'react-final-form'

import { composeName } from '../../utils/functions'
import { TextInput, TextArea, DropDown, Toggle } from './fieldRenderers'
import { QUESTION_TYPE } from '../../models/Question'
import ConditionPartialForm from './Condition.formPart'
import ButtonsPartialForm from './questionWidgets/Buttons.formPart'
import SliderPartialForm from './questionWidgets/Slider.formPart'
import DatePickerPartialForm from './questionWidgets/DatePicker.formPart'
import DropdownPartialForm from './questionWidgets/Dropdown.formPart'
import LocalizationPartialForm from './questionWidgets/Localization.formPart'
import NumberUnitPartialForm from './questionWidgets/NumberUnit.formPart'
import FreeTextPartialForm from './questionWidgets/FreeText.formPart'
import EmergencyForm from './questionWidgets/Emergency'

import i18n from '../../i18n'

const TYPE_LABELS = {
  [QUESTION_TYPE.FreeText]: i18n.t('free_text'),
  [QUESTION_TYPE.NumberUnit]: i18n.t('number_with_unit'),
}
const QUESTION_TYPE_OPTIONS = Object.keys(QUESTION_TYPE).map(k => ({
  value: k,
  label: TYPE_LABELS[k] || k,
}))

const PARTIAL_FORM = {
  [QUESTION_TYPE.Buttons]: ButtonsPartialForm,
  [QUESTION_TYPE.DatePicker]: DatePickerPartialForm,
  [QUESTION_TYPE.DropDown]: DropdownPartialForm,
  [QUESTION_TYPE.NumberUnit]: NumberUnitPartialForm,
  [QUESTION_TYPE.Slider]: SliderPartialForm,
  [QUESTION_TYPE.FreeText]: FreeTextPartialForm,
  [QUESTION_TYPE.Localization]: LocalizationPartialForm,
  [QUESTION_TYPE.Emergency]: EmergencyForm,
}

const QuestionPartialForm = ({ input: { name, value } }) => {
  const isConditional = value.content.isConditional
  const PartialForm = PARTIAL_FORM[value.template]
  return (
    <div>
      <div>
        <Field
          name={composeName(name, 'content.slideName')}
          label={i18n.t('question_name')}
          component={TextInput}
        />
        <Field
          name={composeName(name, 'template')}
          label={i18n.t('question_type')}
          options={QUESTION_TYPE_OPTIONS}
          component={DropDown}
        />
      </div>
      <div>
        <Field
          name={composeName(name, 'text')}
          label={i18n.t('top_message')}
          component={TextArea}
          rows={2}
          fullWidth
        />
        <Field
          name={composeName(name, 'content.summaryTemplate')}
          label={i18n.t('summary_text_template')}
          component={TextArea}
          rows={2}
          fullWidth
        />
        <div>
          <Field
            name={composeName(name, 'content.isMandatory')}
            label={i18n.t('mandatory_question')}
            component={Toggle}
          />
        </div>
        <div>
          <Field
            name={composeName(name, 'content.isConditional')}
            label={i18n.t('conditional_question')}
            component={Toggle}
          />
        </div>
      </div>
      {isConditional && <ConditionPartialForm name={name} />}
      {PartialForm && <Field component={PartialForm} name={name} />}
    </div>
  )
}

export default QuestionPartialForm
