import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';

import styles from './SimpleList.module.scss';

const SimpleList = ({
  searchPlaceholder = '',
  onSearchChange = () => null,
  data = [],
  renderItem = () => null,
  renderItemActions = () => null,
  noSearch = false,
}) => {
  const handleSearch = (ev) => onSearchChange(ev.target.value);
  return (
    <div>
      {!noSearch && (
        <TextField
          className={styles.search}
          placeholder={searchPlaceholder}
          variant="outlined"
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      )}
      <Paper>
        <Table>
          <TableBody>
            {data.map((item, i) => {
              return (
                <TableRow key={i}>
                  <TableCell colSpan="5" className={styles.itemCell}>
                    {renderItem(item)}
                  </TableCell>
                  <TableCell align="right">{renderItemActions(item)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};
SimpleList.propTypes = {
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  onSearchChange: PropTypes.func,
  renderItemActions: PropTypes.func,
};

export default SimpleList;
