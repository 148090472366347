import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../i18n'

export default class ErrorBoundary extends React.PureComponent {
  static propTypes = {
    canRetry: PropTypes.bool,
    message: PropTypes.string,
  }

  static defaultProps = {
    canRetry: false,
    message: i18n.t('something_wrong'),
  }

  state = { hasError: false }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
  }

  handleRetry = () => {
    this.setState({ hasError: false })
  }

  render() {
    const { canRetry, message, children } = this.props
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div>{message}</div>
          {canRetry && (
            <div>
              <button type="button" onClick={this.handleRetry}>
                {i18n.t('retry')}
              </button>
            </div>
          )}
        </div>
      )
    }
    return children
  }
}
