export const getFromLocalOrDefault = <T>(state: T, key: string): T => {
  const item = localStorage.getItem(key)
  return item ? JSON.parse(item) : state
}

export const getFromSessionOrDefault = <T>(state: T, key: string): T => {
  const item = sessionStorage.getItem(key)
  return item ? JSON.parse(item) : state
}

export const saveToLocal = <T>(state: T, key: string): void => {
  const value = JSON.stringify(state)
  localStorage.setItem(key, value)
  console.log(`New ${key} saved locally: `, value)
}

export const saveToSession = <T>(state: T, key: string): void => {
  const value = JSON.stringify(state)
  sessionStorage.setItem(key, value)
  console.log(`New ${key} saved to session: `, value)
}

export const removeFromLocal = (key: string): void => {
  localStorage.removeItem(key)
  console.log('Removed locally: ', key)
}

export const removeFromSession = (key: string): void => {
  sessionStorage.removeItem(key)
  console.log('Removed from session: ', key)
}

export const clearSession = (): void => {
  sessionStorage.clear()
  console.log('Cleared session')
}
