import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import PrimaryButton from '../../PrimaryButton'
import styles from './NumberUnitPreview.module.scss'

function createNumberSequenceArray(from, to) {
  let length = Math.max(0, to - from + 1)
  return new Array(length).fill(null).map((x, i) => from + i)
}

const OPTION_HEIGHT = 32

function getTouchEventPosition(e) {
  return { x: e.touches[0].pageX, y: e.touches[0].pageY }
}

// disable transition while touching
const WheelInput = ({ options, value, onChange }) => {
  const [selectedIdx, setSelectedIdx] = useState(0)
  const scrollPosFromIdx = idx => -OPTION_HEIGHT * idx
  // for touch handling:
  // - scroll state before touching (selectedIdx)
  // - touch start point
  // - current touch point
  // - touch state
  const [isTouching, setTouching] = useState(false)
  const [touchInitValues, setTouchInitValues] = useState(null)
  const [touchPos, setTouchPos] = useState(null)
  useEffect(() => {
    let valueIdx = Math.max(
      0,
      options.findIndex(opt => opt === value)
    )
    setSelectedIdx(valueIdx)
  }, [value, options])
  // follow scroll position
  const scrollEl = useRef(null)

  const handleOptionsScroll = e => {
    e.stopPropagation()
    let newIndex = selectedIdx
    if (e.deltaY > 0) {
      // go down
      newIndex = Math.min(options.length - 1, selectedIdx + 1)
    } else if (e.deltaY < 0) {
      // go up
      newIndex = Math.max(0, selectedIdx - 1)
    }
    setSelectedIdx(newIndex)
    onChange(options[newIndex])
  }
  const handleTouchStart = e => {
    setTouchInitValues({ pos: getTouchEventPosition(e), idx: selectedIdx })
    setTouchPos(getTouchEventPosition(e))
    setTouching(true)
  }
  const handleTouchEnd = e => {
    setTouching(false)
  }
  const handleTouchMove = e => {
    const touchPoss = getTouchEventPosition(e)
    const { pos: touchStartPos, idx: startIdx } = touchInitValues
    setTouchPos(touchPoss)
    // compute current index
    const newIndex = Math.max(
      0,
      Math.min(
        options.length - 1,
        startIdx + Math.round(-(touchPoss.y - touchStartPos.y) / OPTION_HEIGHT)
      )
    )
    setSelectedIdx(newIndex)
    onChange(options[newIndex])
  }

  const maxScrollPos = scrollPosFromIdx(options.length - 1)
  const computeScrollPosition = () => {
    let result
    if (isTouching) {
      const { pos: touchStartPos, idx: startIdx } = touchInitValues
      result = scrollPosFromIdx(startIdx) + touchPos.y - touchStartPos.y
    } else {
      result = scrollPosFromIdx(selectedIdx)
    }
    return Math.max(maxScrollPos, Math.min(0, result))
  }

  let optionsStyle = {
    top: computeScrollPosition() + 'px',
  }
  const classes = classNames(styles.wheelInput, isTouching && styles.touching)
  return (
    <div className={classes}>
      <div
        ref={scrollEl}
        className={styles.scrollableZone}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onWheelCapture={handleOptionsScroll}
      >
        <div className={styles.mask} />
        <div className={styles.options} style={optionsStyle}>
          {options.map((opt, i) => (
            <div
              key={i}
              className={classNames(styles.option, selectedIdx === i && styles.selected)}
            >
              {opt}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
WheelInput.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
}
WheelInput.defaultProps = {
  options: [],
  onChange: () => null,
}

const formatInitialValue = value => {
  if (!value) return null
  const out = []
  const regex = /\d+/gm
  let m

  while ((m = regex.exec(value)) !== null) {
    if (m.index === regex.lastIndex) regex.lastIndex++
    m.forEach(match => {
      if (out.length <= 1) out.push(parseInt(match[0]))
    })
  }
  if (out.length === 1) out.push(0)
  return out
}

export default ({ slide = {}, onChange, initialValue = null }) => {
  const { content = {} } = slide
  const { min, max, units = [], decimal, suffix } = content
  const parsedInitialValue = formatInitialValue(initialValue)
  // states
  const dropdownEl = useRef(null)
  const [isOpen, setOpenState] = useState(false)
  const [intValue, setIntValue] = useState(parsedInitialValue?.[0] || 0)
  const [decimalValue, setDecimalValue] = useState(parsedInitialValue?.[1] || 0)
  const [unitValue, setUnitValue] = useState('')
  const [value, setValue] = useState(initialValue)
  const [intOptions, setIntOptions] = useState([])
  const [decimalOptions, setDecimalOptions] = useState([])

  // side effects when mutating props
  // useEffect(() => {
  //   onChange(value === null ? '' : value.concat(suffix).join(' '));
  // }, [value, suffix, onChange]);
  useEffect(() => {
    // when min or max props change => reset
    let minValue = parseFloat(min) || 0
    let maxValue = parseFloat(max) || 0
    const newIntOptions = createNumberSequenceArray(Math.floor(minValue), Math.floor(maxValue))
    if (JSON.stringify(newIntOptions) !== JSON.stringify(intOptions)) {
      setIntOptions(newIntOptions)
      setIntValue(parsedInitialValue?.[0] || newIntOptions[0])
      setValue(initialValue)
    }
  }, [min, max, intOptions, parsedInitialValue])
  useEffect(() => {
    // reset units if it changes
    setUnitValue(units[0] || '')
  }, [units])
  useEffect(() => {
    // update decimals options and value when one of these changes:
    // min, max, decimal, intValue
    let minValue = parseFloat(min) || 0
    let maxValue = parseFloat(max) || 0
    let decimalOptions = []
    if (decimal) {
      // compute decimal options
      let from = 0
      let to = 9
      if (intValue === Math.floor(minValue)) {
        from = Math.round(10 * (minValue % 1))
      }
      if (intValue === Math.floor(maxValue)) {
        to = Math.round(10 * (maxValue % 1))
      }
      decimalOptions = createNumberSequenceArray(from, to)
    } else {
      setDecimalValue(parsedInitialValue?.[1] || 0)
    }
    setDecimalOptions(decimalOptions)
  }, [min, max, decimal, intValue, parsedInitialValue])
  useEffect(() => {
    // check if an update of decimal value is needed when changing decimal options
    let from = decimalOptions[0] || 0
    let to = decimalOptions[decimalOptions.length - 1] || 0
    if (from > decimalValue) {
      setDecimalValue(from)
    } else if (to < decimalValue) {
      setDecimalValue(to)
    }
  }, [decimalOptions, decimalValue])

  // methods
  const openDropDown = () => {
    // compute dropdown content height
    const contentHeight = [...dropdownEl.current.children].reduce((acc, child) => {
      return acc + child.offsetHeight
    }, 0)
    // set max height to content height (everything visible)
    dropdownEl.current.style.maxHeight = contentHeight + 'px'
    if (value !== null) {
      // reset wheels to current value
      const [floatValue, unit] = value
      setIntValue(Math.floor(floatValue))
      setDecimalValue(Math.round(10 * (floatValue % 1)))
      setUnitValue(unit)
    }
    setOpenState(true)
  }
  // hide all content
  const closeDropDown = () => {
    dropdownEl.current.style.maxHeight = 0
    setOpenState(false)
  }
  // User validate its input
  const submitValue = () => {
    let floatValue = decimal ? parseFloat(intValue + '.' + decimalValue) : parseInt(intValue)
    setValue([floatValue, unitValue].concat(suffix).join(' '))
    onChange([floatValue, unitValue].concat(suffix).join(' '))
    closeDropDown()
  }
  return (
    <div className={classNames(styles.root, isOpen && styles.open)}>
      <div className={styles.box}>
        <div className={styles.permaDisplay}>
          <span onClick={openDropDown}>
            {value === null && 'Set value'}
            {value !== null && value}
          </span>
          <CloseIcon className={styles.icon} onClick={closeDropDown} />
        </div>
        <div ref={dropdownEl} className={styles.dropdown}>
          <div className={styles.pickTool}>
            <WheelInput
              onScroll={console.log}
              options={intOptions}
              onChange={setIntValue}
              value={intValue}
            />
            {decimal && (
              <>
                <span className={styles.colorText}>.</span>
                <WheelInput
                  options={decimalOptions}
                  onChange={setDecimalValue}
                  value={decimalValue}
                />
              </>
            )}
            <WheelInput options={units} onChange={setUnitValue} value={unitValue} />
            {suffix && <span className={styles.colorText}>{suffix}</span>}
          </div>
          <div>
            <PrimaryButton className={styles.submitBtn} onClick={submitValue}>
              Done
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
