import React from 'react';
import SideBar from './widgets/SideBar';
import styles from './HomeLayout.module.scss';

const HomeLayout = ({ children }) => (
  <div className={styles.root}>
    <SideBar />
    <main className={styles.content}>{children}</main>
  </div>
);

export default HomeLayout;