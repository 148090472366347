import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { DatePicker } from 'material-ui-pickers'

export default ({ slide: { content = {} }, onChange }) => {
  const { minDate = {}, maxDate = {} } = content
  const [value, setValue] = useState('')
  useEffect(() => {
    onChange(value)
  }, [value, onChange])
  let pickerProps = {}
  if (minDate.alwaysNow) {
    pickerProps.minDate = Date.now()
  } else if (minDate.value) {
    pickerProps.minDate = minDate.value
  }
  if (maxDate.alwaysNow) {
    pickerProps.maxDate = Date.now()
  } else if (maxDate.value) {
    pickerProps.maxDate = maxDate.value
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        <DatePicker
          value={value || null}
          placeholder="Select a date"
          onChange={setValue}
          autoOk
          {...pickerProps}
        />
      </div>
      <br />
      <Button variant="contained" color="primary">
        Done
      </Button>
    </div>
  )
}
