import React from 'react';
import Button from '@material-ui/core/Button';

import styles from './FreeTextPreview.module.scss';

export default ({ slide }) => {
  const { content = {} } = slide;
  const { multi } = content;
  return (
    <div>
      {multi && (
        <div className={styles.btnRow}>
          <Button variant="contained" color="primary">
            Hold for voice input
          </Button>
        </div>
      )}
      <div>
        <textarea
          placeholder="Type your answer here"
          className={styles.textarea}
          rows="5"
        />
      </div>
      <div className={styles.submitRow}>
        <Button variant="contained" color="primary">
          Done
        </Button>
      </div>
    </div>
  );
};
