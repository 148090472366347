import React, { memo } from 'react';

import { Container, Content, Overlay } from './Styles';

const Popup = ({ children, active, onClose }) => {
  if (!active) return null;
  return (
    <Container>
      <Overlay onClick={onClose}/>
      <Content>
        {children}
      </Content>
    </Container>
  );
};

Popup.whyDidYouRender = true;

export default memo(Popup);
