import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import styles from './DropdownPreview.module.scss';

export default ({ slide: { possible_answers = [] }, onChange }) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    onChange(value);
  }, [value, onChange]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className={styles.root}>
      <div>
        <FormControl className={styles.selectCtrl}>
          <Select
            value={value}
            onChange={handleChange}
            inputProps={{
              name: 'age',
            }}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {possible_answers.map(({ text: label }, index) => (
              <MenuItem key={index} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <br />
      <div>
        <Button variant="contained" color="primary" disabled={value === ''}>
          Done
        </Button>
      </div>
    </div>
  );
};
