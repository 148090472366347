import React from 'react';
import { Field } from 'react-final-form';

import { TextInput } from '../../fieldRenderers';
import ContrastTitle from '../../../widgets/ContrastTitle';

const Emergency = ({ input: { name }, disabled }) => (
  <div>
    <ContrastTitle>Emergency Widget</ContrastTitle>
    <div style={{ width: '100%', height: 16 }} />
    <Field
      name={`${name}.content.toCall`}
      component={TextInput}
      formatItem={({ text, index }) => text}
      parseItem={(text, index) => ({ text, index })}
      label="Number to call"
      disabled={disabled}
    />
    <Field
      name={`${name}.content.btnLabel`}
      component={TextInput}
      formatItem={({ text, index }) => text}
      parseItem={(text, index) => ({ text, index })}
      label="Button Label"
      disabled={disabled}
    />
  </div>
);

export default Emergency;
