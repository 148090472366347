import React, { useEffect, useState } from 'react'
import getProperty from 'lodash/get'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Link } from 'react-router-dom'
// icons
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { ROUTES } from 'Constants'
import PrimaryButton from '../../components/widgets/PrimaryButton'
import ContrastTitle from '../../components/widgets/ContrastTitle'
import { TextInput, Toggle, DropDown } from '../../components/forms/fieldRenderers'
import QuestionPartialForm from '../../components/forms/Question.formPart'
import RelatedRedFlags from '../../components/forms/RelatedRedFlags/index'
import { normalizeComplaint, validateComplaint } from '../../models/Complaint'
import QuestionPreview from '../../components/widgets/QuestionPreview'
import downloadFile from '../../utils/downloadFile'
import SlideSelectionList from './components/SlideSelectionList.formPart'
import styles from './ComplaintForm.module.scss'

import i18n from '../../i18n'

const FORM_MUTATORS = arrayMutators

const handleSubmit = normalizeCallBack => async _values => {
  const values = normalizeComplaint(_values)
  return normalizeCallBack(values)
}

const ComplaintFormView = ({
  error,
  loading,
  editMode,
  initialValues,
  onSubmit,
  history,
  rawComplaint,
  importComplaint: handleImport,
}) => {
  const [selectedSlide, setSelectedSlide] = useState(0)

  const slidePath = `questions[${selectedSlide}]`

  const exportComplaint = e => {
    e.preventDefault()
    downloadFile({
      data: rawComplaint,
      name: { ...rawComplaint }.name.replace(/[^\x00-\x7F]+/, '_').toLowerCase(),
    })
  }

  const importComplaint = e => {
    const reader = new FileReader()
    reader.onload = async event => {
      const text = event.target.result
      console.log(text)
      const parsedComplaint = JSON.parse(text)
      console.log(parsedComplaint)
      handleImport(parsedComplaint)
    }
    reader.readAsText(e.target.files[0])
  }

  if (loading) {
    return <CircularProgress className={styles.loadingSpinner} />
  }
  if (error) {
    return { error }
  }
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      validate={validateComplaint}
      initialValues={initialValues}
      mutators={FORM_MUTATORS}
      render={({ handleSubmit: submit, invalid, submitting, values }) => (
        <form onSubmit={submit} className={styles.root} autoComplete="off">
          <header>
            <div className={styles.left}>
              <Link to={ROUTES.COMPLAINT_DASHBOARD}>
                <IconButton className={styles.btn}>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <span>
                {editMode ? i18n.t('edit') : i18n.t('create')} {i18n.t('a_complaint')}
              </span>
            </div>
            <div>
              <input type="file" name="file" onChange={importComplaint} />
              <PrimaryButton onClick={exportComplaint}>Export</PrimaryButton>
              <Button className={styles.btn}>{i18n.t('finish_later')}</Button>
              <PrimaryButton type="submit" disabled={invalid || submitting}>
                {i18n.t('save_complaint')}
              </PrimaryButton>
            </div>
          </header>
          <main>
            <div className={styles.complaintGeneral}>
              <Field name="name" component={TextInput} label={i18n.t('complaint_name')} />
              <Field name="description" component={TextInput} label="Description" />
              <Field
                name="language_tag"
                component={DropDown}
                options={[
                  { value: 'en', label: 'English' },
                  { value: 'nl', label: 'Dutch' },
                  { value: 'ar', label: 'Arabic' },
                ]}
                label="Language"
              />
              <div className={styles.addlQuestions}>
                <Field
                  name="AlwaysShowAdditionalComplaints"
                  label={i18n.t('show_additional_complaints')}
                  component={Toggle}
                />
              </div>
              <ContrastTitle>{i18n.t('slides')}</ContrastTitle>
              <FieldArray
                name="questions"
                component={SlideSelectionList}
                onEditSlide={setSelectedSlide}
                selectedSlide={selectedSlide}
              />
            </div>
            <div className={styles.editSlideAndPreview}>
              <div className={styles.slideEditor}>
                {selectedSlide >= 0 && (
                  <>
                    <Field name={slidePath} component={QuestionPartialForm} />
                    <FieldArray
                      name="red_flags"
                      component={RelatedRedFlags}
                      complaint={values}
                      questionIndex={selectedSlide}
                      history={history}
                    />
                  </>
                )}
              </div>
              <div className={styles.slidePreview}>
                <div className={styles.paper}>
                  <QuestionPreview complaint={values} question={getProperty(values, slidePath)} />
                </div>
                <div className={styles.buttonRow}>
                  <FieldArray name="questions">
                    {({ fields }) => (
                      <Button
                        className={styles.deleteSlideBtn}
                        onClick={() => {
                          fields.remove(selectedSlide)
                          const newIndex = Math.max(0, selectedSlide - 1)
                          setSelectedSlide(-1)
                          if (fields.length - 1 > 0) {
                            // prevent update error using this timeout trick:
                            setTimeout(() => setSelectedSlide(newIndex), 0)
                          }
                        }}
                      >
                        <DeleteIcon />
                        {i18n.t('remove_slide')}
                      </Button>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>
          </main>
        </form>
      )}
    />
  )
}

export default ComplaintFormView
