import React, { useState, useEffect } from 'react'
import Slider from '@material-ui/lab/Slider'

import styles from './SliderPreview.module.scss'

export default ({ slide: { possible_answers = [] }, onChange }) => {
  const [value, setValue] = useState(0)
  useEffect(() => {
    onChange(possible_answers[value].text)
  }, [onChange, value, possible_answers])
  const handleChange = (event, valuee) => {
    setValue(valuee)
  }
  const labelMaxWidth = 100 / possible_answers.length
  return (
    <div>
      <div className={styles.labelsRow}>
        {possible_answers.map(({ text: label }, index) => {
          const left = (100 * index) / (possible_answers.length - 1) + '%'
          const isFirstOrLast = index === 0 || index === possible_answers.length - 1
          const style = {
            maxWidth: (isFirstOrLast ? labelMaxWidth / 2 : labelMaxWidth) + '%',
            left,
          }
          return (
            <div key={index} className={styles.label} style={style}>
              {label}
            </div>
          )
        })}
      </div>
      {possible_answers.length > 1 && (
        <Slider
          style={{ zIndex: 0 }}
          step={1}
          min={0}
          max={possible_answers.length - 1}
          value={value}
          onChange={handleChange}
        />
      )}
    </div>
  )
}
