import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Done';

import styles from './ButtonsPreview.module.scss';

export default ({ slide = {}, onChange }) => {
  const { possible_answers = [], content = {} } = slide;
  const { multi } = content;
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    // reset selected when number of answers change
    setSelected([]);
  }, [possible_answers.length, multi]);
  useEffect(() => {
    onChange(selected.map((i) => possible_answers[i].text).join(','));
  }, [selected, possible_answers, onChange]);
  let clickHandler = (index) => (e) => {
    if (selected.includes(index)) {
      setSelected((s) => s.filter((k) => k !== index));
    } else {
      if (multi) {
        setSelected((s) => s.concat(index));
      } else {
        setSelected([index]);
      }
    }
  };
  return (
    <div>
      <div className={styles.buttons}>
        {possible_answers.map(({ text: answer }, index) => {
          const isChecked = multi && selected.includes(index);
          return (
            <Button
              key={index}
              className={styles.btn}
              variant="contained"
              onClick={clickHandler(index)}>
              {multi && (
                <CheckIcon
                  className={classNames(
                    styles.icon,
                    isChecked && styles.visible,
                  )}
                />
              )}
              {answer || <>&nbsp;</>}
            </Button>
          );
        })}
      </div>
      {multi && (
        <div className={styles.submitRow}>
          <Button
            variant="contained"
            color="primary"
            disabled={multi && selected.length === 0}>
            Done
          </Button>
        </div>
      )}
    </div>
  );
};
