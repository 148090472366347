import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from '../../ErrorBoundary';
import { QUESTION_TYPE } from '../../../models/Question';
import {
  interpolateQuestionText,
  interpolateSummaryTemplate,
} from '../../../models/Complaint';
import SliderPreview from './components/Slider.preview';
import ButtonsPreview from './components/Buttons.preview';
import DatePickerPreview from './components/DatePicker.preview';
import DropdownPreview from './components/Dropdown.preview';
import FreeTextPreview from './components/FreeText.preview';
import NumberUnitPreview from './components/NumberUnit.preview';
import LocalizationPreview from './components/Localization.preview';
import styles from './QuestionPreview.module.scss';

const PARTIAL_PREVIEW = {
  [QUESTION_TYPE.Buttons]: ButtonsPreview,
  [QUESTION_TYPE.DatePicker]: DatePickerPreview,
  [QUESTION_TYPE.DropDown]: DropdownPreview,
  [QUESTION_TYPE.FreeText]: FreeTextPreview,
  [QUESTION_TYPE.Localization]: LocalizationPreview,
  [QUESTION_TYPE.NumberUnit]: NumberUnitPreview,
  [QUESTION_TYPE.Slider]: SliderPreview,
};

const QuestionPreview = (props) => {
  const [answer, setAnswer] = useState('');
  const { complaint, question } = props;
  const { text, template } = question;
  const PartialPreview = PARTIAL_PREVIEW[template];
  const handleChange = (value) => {
    setAnswer(value);
  };
  return (
    <ErrorBoundary message="Preview crashed" canRetry={true}>
      <div className={styles.root}>
        <div className={styles.slideTitle}>
          {(complaint && interpolateQuestionText(text, complaint)) || text}
        </div>
        <main>
          {PartialPreview && (
            <PartialPreview slide={question} onChange={handleChange} />
          )}
        </main>
        <div>
          Summary text:&nbsp;
          {answer && interpolateSummaryTemplate(question, complaint, answer)}
        </div>
      </div>
    </ErrorBoundary>
  );
};
QuestionPreview.defaultProps = {
  question: {},
};
QuestionPreview.propTypes = {
  question: PropTypes.object.isRequired,
  complaint: PropTypes.object,
};

export default QuestionPreview;
