export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  rotateButton: {
    flexGrow: 0,
    flexShrink: 0,
    fontWeight: '500',
    fontSize: '22px',
  },
  svgWrapper: {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  svg: {
    maxHeight: '100%',
    maxWidth: '100%',
    flex: 1,
  },
  unZoomBtn: {
    position: 'absolute',
    bottom: '5%',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '20px',
    padding: '5px',
  },
};
