import getProperty from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { trimObjProperty, deepFreeze } from '../utils/functions'
import { validateQuestion, normalizeQuestion, QUESTION_TYPE } from './Question'

import i18n from '../i18n'

export const DEFAULT_COMPLAINT = deepFreeze({
  name: i18n.t('new_complaint'),
  questions: [
    {
      text: i18n.t('specify_nature_complaint'),
      possible_answers: [
        { index: 0, text: '' },
        { index: 1, text: '' },
      ],
      content: {
        slideName: 'Nature',
      },
      template: QUESTION_TYPE.Buttons,
    },
    {
      text: i18n.t('mark_complaint_name'),
      possible_answers: [],
      content: {
        slideName: 'Localization',
      },
      template: QUESTION_TYPE.Localization,
    },
    {
      text: i18n.t('specify_complaint_severity'),
      possible_answers: [
        { index: 0, text: 'Low' },
        { index: 1, text: 'Medium' },
        { index: 2, text: 'High' },
      ],
      content: {
        slideName: 'Severity',
      },
      template: QUESTION_TYPE.Slider,
    },
    {
      text: i18n.t('specify_complaint_duration'),
      possible_answers: [],
      content: {
        slideName: 'Duration',
        maxDate: { alwaysNow: true },
      },
      template: QUESTION_TYPE.DatePicker,
    },
    {
      text: i18n.t('specify_course'),
      possible_answers: [
        { index: 0, text: '0' },
        { index: 1, text: '1' },
      ],
      content: {
        slideName: 'Course',
      },
      template: QUESTION_TYPE.Slider,
    },
    {
      text: i18n.t('specify_onset'),
      possible_answers: [
        { index: 0, text: '0' },
        { index: 1, text: '1' },
      ],
      content: {
        slideName: 'Onset',
      },
      template: QUESTION_TYPE.DatePicker,
    },
    {
      text: i18n.t('specify_cause'),
      possible_answers: [
        { index: 0, text: i18n.t('cause') + ' 1' },
        { index: 1, text: i18n.t('cause') + ' 2' },
        { index: 2, text: i18n.t('cause') + ' 3' },
        { index: 3, text: i18n.t('cause') + ' 4' },
      ],
      content: {
        slideName: 'Cause',
      },
      template: QUESTION_TYPE.Buttons,
    },
  ],
})

export function normalizeComplaint(complaint) {
  // clone deep to prevent references to final-form state values
  const result = cloneDeep(complaint)
  // normalize values when needed (eg. trim)
  trimObjProperty(result, 'content.slideName')
  trimObjProperty(result, 'text')
  if (result.questions) {
    result.questions = result.questions.map(normalizeQuestion)
  }

  return result
}

export function validateComplaint(complaint) {
  const values = normalizeComplaint(complaint)

  const errors = {}

  if (!values.name) {
    errors.name = i18n.t('required')
  } else if (values.name.length < 3) {
    errors.name = i18n.t('min_characters')
  }

  // validate slides
  if (values.questions) {
    errors.questions = values.questions.map(validateQuestion(values))
  }

  return errors
}

function interpolateKeyWords(text, dictionary) {
  let result = text
  for (let [keyword, value] of Object.entries(dictionary)) {
    let regex = new RegExp(`\\[${keyword}\\]`, 'g')
    result = result.replace(regex, value)
  }
  return result
}

export function interpolateQuestionText(text = '', complaint) {
  // keyword => property path
  let dictionary = {
    complaintName: getProperty(complaint, 'name', ''),
  }
  return interpolateKeyWords(text, dictionary)
}

export function interpolateSummaryTemplate(question, complaint, answer) {
  const text = getProperty(question, 'content.summaryTemplate', '')
  let dictionary = {
    complaintName: getProperty(complaint, 'name', ''),
    answer,
  }
  return interpolateKeyWords(text, dictionary)
}
