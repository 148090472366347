import React from 'react';
import { Field } from 'react-final-form';

import { composeName } from '../../../utils/functions';
import { Toggle, DatePicker } from '../fieldRenderers';
import ContrastTitle from '../../widgets/ContrastTitle';

import styles from './DatePickerFormPart.module.scss';

import i18n from '../../../i18n';

const renderDateBound = ({
  input: {
    name,
    value: { alwaysNow },
    onChange,
  },
  label,
  disabled,
}) => (
  <div>
    <div className={styles.inputsRow}>
      <Field
        name={composeName(name, 'value')}
        label={label}
        component={DatePicker}
        disabled={disabled || !!alwaysNow}
      />
      <Field
        name={composeName(name, 'alwaysNow')}
        component={Toggle}
        label={i18n.t('always_current_day')}
        disabled={disabled}
      />
    </div>
  </div>
);

const DatePickerPartialForm = ({ input: { name }, disabled }) => (
  <div>
    <ContrastTitle>{i18n.t('datepicker')}</ContrastTitle>
    <Field
      name={composeName(name, 'content.minDate')}
      component={renderDateBound}
      label={i18n.t('min_date')}
      disabled={disabled}
    />
    <br />
    <Field
      name={composeName(name, 'content.maxDate')}
      component={renderDateBound}
      label={i18n.t('max_date')}
      disabled={disabled}
    />
  </div>
);

export default DatePickerPartialForm;
