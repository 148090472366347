import React from 'react'
import classNames from 'classnames'
import { Field } from 'react-final-form'
import Button from '@material-ui/core/Button'
// icons
import AddIcon from '@material-ui/icons/Add'
import ArrowUpIcon from '@material-ui/icons/ExpandLess'
import ArrowDownIcon from '@material-ui/icons/ExpandMore'

import { DEFAULT_QUESTION } from '../../../models/Question'
import styles from './SlideSelectionListFormPart.module.scss'

const SlideSelectionList = ({ fields, onEditSlide, selectedSlide }) => {
  const swapValues = (indexA, indexB) => {
    // Update Parent conditional question
    fields.forEach((name, idx) => {
      if (idx !== indexB && fields.value[idx]?.content?.condition?.parentIdx === indexA) {
        const tmp = fields.value[idx]
        tmp.content.condition.parentIdx = indexB
        fields.update(idx, tmp)
      } else if (idx !== indexA && fields.value[idx]?.content?.condition?.parentIdx === indexB) {
        const tmp = fields.value[idx]
        tmp.content.condition.parentIdx = indexA
        fields.update(idx, tmp)
      }
    })
    // Swap values
    fields.swap(indexA, indexB)
    // update selected index to keep focus on same slide
    if (selectedSlide === indexA) {
      onEditSlide(indexB)
    } else if (selectedSlide === indexB) {
      onEditSlide(indexA)
    }
  }
  const handleMoveSlideUp = index => {
    if (index > 0) {
      swapValues(index, index - 1)
    }
  }
  const handleMoveSlideDown = index => {
    if (index < fields.length - 1) {
      swapValues(index, index + 1)
    }
  }

  return (
    <div className={styles.slidesList}>
      {fields.map((name, index) => (
        <Field key={name} name={name}>
          {({
            input: {
              value: {
                content: { slideName, isConditional },
              },
            },
            meta: { dirty, invalid },
          }) => {
            const classes = classNames(styles.slideItem, {
              [styles.selected]: index === selectedSlide,
              [styles.invalid]: invalid,
              [styles.dirty]: dirty,
            })
            const canMoveUp = index > 0
            const canMoveDown = index < fields.length - 1
            return (
              <div className={classes}>
                <div className={styles.sortContainer}>
                  <ArrowUpIcon
                    className={classNames(styles.icon, !canMoveUp && styles.disabled)}
                    onClick={() => canMoveUp && handleMoveSlideUp(index)}
                  />
                  <ArrowDownIcon
                    className={classNames(styles.icon, !canMoveDown && styles.disabled)}
                    onClick={() => canMoveDown && handleMoveSlideDown(index)}
                  />
                </div>
                <Button className={styles.btn} fullWidth onClick={() => onEditSlide(index)}>
                  {isConditional && <>&copy;&nbsp;</>}
                  {slideName}
                </Button>
              </div>
            )
          }}
        </Field>
      ))}
      <Button
        className={styles.btn}
        fullWidth
        onClick={() => {
          onEditSlide(fields.length)
          fields.push(Object.assign({}, DEFAULT_QUESTION))
        }}
      >
        <AddIcon className={styles.icon} />
        Add new slide
      </Button>
    </div>
  )
}

export default SlideSelectionList
