export const ROUTES = {
  LOGIN: (next: string | undefined): string => {
    let route = '/login'
    if (next) {
      route += `?next=${encodeURIComponent(next)}`
    }
    return route
  },
  COMPLAINT_DASHBOARD: '/complaints',
  NEW_COMPLAINT: '/complaints/new',
  EDIT_COMPLAINT: (id: number) => `/complaints/${id}`,
}
