export const getRelatedRedFlags = (redFlags, id) => {
  const out = [];

  redFlags.forEach((r) => {
    r.answer_conditions?.forEach((a) => {
      if (!out.find((o) => o.id === r.id) && a?.question_conditional_id === id)
        out.push(r);
    });
  });
  return out;
};

export const asyncForEach = async (array, callback) => {
  if (!array?.length) return;
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
};
