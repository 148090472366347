import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import Button from '@material-ui/core/Button'
import { ROUTES } from 'Constants'
import { CS } from 'back-end-api'

import { notifSuccess, notifError } from '../../utils/Notification'
import HomeLayout from '../../components/HomeLayout'
import SimpleList from '../../components/widgets/SimpleList'
import PrimaryButton from '../../components/widgets/PrimaryButton'
import styles from './ComplaintsDashboard.module.scss'

import i18n from '../../i18n'

export default class ComplaintsDashboard extends React.PureComponent {
  state = { data: [], filteredData: [] }

  componentDidMount() {
    this.getComplaintData()
  }

  async getComplaintData() {
    const res = await CS.listComplaints()
    if (res.error) {
      console.error(res.error.message)
    }

    this.setState({
      data: res.data,
      filteredData: res.data,
    })
  }

  handleSearch = searchText => {
    const lowerSearch = searchText.toLowerCase()
    if (searchText) {
      this.setState(({ data }) => ({
        filteredData: data
          // compute match index
          .map(d => [d, d.name.toLowerCase().indexOf(lowerSearch)])
          // filter out when no match
          .filter(([, idx]) => idx !== -1)
          // sort by match index
          .sort(([, i1], [, i2]) => i1 - i2)
          // remap to model only
          .map(([d]) => d),
      }))
    } else {
      this.setState(({ data }) => ({
        filteredData: data,
      }))
    }
  }

  handleRemove = id => async () => {
    const res = confirm('Are you sure?')
    if (res) {
      const deleteRes = await CS.deleteComplaint(id)
      if (deleteRes.error) {
        notifError('Could not delete')
        return
      }

      notifSuccess('Complaint deleted')
      this.getComplaintData()
    }
  }

  render() {
    const { filteredData } = this.state
    return (
      <HomeLayout>
        <header className={styles.header}>
          <Typography className={styles.title}>{i18n.t('all_complaints')}</Typography>
          <div className={styles.grow} />
          <Link to={ROUTES.NEW_COMPLAINT}>
            <PrimaryButton>{i18n.t('add_new_complaint')}</PrimaryButton>
          </Link>
        </header>
        <SimpleList
          searchPlaceholder={i18n.t('search_complaint')}
          data={filteredData}
          onSearchChange={this.handleSearch}
          renderItem={({ name }) => name}
          renderItemActions={item => (
            <>
              <Link to={ROUTES.EDIT_COMPLAINT(item.id)}>
                <Button>
                  <EditIcon />
                  {i18n.t('edit')}
                </Button>
              </Link>
              <Button className={styles.deleteRow} onClick={this.handleRemove(item.id)}>
                <DeleteOutlinedIcon />
                {i18n.t('remove')}
              </Button>
            </>
          )}
        />
      </HomeLayout>
    )
  }
}
