import React from 'react';
import { Field } from 'react-final-form';

import { composeName } from '../../../utils/functions';
import { Toggle } from '../fieldRenderers';
import ContrastTitle from '../../widgets/ContrastTitle';

import i18n from '../../../i18n';

const FreeTextPartialForm = ({ input: { name }, disabled }) => (
  <div>
    <ContrastTitle>{i18n.t('free_text_widget')}</ContrastTitle>
    <Field
      name={composeName(name, 'content.stt')}
      component={Toggle}
      label={i18n.t('enable_stt')}
      disabled={disabled}
    />
  </div>
);

export default FreeTextPartialForm;
