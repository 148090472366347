import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import styles from './PrimaryButton.module.scss';

const PrimaryButton = ({
  className,
  children,
  type = 'button',
  fullWidth = false,
  ...rest
}) => (
  <Button
    className={classNames(styles.root, fullWidth && styles.fullWidth, className)}
    variant="contained"
    color="primary"
    type={type}
    {...rest}>
    {children}
  </Button>
);

export default PrimaryButton;
