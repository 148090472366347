import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field } from 'react-final-form'
import debounce from 'lodash/debounce'
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'
import IconButton from '@material-ui/core/IconButton'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'

import Label from '../widgets/Label'
import PrimaryButton from '../widgets/PrimaryButton'
import ErrorText from '../widgets/ErrorText'
import TextField from './inputs/TextField'
import styles from './fieldRenderers.module.scss'
import i18n from '../../i18n'

/***
 * Fields renderers for react-final-form
 */

export const TextInput = ({ input, meta: { error }, inputProps = {}, ...rest }) => (
  <TextField error={error} inputProps={{ ...input, ...inputProps }} {...rest} />
)

export const TextArea = ({
  label,
  input,
  meta: { error },
  rows = 1,
  disabled = false,
  fullWidth = false,
  className,
}) => {
  const classes = classNames(
    className,
    styles.textArea,
    fullWidth && styles.fullWidth,
    disabled && styles.disabled
  )
  return (
    <div className={classes}>
      <label>
        <Label>{label}</Label>
        <textarea rows={rows} disabled={disabled} {...input} />
        <ErrorText>{error}</ErrorText>
      </label>
    </div>
  )
}

export const DropDown = ({
  options = [],
  input,
  meta: { error } = {},
  label,
  disabled = false,
  fullWidth = false,
  searchEnabled = false,
  onSearch = () => [],
  fixedError,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const searchInput = useRef(null)
  const [searchResults, setSearchResults] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isSearching, setSearching] = useState(false)

  const performSearch = useCallback(
    debounce(async query => {
      let results = await onSearch(query)
      if (results && results instanceof Array) {
        setSearchResults(results)
      }
    }, 300),
    [options]
  )
  const handleSearchChange = useCallback(
    e => {
      performSearch.cancel()
      const newSearchText = e.target.value
      setSearchText(newSearchText)
      if (newSearchText) {
        setSearching(true)
        performSearch(newSearchText)
      } else {
        setSearching(false)
        setSearchResults([])
      }
    },
    [performSearch]
  )
  const closeChoices = () => {
    window.removeEventListener('click', closeChoices)
    setIsOpen(false)
    setSearching(false)
    setSearchText('')
    setSearchResults([])
  }
  const openChoices = () => {
    setIsOpen(true)
    if (searchInput.current) {
      searchInput.current.focus()
      searchInput.current.scrollIntoView()
    }
    window.addEventListener('click', closeChoices)
  }
  const toggleChoices = e => {
    if (disabled) return
    e.stopPropagation()
    ;(isOpen ? closeChoices : openChoices)()
  }
  const { onChange } = input
  const classes = classNames(
    className,
    styles.dropDown,
    fullWidth && styles.fullWidth,
    disabled && styles.disabled,
    isOpen && styles.open
  )
  const selectedOption = options.find(o => o.value === input.value)
  const valueLabel = (selectedOption && selectedOption.label) || ''

  const searchFieldProps = {
    ref: searchInput,
    onChange: handleSearchChange,
    fixedError: false,
    fullWidth: true,
    placeholder: i18n.t('search'),
    value: searchText,
    className: styles.searchField,
    inputProps: {
      onClick: e => e.stopPropagation(),
    },
  }

  const optsList = isSearching ? searchResults : options
  return (
    <div className={classes}>
      {label && <Label>{label}</Label>}
      <input {...input} type="hidden" />
      <div className={styles.dropDownWrapper}>
        <div className={styles.box} onClick={toggleChoices}>
          <div className={styles.valueDisplay}>{valueLabel}</div>
          <ExpandIcon className={styles.icon} />
        </div>
        <div className={styles.dropPanel}>
          {options.length > 0 && searchEnabled && <TextField {...searchFieldProps} />}
          {optsList.length === 0 && (
            <center>
              {!isSearching && <i>No options available</i>}
              {isSearching && <i>No results</i>}
            </center>
          )}
          {optsList.map((opt, index) => {
            const classes = classNames(styles.option, opt.value === input.value && styles.selected)
            return (
              <div
                key={index}
                className={classes}
                title={opt.label}
                onClick={() => onChange(opt.value)}
              >
                {opt.label}
              </div>
            )
          })}
        </div>
      </div>
      <ErrorText fixed={fixedError}>{error}</ErrorText>
    </div>
  )
}

export const Toggle = ({ input, meta: { error }, label, className, disabled = false }) => {
  const classes = classNames(className, styles.toggle, disabled && styles.disabled)
  const onChange = () => input.onChange(!input.value)
  return (
    <div className={classes}>
      <label>
        <input
          {...input}
          onChange={onChange}
          checked={input.value}
          disabled={disabled}
          type="checkbox"
        />
        <div className={styles.display} />
        <Label>{label}</Label>
      </label>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  )
}

const CheckBoxImpl = ({
  value: propValue = false,
  onChange: propOnChange,
  error,
  label,
  className,
  disabled = false,
}) => {
  const [stateValue, setStateValue] = useState(propValue)

  useEffect(() => {
    setStateValue(propValue)
  }, [propValue])

  const onChange = e => {
    const newValue = e.target.checked
    setStateValue(newValue)
    propOnChange(newValue)
  }

  const classes = classNames(className, styles.checkbox, disabled && styles.disabled)

  return (
    <div className={classes}>
      <label>
        <input onChange={onChange} checked={stateValue} disabled={disabled} type="checkbox" />
        <div className={styles.display}>
          <CloseIcon className={styles.icon} />
        </div>
        <Label light>{label}</Label>
      </label>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  )
}

export const CheckboxGroup = ({
  input: { value, onChange },
  options,
  disabled = false,
  formatOption = i => i,
}) => {
  // map value => index in fields
  const [stateValue, setStateValue] = useState([])

  useEffect(() => {
    if (value instanceof Array) {
      setStateValue(value)
    }
  }, [value])

  const toggle = (checked, index) => {
    let newValue
    if (checked) {
      newValue = stateValue.concat(index)
    } else {
      newValue = stateValue.filter(x => x !== index)
    }
    setStateValue(newValue)
    onChange(newValue)
  }

  return (
    <>
      {options.map((option, i) => {
        const props = {
          value: stateValue.includes(i),
          onChange: checked => toggle(checked, i),
          disabled,
          label: formatOption(option),
        }
        return <CheckBoxImpl key={i} {...props} />
      })}
    </>
  )
}

export const DatePicker = ({ input, label, meta, className, disabled = false }) => {
  const classes = classNames(className, styles.datepicker, disabled && styles.disabled)
  return (
    <div className={classes}>
      <label>
        <Label>{label}</Label>
        <input {...input} type="date" disabled={disabled} />
      </label>
    </div>
  )
}

export const TextFieldsArray = ({
  fields,
  meta: { error },
  itemLabel,
  addBtnLabel,
  formatItem,
  parseItem,
  disabled = false,
}) => {
  const displayError = !(error instanceof Array)
  return (
    <div className={styles.textFieldsArray}>
      {fields.map((name, index) => (
        <div key={name} className={styles.textFieldWithDelete}>
          <Field
            name={name}
            label={itemLabel(index)}
            className={styles.textInput}
            format={(value, name) => formatItem(value || '', index, name)}
            parse={(value, name) => parseItem(value, index, name)}
            disabled={disabled}
            component={TextInput}
          />
          {!disabled && (
            <IconButton onClick={() => fields.remove(index)} className={styles.deleteBtn}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ))}
      {!disabled && (
        <div>
          <PrimaryButton onClick={() => fields.push('')}>{addBtnLabel}</PrimaryButton>
        </div>
      )}
      <ErrorText className={styles.arrayError}>{displayError && error}</ErrorText>
    </div>
  )
}
TextFieldsArray.propTypes = {
  itemLabel: PropTypes.func,
  formatItem: PropTypes.func,
  parseItem: PropTypes.func,
  addBtnLabel: PropTypes.string,
}
TextFieldsArray.defaultProps = {
  itemLabel: () => null,
  formatItem: v => v,
  parseItem: v => v,
  addBtnLabel: 'Add',
}

export const DisplayText = ({ input: { value } }) => <>{value}</>

export const RadioTabs = ({
  input: { onChange: inputOnChange, value },
  onChange: propOnChange,
  options,
  renderOption,
}) => {
  const onChange = (...args) => {
    inputOnChange(...args)
    propOnChange(...args)
  }
  const handleClick = opt => () => {
    if (value !== opt) {
      onChange(opt)
    }
  }
  return (
    <div className={styles.radioTabs}>
      {options.map((opt, i) => (
        <div
          key={i}
          className={classNames(styles.option, opt === value && styles.selected)}
          onClick={handleClick(opt)}
        >
          {renderOption(opt)}
        </div>
      ))}
    </div>
  )
}
RadioTabs.propTypes = {
  options: PropTypes.array,
  renderOption: PropTypes.func,
  onChange: PropTypes.func,
}
RadioTabs.defaultProps = {
  options: [],
  renderOption: i => i,
  onChange: () => null,
}

export const RadioButtons = ({ input: { onChange, value }, options, renderOption }) => {
  const handleClick = opt => () => {
    if (value !== opt) {
      onChange(opt)
    }
  }
  return (
    <div className={styles.radioBtns}>
      {options.map((opt, i) => (
        <div
          key={i}
          className={classNames(styles.option, opt === value && styles.selected)}
          onClick={handleClick(opt)}
        >
          <div className={styles.dot} />
          {renderOption(opt)}
        </div>
      ))}
    </div>
  )
}
RadioButtons.propTypes = {
  options: PropTypes.array,
  renderOption: PropTypes.func,
}
RadioButtons.defaultProps = {
  options: [],
  renderOption: i => i,
}
