import React, { forwardRef } from 'react';
import classNames from 'classnames';

import ErrorText from '../../../widgets/ErrorText';
import Label from '../../../widgets/Label';

import styles from './TextField.module.scss';

const TextField = forwardRef((props, ref) => {
  const {
    label,
    value,
    onChange = () => null,
    error,
    placeholder,
    type = 'text',
    disabled = false,
    fullWidth = false,
    fixedError = true,
    inputProps = {},
    className,
  } = props;
  const inputFinalProps = {
    disabled,
    value,
    ref,
    type,
    placeholder,
    onChange,
    ...inputProps,
  };
  const classes = classNames(
    className,
    styles.textField,
    fullWidth && styles.fullWidth,
    disabled && styles.disabled,
  );
  return (
    <div className={classes}>
      <label>
        {label && <Label>{label}</Label>}
        <input {...inputFinalProps} />
        <ErrorText fixed={fixedError}>{error}</ErrorText>
      </label>
    </div>
  );
});

export default TextField;
