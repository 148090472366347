import React, { useState } from 'react';

import Dummy, { BODYPARTS_LABELS } from '../../components/widgets/Dummy';

import styles from './dummy.module.scss';

export default () => {
  const [selection, setSelection] = useState('');
  const [zoom, setZoom] = useState('');

  return (
    <div className={styles.root}>
      <div className={styles.infos}>
        <h1>Dummy demo</h1>
        <p>
          {selection && (
            <span>Selection: {BODYPARTS_LABELS['en'][selection]}</span>
          )}
          &nbsp;
        </p>
        <p>{zoom && <span>Zoom: {BODYPARTS_LABELS['en'][zoom]}</span>}&nbsp;</p>
      </div>
      <Dummy
        className={styles.dummy}
        onSelectionChange={setSelection}
        onZoomChange={setZoom}
      />
    </div>
  );
};
