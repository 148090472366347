import React from 'react';
import { Field } from 'react-final-form';

import { composeName } from '../../../utils/functions';
import { Toggle, DropDown } from '../fieldRenderers';
import ContrastTitle from '../../widgets/ContrastTitle';
import { ZOOMABLE_AREAS, BODYPARTS_LABELS } from '../../widgets/Dummy';

import i18n from '../../../i18n';

const AREAS = ZOOMABLE_AREAS.map((name) => ({
  label: BODYPARTS_LABELS['en'][name],
  value: name,
}));

const handleSearchBodyPart = (searchText) => {
  let sTxt = searchText.toLowerCase();
  return AREAS.map((p) => [p, p.label.toLowerCase().indexOf(sTxt)])
    .filter(([, idx]) => idx !== -1)
    .sort(([, i1], [, i2]) => i1 - i2)
    .map(([p]) => p);
};

const SliderPartialForm = ({ input: { name }, disabled }) => (
  <div>
    <ContrastTitle>{i18n.t('localization')}</ContrastTitle>
    <Field
      name={composeName(name, 'content.lockZoom')}
      component={Toggle}
      label={i18n.t('lock_zoom')}
      disabled={disabled}
    />
    <br />
    <Field
      name={composeName(name, 'content.preZoom')}
      component={DropDown}
      label={i18n.t('zoomed_in_area')}
      options={AREAS}
      disabled={disabled}
      searchEnabled
      onSearch={handleSearchBodyPart}
    />
    <br />
    <Field
      name={composeName(name, 'content.lockRotation')}
      component={Toggle}
      label={i18n.t('lock_rotation')}
      disabled={disabled}
    />
  </div>
);

export default SliderPartialForm;
