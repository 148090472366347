import React from 'react';
import { Field } from 'react-final-form';

import { RadioButtons } from '../../forms/fieldRenderers';
import { composeName } from '../../../utils/functions';
import { GENDER } from '../../../models/Question';
import styles from './NumberCondition.module.scss';

const options = [GENDER.Male, GENDER.Female];

const GenderConditionFormPart = ({ name }) => {
  const genderPath = composeName(name, 'content', 'condition', 'value[0]');
  return (
    <div className={styles.root}>
      <div>Patient gender is</div>
      <Field name={genderPath} component={RadioButtons} options={options} />
    </div>
  );
};

export default GenderConditionFormPart;
