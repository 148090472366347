import i18n from 'i18n-js'

const en = {
  welcome: 'Welcome to MIA Editor',
  username: 'Username',
  password: 'Password',
  login: 'Log In',
  login_failed: 'Login failed',
  general: 'General',
  see_all_complaints: 'See all complaints',
  add_new_complaint: 'Add a new complaint',
  logout: 'Logout',
  edit: 'Edit',
  remove: 'Remove',
  search: 'Search',
  search_complaint: 'Search for a complaint...',
  create_complaint: 'Create a complaint',
  finish_later: 'Finish later',
  save_complaint: 'Save complaint',
  complaint_name: 'Complaint name',
  new_complaint: 'New complaint',
  slides: 'Slides',
  nature: 'Nature',
  localization: 'Localization',
  severity: 'Severity',
  duration: 'Duration',
  course: 'Course',
  onset: 'Onset',
  cause: 'Cause',
  add_new_slide: 'Add new slide',
  question_name: 'Question Name',
  question_type: 'Question Type',
  buttons: 'Buttons',
  slider: 'Slider',
  datepicker: 'DatePicker',
  dropdown: 'DropDown',
  free_text: 'Free Text',
  number_with_unit: 'Number with Unit',
  top_message: 'Top Message',
  multiple_answers_allowed: 'Multiple answers allowed',
  required: 'Required',
  add_step: 'Add a step',
  step: 'STEP',
  add_button: 'Add a button',
  min_date: 'Min date',
  max_date: 'Max date',
  always_current_day: 'Always current day',
  done: 'Done',
  option: 'Option',
  add_option: 'Add an option',
  remove_slide: 'Remove slide',
  new_question: 'New Question',
  successfully_created: 'Successfully created',
  complaint_deleted: 'Complaint deleted',
  save: 'Save',
  type_question: 'Type your question...',
  type_answer: 'Type your answer here',
  all_complaints: 'All complaints',
  create: 'Create',
  a_complaint: 'a Complaint',
  retry: 'Retry',
  something_wrong: 'Something went wrong',
  no_match_found: 'No match found',
  free_text_widget: 'FreeText Widget',
  enable_stt: 'Enable Speech To Text',
  lock_zoom: 'Lock Zoom',
  zoomed_in_area: 'Zoomed in area',
  lock_rotation: 'Lock Rotation',
  orientation: 'Orientation',
  minimum_value: 'Minimum Value',
  maximum_value: 'Maximum Value',
  decimal_precision: 'Decimal Precision',
  suffix: 'Suffix',
  add_unit: 'Add unit',
  unit: 'Unit',
  summary_text_template: 'Summary Text Template',
  specify_nature_complaint: 'Please specify the nature',
  mark_complaint_name: 'Please mark your [complaintName]s',
  specify_complaint_severity: 'Please specify the severity',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  specify_complaint_duration: 'Please specify the duration',
  specify_course: 'Please specify the course',
  specify_onset: 'Please specify the onset',
  specify_cause: 'Please specify the cause',
  min_characters: 'Min 3 characters',
  too_long: 'Too Long',
  button: 'Button',
  conditional_question: 'Conditional question',
  mandatory_question: 'Mandatory question',
  show_additional_complaints: "Always show add'l questions",
  export: 'Export Patient Data to CSV',
}

const nl = {
  welcome: 'Welkom bij de MIA Editor',
  username: 'Gebruikersnaam',
  password: 'Wachtwoord',
  login: 'Inloggen',
  login_failed: 'Uw inloggegevens zijn onjuist',
  general: 'Algemeen',
  see_all_complaints: 'Alle vragenlijsten weergeven',
  add_new_complaint: 'Nieuwe vragenlijst aanmaken',
  logout: 'Uitloggen',
  edit: 'Bewerken',
  remove: 'Verwijderen',
  search: 'Zoeken',
  search_complaint: 'Zoeken...',
  create_complaint: 'Nieuwe vragenlijst aanmaken',
  finish_later: 'Later afronden',
  save_complaint: 'Vragenlijst opslaan',
  complaint_name: 'Klacht beschrijving',
  new_complaint: 'Nieuwe vragenlijst',
  slides: 'Vragenconfiguratie',
  nature: 'Aard',
  localization: 'Lokalisatie',
  severity: 'Ernst',
  duration: 'Duur',
  course: 'Beloop',
  onset: 'Ontstaan',
  cause: 'Oorzaak',
  add_new_slide: 'Voeg nieuwe vraag toe',
  question_name: 'Vraag naam',
  question_type: 'Selecteer invulmethode',
  buttons: 'Knoppen',
  slider: 'Schuifbalk',
  datepicker: 'Kalender',
  dropdown: 'Lijst',
  free_text: 'Vrij veld',
  number_with_unit: 'Hoeveelheden en Tijd',
  top_message: 'Vraagbeschrijving (Koptekst)',
  multiple_answers_allowed: 'Meervoudige selectie aan/uit',
  required: 'Ver-eist',
  add_step: 'Voeg stap toe',
  step: 'Stap',
  add_button: 'Voeg knop toe',
  min_date: 'Min. datum',
  max_date: 'Max. datum',
  always_current_day: 'Altijd huidige datum',
  done: 'Klaar',
  option: 'Keuze',
  add_option: 'Voeg nieuwe keuze toe',
  remove_slide: 'Verwijder deze vraag',
  new_question: 'Nieuwe vraag',
  successfully_created: 'Aanmaken vragenlijst succesvol!',
  complaint_deleted: 'Vragenlijst verwijderd',
  save: 'Opslaan',
  type_question: 'Beschrijf uw vraag',
  type_answer: 'Geef mogelijke antwoorden op',
  all_complaints: 'Alle klachten',
  create: 'aanmaken',
  a_complaint: 'Nieuwe anamneselijst',
  retry: 'Opnieuw proberen',
  something_wrong: 'Foutmelding onbekend',
  no_match_found: 'Geen resultaat gevonden',
  free_text_widget: 'Tekst en Spraak',
  enable_stt: 'Spraakherkenning aan/uit',
  lock_zoom: 'Beeld vastzetten',
  zoomed_in_area: 'Focusgebied',
  lock_rotation: 'Orientatie vastzetten',
  orientation: 'Orientatie richting',
  minimum_value: 'Minimale waarde',
  maximum_value: 'Maximale waarde',
  decimal_precision: 'Decimale precisie',
  suffix: 'achtervoegsel',
  add_unit: 'voeg eenheid toe',
  unit: 'Eenheid',
  summary_text_template: 'Samenvattingstekst(SOEP)',
  specify_nature_complaint: 'Wat is de aard van uw klacht?',
  mark_complaint_name: 'Waar zit uw klacht?',
  specify_complaint_severity: 'Wat is de ernst van uw klacht?',
  low: 'Matig ernstig',
  medium: 'Ernstig',
  high: 'Zeer ernstig',
  specify_complaint_duration: 'Hoe lang duren uw klachten?',
  specify_course: 'Hoe verloopt uw klacht tot nu toe?',
  specify_onset: 'Wanneer is uw klacht ontstaan?',
  specify_cause: 'Is er een duidelijke oorzaak voor uw klacht?',
  min_characters: 'Min 3 tekens',
  too_long: 'te lang',
  button: 'Knop',
  conditional_question: 'Voorwaardelijke vraag',
  mandatory_question: 'Verplichte vraag',
  show_additional_complaints: 'Altijd aanvullende vragen tonen',
  export: 'Exporteer patientgegevens naar CSV',
}

i18n.fallbacks = true
i18n.translations = { nl, en }
i18n.locale = 'nl'

if (global.navigator && global.navigator.languages) {
  let locale = global.navigator.languages.find(l => ['nl', 'en'].includes(l))
  if (locale) {
    i18n.locale = locale
  }
}

export default i18n
