import { useEffect } from 'react';
import { useField } from 'react-final-form';

function isDefined(value) {
  return typeof value !== 'undefined' && value !== null && value !== '';
}

const useFieldInit = (name, initialValue) => {
  const {
    input: { onChange },
    meta: { initial, pristine },
  } = useField(name, { subscription: { initial: true, pristine: true } });
  useEffect(() => {
    if (
      pristine &&
      !isDefined(initial) &&
      typeof initialValue !== 'undefined'
    ) {
      setTimeout(onChange, 0, initialValue);
    }
  }, [initial, initialValue, onChange]);
};

export default useFieldInit;
