import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import MainHeader from './components/widgets/MainHeader'
import Login from './screens/Login'
import ComplaintForm from './screens/ComplaintForm'
import ComplaintsDashboard from './screens/ComplaintsDashboard'
import styles from './App.module.scss'
import { ROUTES } from 'Constants'
import DummyScreen from './screens/DummyScreen'
import InitBackEndServices from 'services/InitBackEndServices'

const ErrorScreen = () => (
  <div className={styles.errorScreen}>
    <div>Sorry, something went wrong</div>
    <div>You may try to reload the page</div>
  </div>
)

const NotFoundScreen = () => <div>Page Not Found</div>

export default class App extends React.PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    crashed: false,
  }

  componentDidCatch() {
    this.setState({ crashed: true })
  }

  render() {
    const { crashed } = this.state
    if (crashed) {
      return <ErrorScreen />
    }
    return (
      <Router>
        <InitBackEndServices />
        <div className={styles.root}>
          <MainHeader />
          <Switch>
            <Route exact path="/">
              <Redirect to={ROUTES.COMPLAINT_DASHBOARD} />
            </Route>
            <Route exact path="/dummy" component={DummyScreen} />
            <Route path={ROUTES.NEW_COMPLAINT} exact component={ComplaintForm} />
            <Route path={ROUTES.EDIT_COMPLAINT(':id')} exact component={ComplaintForm} />
            <Route path={ROUTES.LOGIN()} exact component={Login} />
            <Route path={ROUTES.COMPLAINT_DASHBOARD} exact component={ComplaintsDashboard} />
            <Route path="/" component={NotFoundScreen} />
          </Switch>
        </div>
      </Router>
    )
  }
}
