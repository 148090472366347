import React, { memo, useCallback, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { TextInput, TextArea, DropDown, Toggle } from '../../fieldRenderers';
import PatientConditions from './PatientConditions';
import Answers from './Answers';
import { Container } from './Styles';

const Form = ({ values, questions, currentQuestionIndex }) => {
  const [tab, setTab] = useState(0);

  const handleChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, [setTab]);
  return (
    <Container>
      <Typography variant="h5">
        {values?.length > 1 ? 'Edit' : 'Create'}{' '}Red Flag
      </Typography>
      <Field name={`red_flags[${values.index}].text`} label="Title" component={TextInput}/>
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="Patient conditions" />
        <Tab label="Answers" />
      </Tabs>
      {tab === 0 && (
        <FieldArray
          name={`red_flags[${values.index}].account_conditions`}
          component={PatientConditions}
          rfIndex={values.index}
        />
      )}
      {tab === 1 && (
        <FieldArray
          name={`red_flags[${values.index}].answer_conditions`}
          component={Answers}
          questions={questions}
          rfIndex={values.index}
          currentQuestionIndex={currentQuestionIndex}
        />
      )}
    </Container>
  );
};

Form.whyDidYouRender = true;

export default memo(Form);
