import React from 'react'
import propTypes from 'prop-types'

import BodyView from './BodyView'
import frontData from './views/frontData'
import backData from './views/backData'
import leftData from './views/leftData'
import rightData from './views/rightData'
import { ORIENTATION, BODYPARTS_DATA } from './constants'

import styles from './Dummy.styles'

// order matters when rotating
const VIEWS = {
  [ORIENTATION.FRONT]: frontData,
  [ORIENTATION.LEFT]: leftData,
  [ORIENTATION.BACK]: backData,
  [ORIENTATION.RIGHT]: rightData,
}

/**
 * Find bodypart in the data provided
 * @param {func} testFn
 * @param {*} partData
 */
function findBodyPart(testFn, partData) {
  if (testFn(partData)) {
    return partData
  }
  if (partData.areas) {
    for (let area of partData.areas) {
      let result = findBodyPart(testFn, area)
      if (result) {
        // return as soon as possible
        return result
      }
    }
  }
}

function findAllBodyParts(testFn, partData) {
  let result = []
  if (testFn(partData)) {
    result.push(partData)
  }
  if (partData.areas) {
    for (let area of partData.areas) {
      result.push(...findAllBodyParts(testFn, area))
    }
  }
  return result
}

export const ZOOMABLE_AREAS = Array.from(
  new Set(
    Object.values(ORIENTATION)
      .map(orientation =>
        findAllBodyParts(({ areas }) => areas && areas.length > 0, VIEWS[orientation])
      )
      .flat()
      .map(({ name }) => name)
  )
).filter(name => !!name)

function findBodyPartByName(name) {
  return findBodyPart(part => part.name === name, VIEWS[getBodyPartOrientation(name)])
}

function findBodyPartByRotation(rotation, partData) {
  return findBodyPart(part => BODYPARTS_DATA[part.name].rotation === rotation, partData)
}

function findBodyPartRotations(bodypartName) {
  const result = {}
  const { rotation } = BODYPARTS_DATA[bodypartName]
  if (rotation) {
    for (let side of Object.values(ORIENTATION)) {
      const rotatedPart = findBodyPartByRotation(rotation, VIEWS[side])
      if (rotatedPart) {
        result[side] = rotatedPart
      }
    }
  }
  return result
}

function findBodyPartParent(bodypartName) {
  const orientation = getBodyPartOrientation(bodypartName)
  return findBodyPart(
    ({ areas }) => areas && areas.some(({ name }) => name === bodypartName),
    VIEWS[orientation]
  )
}

function getBodyPartOrientation(bodypartName) {
  return BODYPARTS_DATA[bodypartName].orientation
}

// Modulo function with always positive result
const posMod = (x, mod) => ((x % mod) + mod) % mod

export default class Dummy extends React.PureComponent {
  // This is the interface of the component, we define which properties are
  // accepted and if they are required or not
  static propTypes = {
    // [Optionnal] current selection, array of bodyparts or a bodypart directly
    selection: propTypes.oneOfType([
      propTypes.arrayOf(propTypes.oneOf(Object.keys(BODYPARTS_DATA))),
      propTypes.oneOf(Object.keys(BODYPARTS_DATA)),
    ]),
    // [Optionnal] Called when user select a zone (selectedZone => *)
    onSelectionChange: propTypes.func,
    // [Optionnal] Called when user change zoom (zone => *)
    onZoomChange: propTypes.func,
    // [Optionnal] The area we may want to be zoomed in initially
    initialZoom: propTypes.string,
    // TODO [Optionnal] We can disable zoom (especially useful to disable zooming out)
    canZoom: propTypes.bool,
    // TODO [Optionnal] We can disable rotation
    canRotate: propTypes.bool,
    // [Optionnal] disable selection of a bodypart
    canSelect: propTypes.bool,
  }

  // these are the default values for every property not set
  static defaultProps = {
    onSelectionChange: () => null,
    onZoomChange: () => null,
    initialZoom: null,
    canZoom: true,
    canRotate: true,
    canSelect: true,
    selection: null,
  }

  constructor(props) {
    super(props)
    const { selection: propSelection, initialZoom } = props

    let orientation = ORIENTATION.FRONT
    let currentBodyPart = null
    let selection = []

    if (initialZoom) {
      orientation = getBodyPartOrientation(initialZoom)
      currentBodyPart = findBodyPartByName(initialZoom)
    }

    if (!!propSelection) {
      if (propSelection instanceof Array) {
        if (propSelection.length > 0) {
          const [firstSelection] = propSelection
          orientation = getBodyPartOrientation(firstSelection)
          selection = propSelection.map(x => findBodyPartByName(x))
          currentBodyPart = findBodyPartParent(firstSelection)
        }
      } else {
        orientation = getBodyPartOrientation(propSelection)
        selection = [findBodyPartByName(propSelection)]
        currentBodyPart = findBodyPartParent(propSelection)
      }
    }

    let isZoomed = true
    if (!currentBodyPart) {
      isZoomed = false
      currentBodyPart = VIEWS[orientation]
    }

    this.state = {
      selection,
      orientation,
      currentBodyPart,
      rotations: findBodyPartRotations(currentBodyPart.name),
      isZoomed,
    }
  }

  get canZoom() {
    const { canZoom } = this.props
    const { selection } = this.state
    // zoom enabled only when no selection
    return canZoom && selection.length === 0
  }

  get canRotate() {
    const { canRotate } = this.props
    const { selection, rotations } = this.state
    // rotation enabled only when no selection and rotation available
    return canRotate && selection.length === 0 && Object.values(rotations).length > 1
  }

  handleClickArea = area => {
    const { canSelect, onSelectionChange, onZoomChange } = this.props
    const { selection } = this.state
    if (area.areas) {
      // distinct if to prevent selection of this area
      if (this.canZoom) {
        onZoomChange(area.name)
        this.handleZoom(area)
      }
    } else {
      if (canSelect) {
        // select area
        let newSelection
        if (selection.includes(area)) {
          newSelection = selection.filter(x => x !== area)
        } else {
          newSelection = selection.concat(area)
        }
        onSelectionChange(newSelection.map(({ name }) => name))
        this.setState({
          selection: newSelection,
        })
      }
    }
  }

  handleRotate = (direction = 0) => {
    this.setState(({ orientation, rotations }) => {
      let newOrientation = orientation
      do {
        newOrientation = posMod(newOrientation + direction, 4)
      } while (!rotations[newOrientation])
      return {
        orientation: newOrientation,
        currentBodyPart: rotations[newOrientation],
      }
    })
  }

  handleRotateLeft = () => this.handleRotate(1)

  handleRotateRight = () => this.handleRotate(-1)

  handleZoom = bodyPart => {
    this.setState({
      currentBodyPart: bodyPart,
      rotations: findBodyPartRotations(bodyPart.name),
      isZoomed: true,
    })
  }

  handleUnZoom = () => {
    const { onZoomChange } = this.props
    onZoomChange(null)
    this.setState(({ orientation }) => {
      const currentBodyPart = VIEWS[orientation]
      return {
        currentBodyPart,
        rotations: findBodyPartRotations(currentBodyPart.name),
        isZoomed: false,
      }
    })
  }

  render() {
    // This is the render function of the component that runs every time a
    // property or the internal state of the component change
    const {
      onSelectionChange,
      onZoomChange,
      initialZoom,
      initialOrientation,
      style,
      // don't spread
      canZoom,
      canRotate,
      canSelect,
      ...rest
    } = this.props
    const { selection, orientation, currentBodyPart, rotations, isZoomed } = this.state
    let viewData = VIEWS[orientation]
    const containerStyle = Object.assign({}, styles.container, style)
    const finalCanRotate = this.canRotate && Object.values(rotations).length > 1
    return (
      <div style={containerStyle} {...rest}>
        {finalCanRotate && (
          <div style={styles.rotateButton} onClick={this.handleRotateLeft}>
            &lt;
          </div>
        )}
        <div style={styles.svgWrapper}>
          <BodyView
            {...viewData}
            {...currentBodyPart}
            style={styles.svg}
            selectedAreas={selection}
            onClickArea={this.handleClickArea}
          />
        </div>
        {finalCanRotate && (
          <div style={styles.rotateButton} onClick={this.handleRotateRight}>
            &gt;
          </div>
        )}
        {this.canZoom && isZoomed && (
          <button style={styles.unZoomBtn} onClick={this.handleUnZoom}>
            un-zoom
          </button>
        )}
      </div>
    )
  }
}
